import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoMdArrowDropdown, IoIosSearch } from "react-icons/io";
import { ArrowBackButton, handleNavigateSearch, fetchApi } from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import GotoCart from "../Cart/GotoCart";
import { Toaster, toast } from "react-hot-toast";
import { REQUESTOPTIONS, DIAGNOSTIC_TEST_API } from "../../Constants";

import {
  ArrowBack,
  appCartIcon,
  appHeartIcon,
  appCommonlyBookTest,
} from "../../images";
import NoDataFoundComp from "../../components/NoDataFound";

const CommonBookTest = () => {
  const [testData, setTestData] = useState([]);
  const [isAllpackageLoading, setAllPackageLoading] = useState(true);
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [isErrorData, setErrorData] = useState([]);
  const navigate = useNavigate();



  const handleCart = (id) => {
    const isItemInCart = CartData.some((item) => item.test_id === id);

    if (!isItemInCart) {
      setCartData((prevData) => [
        ...prevData,
        ...testData.data.filter((data) => data.test_id === id),
      ]);
      toast.success("Item Added")
    } else {
      const updatedCart = CartData.filter((item) => item.test_id !== id);
      setCartData(updatedCart);
      toast.success("Item Removed Succesfully")
    }
  };

  useEffect(() => {
    if (CartData.length > 0) {
      localStorage.setItem("CartData", JSON.stringify(CartData));
    }
  }, [CartData]);

  useEffect(() => {
    fetchApi(DIAGNOSTIC_TEST_API, REQUESTOPTIONS, setTestData, setAllPackageLoading, setErrorData);
  }, []);


  return (
    <div>
      <div className="home-review-banner">

        <div className="app-header-wraper">
          <div className="header-top-section d-flex justify-content-between">
            <div className="header-top-section-category-section">
              <h3>Lab Test</h3>
            </div>
            <div className="header-top-section-add-to-cart-section lab-cart-section">
              <button>
                <img src={appCartIcon} width="100%" alt="Cart-icon" />
                {CartData.length > 0 && (
                  <span className="Header-count-number">{CartData.length > 0 && CartData.length}</span>
                )}
              </button>
            </div>
            <div className="header-top-section-add-to-cart-section-hert">
              <img src={appHeartIcon} width="100%" alt="heart-icon" />
            </div>
          </div>
        </div>

        <div className="app-searh-section lab-search-bar">
          <div className="inputWithIcon" onClick={() => handleNavigateSearch(navigate)}>
            <input type="text" placeholder="Search" />
            <button>
              <IoIosSearch />
            </button>
          </div>
        </div>


        <div className="common-test-wrapper common-book-test">
          <div className="container">
            <div className="row">
              {testData.data?.length > 0 ? (
                testData.data.map((elem, index) => (
                  <div className="col-lg-6 common-repo mb-4">
                    <div className="common-test-box mb-3">
                      <NavLink to={`/test-description/${elem.test_id}`}>
                        <div className="common-test-image">
                          <img
                            src={appCommonlyBookTest}
                            width="100%"
                            alt="Book-test"
                          />
                        </div>
                        <div className="offer-common">
                          <p>{elem?.discount_percent} OFF</p>
                        </div>
                        <div className="common-test-heading">
                          <h6>{elem.test_heading}</h6>
                        </div>
                        <hr />
                        <div className="common-test-content text-center">
                          <p>
                            Included Tests :{" "}
                            {elem.test_parameter.length > 0 &&
                              elem.test_parameter.length}
                          </p>
                          <h6>Medibhai Price : ₹ {elem?.test_medi_price}</h6>
                          <span>MRP: ₹ {elem?.test_mrp}</span>
                        </div>
                      </NavLink>
                      {CartData.some(
                        (item) => item.test_id === elem.test_id
                      ) ? (
                        <button
                          className="remove-cart-btn"
                          onClick={() => handleCart(elem.test_id)}
                        >
                          Remove
                        </button>
                      ) : (
                        <div className="common-add-cart">
                          <button onClick={() => handleCart(elem.test_id)}>
                            <p>ADD TO CART</p>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <NoDataFoundComp />
              )}
            </div>
          </div>
        </div>

      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 1800,
          style: {
            background: "#fff",
            color: "#363636",
          },
        }}
      />
      {CartData.length > 0 && (
        <div className="cartwrapper">
          <GotoCart CartData={CartData} link="diagnostictest" />
        </div>

      )}
    </div>
  );
};

export default CommonBookTest;
