import { React, useEffect, useState, useContext, useParams, forwardRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  appCartIcon,
  headerLogo,
  appTestTube,
  Nutition_banner,
  ArrowBack,
} from "../../../images";
import {
  handleNavigate,
  validateNumberInput,
  fetchApi,
  ArrowBackButton,
} from "../../../utils/AllFunction";
import { Toaster, toast } from "react-hot-toast";
import { AppContext, imgPath } from "../../../ContextApi";
import Loader from "../../../Animation/Loader";
import { validateTextInput } from "../../../utils/AllFunction";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useRazorpay from "react-razorpay";
import { useForm, Controller } from "react-hook-form";
import { Button, InputGroup, Modal } from "react-bootstrap";
import PaymentModal from "../../../Animation/PaymentModal";
import PaymentLoader from "../../../Animation/PaymentLoader";
import SuccessFull from "../../../Animation/SuccessFull";
import { parseISO, format } from "date-fns";
import { FaCalendarAlt } from 'react-icons/fa';

import {
  BOOK_TELECONSULTATION_API,
  WALLET_API,
  IMG_PATH,
  TELECONSULTATION_LIST_API,
  GET_TIME_SLOT_API,
  MY_ACCOUNT_API,
} from "../../../Constants";
import { PiUploadBold } from "react-icons/pi";
import { FaRegCircleXmark } from "react-icons/fa6";



const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="date-picker-input" onClick={onClick} ref={ref}>
    <input
      type="text"
      readOnly
      value={value}
      placeholder="Select a Booking Date"
      className="mb-3 booking-date"
    />
    <FaCalendarAlt className="calendar-icon" />
  </div>
));


const BookTeleConsultation = (props) => {
  const navigate = useNavigate();

  // const Userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [BookingDate, setBookingDate] = useState(new Date());
  const [selectedGender, setSelectedGender] = useState("");
  const [patientAge, setPatientAge] = useState(null);
  const [remark, setRemark] = useState(null);
  const [patientName, setPatientName] = useState([]);
  const [patientMobile, setPatientMobile] = useState([]);
  const [patientEmail, setPatientEmail] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [isErrorData, setErrorData] = useState([]);
  const [isDataLoading, setDataLoading] = useState();
  const [BookedTeleConsultData, setBookedTeleconsult] = useState(
    JSON.parse(localStorage.getItem("BOOKED_TELECONSULTATION_DATA")) || []
  );
  const [mbConsulttype, setMbconsultType] = useState(localStorage.getItem("mbConsultType"));
  const [bookingStatus, setBookingStatus] = useState(false);
  const [bookingMsg, setBookingMsg] = useState("");
  const [isPaymentModalOpen, setPaymentModal] = useState(false);
  const [paymentMsg, setpaymentMsg] = useState("");
  const [paymentAnimation, setPaymentAnimation] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [timeSlotOptions, setTimeSlotoptions] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [encodedFiles, setEncodedFiles] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false)
  const [formDataState, setFormDataState] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const show_slot = queryParams.get("type");
  const dept_id = queryParams.get("dept_id");
  const token = localStorage.getItem("ACCESS_TOKEN");
  const V2HREF = localStorage.getItem("V2HREF");
  const PackageId = JSON.parse(localStorage.getItem("PackageSelectData"));

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      patientName: patientName || "",
      patientEmail: patientEmail || "",
      patientMobile: patientMobile || "",
      // Add default values for other fields as needed
    },
  });

  const isV2token = queryParams.get("v2Token");
  const v2href = queryParams.get("href");

  if (isV2token) {
    window.history.pushState({}, "", window.location.pathname);
  }

  useEffect(() => {
    const userData = async () => {
      const UserRes = await fetch(`${MY_ACCOUNT_API}`, requestOptions);
      const userResData = await UserRes.json();
      setPatientName(userResData.data?.profile_details?.fullname);
      setPatientMobile(userResData.data?.profile_details?.mobile);
      setPatientEmail(userResData.data?.profile_details?.email);
    };
    userData();
  }, []);

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const [Razorpay] = useRazorpay();

  useEffect(() => {
    const formData = new FormData();
    formData.append("module_id", 3);
    formData.append("price", BookedTeleConsultData.cosult_charge);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    fetchApi(
      WALLET_API,
      requestOptionsCopy,
      setWalletData,
      setDataLoading,
      setErrorData
    );
  }, []);

  const handleModalSubmit = () => {
    setShowModal(false);
    setSubmitLoader(true)
    setPaymentLoading(true)

    const onSubmitData = JSON.stringify({
      date: formatDate(BookingDate),
      dept_id: BookedTeleConsultData.dept_id,
      mb_consult_type: mbConsulttype,
      mb_price: show_slot == "1" ? 0 : BookedTeleConsultData.consult_charge,
      user_id: walletData?.data?.user_id,
      patient_name: patientName,
      user_package_id: PackageId.id,
      mobile_number: patientMobile,
      email_id: patientEmail,
      patient_age: patientAge,
      appointment_date: bookingData,
      gender: selectedGender,
      bookid: BookedTeleConsultData.doctor_id,
      remark: remark,
      files: encodedFiles,
      ...(show_slot !== "symptoms" && { appointment_time: selectedTimeSlot })
    });


    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = onSubmitData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function submitTeleConsultationBooking() {
      try {
        const response = await fetch(
          BOOK_TELECONSULTATION_API,
          requestOptionsCopy
        );
        const res = await response.json();
        if (res.status === 200) {
          if (walletData.data.show_button === "online") {
            if (res && res.razorpay_details) {
              handlePlaceOrder(res.razorpay_details);
            } else if (V2HREF) {
              setBookingStatus(true);
              setBookingMsg(res.message);
              setPaymentLoading(false);
              setTimeout(() => {
                window.location.href = V2HREF;
                setPaymentModal(false);
                setPaymentLoading(false);
                setBookingStatus(false);
              }, 2000);
            }
            else {
              setPaymentLoading(false);
              setBookingStatus(true);
              setBookingMsg(res.message);
              setTimeout(() => {
                setPaymentModal(false);
                setBookingStatus(false);
                navigate("/teleconsultation", { replace: true });
              }, 2000);
            }
          }
        }

        else {
          setBookingStatus(false);
          setBookingMsg(res.msg);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error(error);
      } finally {
        setSubmitLoader(false)
      }
    }
    submitTeleConsultationBooking();
  };

  const handlePlaceOrder = async (razorpay_details) => {
    const handlePayment = async (razorpay_details) => {
      // Define a custom handler function
      const customSuccessHandler = function (response) {
        MakeOnlinePayment(
          razorpay_details.call_back_url,
          response.razorpay_payment_id,
          razorpay_details.insert_id
        );
      };

      setBookingStatus(false);

      const customOptions = {
        handler: customSuccessHandler,
        modal: {
          ondismiss: function () {
            toast.error("Payment Cancelled");
            setTimeout(() => {
              navigate("/teleconsultation", { replace: true });
            }, 1000);
          },
        },
      };

      const rzp1 = new Razorpay({ ...razorpay_details, ...customOptions });

      rzp1.on("payment.failed", function (response) {
        // Handle failed payment
        console.log(
          response.error.code ||
          response.error.description ||
          response.error.source ||
          response.error.step ||
          response.error.reason ||
          response.error.metadata.order_id ||
          response.error.metadata.payment_id
        );
      });

      rzp1.open();
    };

    handlePayment(razorpay_details);

    const payment_data = {
      insert_id: "",
      transaction_id: "",
    };

    const paymentOptions = { ...requestOptions };
    paymentOptions.method = "POST";
    paymentOptions.body = JSON.stringify(payment_data);
    paymentOptions.contentType = false;
    paymentOptions.processData = false;

    const MakeOnlinePayment = async (
      callBackUrl,
      transaction_id,
      insert_id
    ) => {
      setPaymentLoading(true);
      const url = new URL(callBackUrl);

      const payment_Transaction_data = JSON.stringify({
        insert_id: insert_id,
        transaction_id: transaction_id,
        user_package_id: PackageId.id
      })

      const updatedRequestOption = {
        ...paymentOptions,
        body: payment_Transaction_data,
      };

      try {
        const response = await fetch(url.href, updatedRequestOption);

        const res = await response.json();
        if (res.status) {
          setPaymentAnimation(true);
          setpaymentMsg(res.message);
          setPaymentLoading(false);
          setPaymentModal(true);

          if (V2HREF) {
            setTimeout(() => {
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }, 500);
          } else {
            setTimeout(() => {
              setPaymentModal(false);
              navigate("/teleconsultation", { replace: true });
              localStorage.removeItem('V2HREF')
            }, 2500);
          }
        } else {
          setPaymentAnimation(false);
          setpaymentMsg(res.message);
          setPaymentModal(true);
          if (V2HREF) {
            setTimeout(() => {
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }, 500);
          } else {
            setTimeout(() => {
              setPaymentModal(false);
              navigate("/teleconsultation", { replace: true });
              localStorage.removeItem('V2HREF')
            }, 2500);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
  };

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 6);
  ;

  useEffect(() => {
    const formData = new FormData();

    formData.append("date", formatDate(BookingDate));
    formData.append("dept_id", BookedTeleConsultData.dept_id);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;

    async function getTimeSlot() {
      try {
        const response = await fetch(GET_TIME_SLOT_API, requestOptionsCopy);
        const res = await response.json();
        if (res) {
          setTimeSlotoptions(res.time_slot);
        }
      } catch (error) {
        console.error(error);
      }
    }
    // if (show_slot == "1") {
    getTimeSlot();
    // }
  }, [BookingDate]);

  useEffect(() => {
    if (timeSlotOptions && timeSlotOptions.length > 0) {
      setSelectedTimeSlot(timeSlotOptions[0]);
    }
  }, [timeSlotOptions]);

  const formatDate = (date) => {
    if (!date) return "";

    const formattedDate = format(date, "yyyy-MM-dd"); // Format the date to 'yyyy-MM-dd'
    setBookingData(formattedDate);
    return formattedDate;
  };

  useEffect(() => {
    if (BookedTeleConsultData) {
      setValue("patientMobile", patientMobile || "");
      setValue("patientName", patientName || "");
      setValue("patientEmail", patientEmail || "");
    }
  }, [
    BookedTeleConsultData,
    setValue,
    patientEmail,
    patientName,
    patientMobile,
  ]);

  const [files, setFiles] = useState({
    Document: []
  });

  const docTypes = [
    {
      Document: "Document",
    },
  ];

  useEffect(() => {
    if (formDataState) {
      const formDataObject = {};
      for (let pair of formDataState.entries()) {
        formDataObject[pair[0]] = pair[1];
      }
      setPreviewData(formDataObject);
    }
  }, [formDataState]);

  const onSubmit = () => {
    const formData = new FormData();
    formData.append("mb_consult_type", mbConsulttype);
    formData.append("otp_verify", 1);
    formData.append(
      "mb_price",
      show_slot == "1" ? 0 : BookedTeleConsultData.consult_charge
    );
    formData.append("user_id", walletData?.data?.user_id);
    formData.append("patient_name", patientName);
    formData.append("mobile_number", patientMobile);
    formData.append("email_id", patientEmail);
    formData.append("patient_age", patientAge);
    formData.append("appointment_date", bookingData);
    formData.append("gender", selectedGender);
    formData.append("bookid", BookedTeleConsultData.doctor_id);
    formData.append("dept_id", BookedTeleConsultData.dept_id);
    formData.append("remark", remark);
    formData.append("files", encodedFiles);

    if (show_slot !== "symptoms") {
      formData.append("appointment_time", selectedTimeSlot);
    }

    setFormDataState(formData);
    setShowModal(true);
  };


  const documentTypes = Object.keys(files);

  const handleFileChange = (e, documentData) => {
    const newFiles = Array.from(e.target.files);

    setFiles((prevFiles) => {
      // Filter out files with the same name
      const uniqueNewFiles = newFiles.filter(
        (newFile) =>
          !prevFiles[documentData].find(
            (prevFile) => prevFile.name === newFile.name
          )
      );
      return {
        ...prevFiles,
        [documentData]: [...prevFiles[documentData], ...uniqueNewFiles],
      };
    });
  };

  const handleFileDelete = (e, documentType, file, index) => {
    e.preventDefault();
    const updatedDoc = files[documentType].filter(
      (myfile) => myfile.name !== file.name
    );
    setFiles((prevFiles) => ({
      ...prevFiles,
      [documentType]: updatedDoc,
    }));
  };

  useEffect(() => {
    const readFiles = async () => {
      const readFile = (file, documentType) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64Data = reader.result.split(",")[1];
            const documentData = {
              file_type: docTypes[0] ? docTypes[0][documentType] : "",
              file_name: file.name,
              file_path: base64Data,
            };
            resolve(documentData);
          };
          reader.onerror = () =>
            reject(new Error(`Error reading file: ${file.name}`));
          reader.readAsDataURL(file);
        });
      };

      const promises = [];
      Object.entries(files).forEach(([documentType, fileInputs]) => {
        if (fileInputs.length > 0) {
          Array.from(fileInputs).forEach((file) => {
            promises.push(readFile(file, documentType));
          });
        }
      });

      try {
        const documentDataArray = await Promise.all(promises);
        setEncodedFiles((prevState) => {
          // Only update state if the data has actually changed
          if (JSON.stringify(prevState) !== JSON.stringify(documentDataArray)) {
            return documentDataArray;
          }
          return prevState;
        });
      } catch (error) {
        console.error("Error reading files", error);
      }
    };

    readFiles();
  }, [files, docTypes]);

  return (
    <>
      <div className="home-review-banner mb-4">
        <div className="home-app-header d-flex justify-content-start align-items-center">
          <div className="home-header-teleconsultation d-flex justify-content-between align-items-center">
            <div className="header-navigation-icon">
              {isV2token ? (
                <h6 className="mb-0">Nutrition</h6>
              ) : (
                <h6 className="mb-0">
                  {BookedTeleConsultData.department_name}
                </h6>
              )}
            </div>
          </div>
        </div>
        {isV2token ? (
          <div className="banner-cover">
            <img src={Nutition_banner}></img>
          </div>
        ) : (
          <>
            <div className="booking-test-details p-3">
              <div className="booking-details-box d-flex align-items-center">
                <img
                  src={`${IMG_PATH}${BookedTeleConsultData.department_image}`}
                  width="100%"
                  alt="Cart-icon"
                />
                <p>
                  {BookedTeleConsultData?.department_descriptoon
                    ? BookedTeleConsultData?.department_descriptoon
                    : BookedTeleConsultData?.department_name}
                </p>
              </div>
              {show_slot == "1" && (
                <div className="booking-price-box">
                  <span>₹ 0</span>
                </div>
              )}
              {show_slot !== "1" && (
                <div className="booking-price-box">
                  {<span>₹ {BookedTeleConsultData?.cosult_charge}</span>}
                </div>
              )}
            </div>
          </>
        )}

        <div className="Booking-teleconsult-main-wrapper px-3">
          <p className="book-teleconsult-inner-heading">
            Teleconsultation will be conducted for this person
          </p>
          <div className="book-now-form-main-cover">
            <div className="book-now-form">
              <h6 className="tele-form-heading">Enter Patient Details</h6>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 book-now-input-box">
                  <label className="tele-labels">Patient Name</label>
                  <input
                    type="text"
                    placeholder="Patient Name"
                    {...register("patientName", {
                      required: "Please enter your Name",
                    })}
                    value={patientName}
                    onChange={(e) => {
                      setPatientName(e.target.value);
                    }}
                    onInput={(e) => validateTextInput(e.target)}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientName && errors.patientName.message}
                  </span>
                </div>
                <label className="tele-labels">Set Appointment Date</label>
                <div className="date-prod-data-box">
                  <DatePicker
                    name="start_date"
                    selected={BookingDate}
                    onChange={(date) => setBookingDate(date)}
                    placeholderText="Select a Booking Date"
                    showYearDropdown={false}
                    showMonthDropdown={false}
                    autoComplete="off"
                    minDate={new Date()}
                    maxDate={maxDate}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInput />}
                  />
                </div>
                {show_slot !== "symptoms" && (
                  <div className="time-slot-input-box mb-3">
                    <label htmlFor="selectOption" className="tele-labels">
                      Select Appointment Time
                    </label>
                    <select
                      id="selectOption"
                      value={selectedTimeSlot}
                      onChange={(e) => setSelectedTimeSlot(e.target.value)}
                      required
                    >
                      {timeSlotOptions?.length > 0 &&
                        timeSlotOptions.map((timeslot) => (
                          <option key={timeslot} value={timeslot}>
                            {timeslot}
                          </option>
                        ))}
                    </select>
                    {timeSlotOptions && timeSlotOptions?.length === 0 && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          marginLeft: "3px",
                        }}
                      >
                        No time slots available
                      </p>
                    )}
                  </div>
                )}

                <div className="mb-3 book-now-input-box">
                  <label className="tele-labels">Age</label>
                  <input
                    type="text"
                    maxLength={2}
                    placeholder="Age"
                    {...register("patientAge", {
                      required: "Please enter your Age",
                      pattern: {
                        value: /^\d+$/,
                        message: "Please enter a valid age",
                      },
                    })}
                    defaultValue={patientAge}
                    onInput={(e) => validateNumberInput(e)}
                    onChange={(e) => {
                      setPatientAge(e.target.value);
                    }}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientAge && errors.patientAge.message}
                  </span>
                </div>
                <div className="gender-input-radio-box mb-3">
                  <Form.Group>
                    <Form.Label className="radio-title-label">
                      Select Gender
                    </Form.Label>
                    <InputGroup>
                      <div className="radio-input-box d-flex align-items-center">
                        <InputGroup.Radio
                          name="gender"
                          value="male"
                          checked={selectedGender === "male"}
                          onChange={handleGenderChange}
                          class="gender-input-radio p-0"
                          required
                        />
                        <Form.Label className="mr-2">Male</Form.Label>
                      </div>

                      <div className="radio-input-box d-flex align-items-center">
                        <InputGroup.Radio
                          name="gender"
                          value="female"
                          checked={selectedGender === "female"}
                          onChange={handleGenderChange}
                          class="gender-input-radio"
                        />
                        <Form.Label>Female</Form.Label>
                      </div>

                      <div className="radio-input-box d-flex align-items-center">
                        <InputGroup.Radio
                          name="gender"
                          value="other"
                          checked={selectedGender === "other"}
                          onChange={handleGenderChange}
                          class="gender-input-radio"
                        />
                        <Form.Label>Other</Form.Label>
                      </div>
                    </InputGroup>
                  </Form.Group>
                </div>

                <div className="upload-form-data">
                  <label>Upload <span>(Upload JPG,PNG,PDF File format)</span></label>
                  <div className="file-data-box-repo">
                    {documentTypes.map((documentData, index) => (
                      <div className="upload-document-boxlist-cover">
                        <div className="upload-document-list-header" key={index}>
                          <div className="upload-data-box-repo-site tele-data-text">
                            <h6>{docTypes[0][documentData]}</h6>
                            <div className="upload-document-btn-box tele-form-data">
                              <button>
                                <span>
                                  <PiUploadBold />
                                </span>
                                <input
                                  type="file"
                                  name="claim_upload_file[]"
                                  onChange={(e) =>
                                    handleFileChange(e, documentData)
                                  }
                                  style={{
                                    opacity: 0,
                                    width: 100,
                                    position: "absolute",
                                    left: "-8px",
                                    top: 3,
                                  }}
                                  multiple
                                />
                                <p>Upload</p>
                              </button>
                            </div>
                          </div>



                          <div
                            className={
                              `${files[documentData]?.length > 2}`
                                ? "uploaded-documents-list-box height-style"
                                : "uploaded-documents-list-box"
                            }
                          >

                            {files[documentData].length > 0 ? (
                              files[documentData].map((file, index) => (
                                <div
                                  className="uploaded-documents-list"
                                  key={index}
                                >
                                  <h6>{file.name}</h6>
                                  <button
                                    onClick={(e) =>
                                      handleFileDelete(
                                        e,
                                        documentData,
                                        file,
                                        index
                                      )
                                    }
                                  >
                                    <FaRegCircleXmark />
                                  </button>
                                </div>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>

                        </div>

                      </div>
                    ))}
                  </div>
                </div>

                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="tele-labels">Remark</label>
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    rows={4}
                    cols={50}
                    {...register("remark")}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </Form.Group>


                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="tele-labels">Phone Number</label>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    {...register("patientMobile", {
                      required: "Please enter your number",
                    })}
                    value={patientMobile}
                    onChange={(e) => setPatientMobile(e.target.value)}
                    onInput={(e) => validateNumberInput(e)}
                    maxLength={10}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientMobile && errors.patientMobile.message}
                  </span>
                </Form.Group>
                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="tele-labels">Email Address</label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    {...register("patientEmail", {
                      // pattern: {
                      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      //   message: "Please enter a valid email address",
                      // },
                    })}
                    value={patientEmail}
                    onChange={(e) => {
                      setPatientEmail(e.target.value);
                    }}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientEmail && errors.patientEmail.message}
                  </span>
                </Form.Group>
                {paymentType === "wallet" && (
                  <Form.Check
                    type="checkbox"
                    className="payment_method"
                    label="Make Payment from Wallet"
                  />
                )}
                {/* {walletData?.show_wallet &&
                walletData?.show_wallet ==
                  "0"(
                    
                  )} */}

                <p className="wallet_summary-point"></p>
                <div className="submit-btn-box d-flex justify-content-center">
                  <button type="submit" disabled={submitLoader}>{submitLoader ? "Submitting" : "Submit"}</button>
                </div>
              </form>

              <Modal show={showModal} onHide={() => setShowModal(false)} className="preview-data-box-con">
                <Modal.Header closeButton className="details-data-box-con">
                  <Modal.Title className="data-text-bee">
                    <p>Preview Details</p>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="repo-tele-body">
                  <p><strong>Patient Name : </strong> {previewData?.patient_name}</p>
                  <p><strong>Patient Age:</strong> {previewData?.patient_age} Years</p>
                  <p><strong>Patient Gender:</strong> {previewData?.gender}</p>
                  <p><strong>Appointment Date:</strong> {previewData?.appointment_date}</p>
                  <p><strong>Appointment Time:</strong> {previewData?.appointment_time}</p>
                </Modal.Body>
                <Modal.Footer className="footer-text-data">
                  <Button variant="primary" onClick={handleModalSubmit}>
                    Proceed
                  </Button>
                </Modal.Footer>
              </Modal>

            </div>
          </div>
        </div>
        <div className="middle_toaster">
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "custom-toast position-middle-center",
              duration: 2000,
              style: {
                background: "#fff",
                color: "#363636",
                fontSize: "14px",
                width: "300px",
                top: "55px !important",
              },
            }}
          />
        </div>
        {bookingStatus && (
          <SuccessFull
            show={bookingStatus}
            successMessage={bookingMsg}
            animationtype={bookingStatus}
          />
        )}
        {paymentAnimation && (
          <PaymentModal
            show={isPaymentModalOpen}
            successMessage={paymentMsg}
            paymentAnimation={paymentAnimation}
          />
        )}

        {isPaymentLoading && (
          <div className="payment-loading-page">
            <PaymentLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default BookTeleConsultation;
