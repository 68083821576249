import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import {
  ArrowBack,
  appAmbulanceImg,
  appAmbulanceImgage,
  appLocationAmbulance,
  headerLogo,
} from "../../images";
import Form from "react-bootstrap/Form";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Loader from "../../Animation/Loader";
import SuccessFull from "../../Animation/SuccessFull";
import { AMBULANCE_REQUEST_API, PINCODE_STATE_CITY_API, REQUESTOPTIONS } from '../../Constants'


const AmbulanceAssistance = () => {
  const [isAlternateLocation, setIsAlternateLocation] = useState(false);
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);
  const [pickupaddress2, setPickupAddress2] = useState("");
  const [landmarkcurrent, setLandmarkCurrent] = useState("");
  const [loadingHomeData, setLoadingHomeData] = useState(true);
  const [showambulanceaddress, setShowAmbulanceAddress] = useState(true);
  const [dropaddress, setDropAddress] = useState(true);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [pincode, setPincode] = useState(null);
  const [pincodeData, setPincodeData] = useState(null);
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [userPincode, setuserPincode] = useState(null);
  const navigate = useNavigate();
  const { user_package_id } = useParams();
  const [alternateLocationData, setAlernateLocationData] = useState(
    JSON.parse(localStorage.getItem("alternateLocationData"))
  );
  const [currentLocationData, setCurrentLocationData] = useState(
    JSON.parse(localStorage.getItem("currentlocation"))
  );
  // console.log(currentLocationData.txt_pickup_address_1);
  const [hospitalLocation, setHospitalLocation] = useState({
    pincode: null,
    state: null,
    city: null,
    address1: null,
    address2: null,
    landmark: null,
  });
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    pincode: null,
    state: null,
    city: null,
    landmark: null,
  });
  const [alterlocation, setAlterLocation] = useState({
    pincode: null,
    state: null,
    city: null,
    landmark: null,
    address1: null,
    address2: null,
  });

  const fetchPincodeData = async () => {
    try {
      const formData = new FormData();
      formData.append("pincode", pincode);

      const PincodeRes = await fetch(PINCODE_STATE_CITY_API, {
        headers: {
          Authorization: `${token}`,
        },
        method: "POST",
        body: formData,
      });

      if (!PincodeRes.ok) {
        console.error("API error:", PincodeRes.status, PincodeRes.statusText);
        const errorData = await PincodeRes.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      const pincodeData = await PincodeRes.json();
      setPincodeData(pincodeData?.data);

      return pincode;
    } catch (error) {
      console.error("Api Unsuccessfull", error);
    }
  };

  const isValidPincode = (code) => /^[0-9]{6}$/.test(code);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "pincode" && isValidPincode(value)) {
      setPincode(value);
    } else {
      setAlterLocation((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleHospitalChange = (e) => {
    setuserPincode(e.target.value);
    const { name, value } = e.target;
    setHospitalLocation((prev) => ({ ...prev, [name]: value }));
    if (name === "pincode" && isValidPincode(value)) {
      setPincode(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isValidPincode(pincode)) {
          const pincodeData = await fetchPincodeData();
          if (pincodeData) {
            setPincode(pincodeData.pincode);
          } else {
            console.error("Invalid pincode data");
          }
        } else {
          console.error("Invalid pincode format");
        }
      } catch (error) {
        console.error("Error fetching pincode data:", error);
      }
    };

    fetchData();
  }, [pincode]);

  const getLocation = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;

            const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBz8qUh5g91GyggiZf6eNqOC6jKMErllxA`;

            const response = await fetch(geocodingApiUrl);
            const data = await response.json();

            if (data.results && data.results.length > 0) {
              const addressComponents = data.results[0].address_components;
              const pincode = addressComponents.find((component) =>
                component.types.includes("postal_code")
              ).long_name;
              const state = addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              ).long_name;
              const city = addressComponents.find((component) =>
                component.types.includes("locality")
              ).long_name;
              const fullAddress = data.results[0].formatted_address;
              const shortAddress = `${addressComponents[0].short_name},${addressComponents[1].short_name}`;
              const shortAddress2 = `${addressComponents[3].short_name},${addressComponents[4].short_name}`;

              setLocation({
                latitude,
                longitude,
                pincode,
                state,
                city,
                fullAddress,
                shortAddress,
                shortAddress2,
              });
            } else {
              console.error(
                "Invalid response structure from Google Maps Geocoding API:",
                data
              );
            }
          },
          (error) => {
            console.error("Error getting location:", error.message);
          },
          {
            enableHighAccuracy: true,
          }
        );
      } else {
        console.error("Geolocation is not supported by your browser");
      }
    } catch (error) {
      console.error("Error getting location:", error.message);
    } finally {
      setLoadingHomeData(false);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const handleRadioChange = () => {
    setIsAlternateLocation((prev) => !prev);

    if (!isAlternateLocation) {
      getLocation();
    }
  };

  let formData = new FormData();

  const getCurrentLocationData = () => {
    console.log("All Data:", {
      location,
      landmarkcurrent,
    });

    formData.append("vendor_name", "package");
    formData.append("txt_pickup_address_1", location.shortAddress);
    formData.append("txt_pickup_address_2", location.shortAddress2);
    formData.append("txt_pickup_landmark", landmarkcurrent);
    formData.append("txt_pickup_pincode", location.pincode);
    formData.append("txt_pickup_city", location.city);
    formData.append("txt_pickup_state", location.state);

    alert("success for current location");

    localStorage.setItem(
      "currentlocation",
      JSON.stringify({
        vendor_name: "package",
        txt_pickup_address_1: location.shortAddress,
        txt_pickup_address_2: location.shortAddress2,
        txt_pickup_landmark: landmarkcurrent,
        txt_pickup_pincode: location.pincode,
        txt_pickup_city: location.city,
        txt_pickup_state: location.state,
      })
    );

    // gethospitalData();
  };

  const gethospitalData = async () => {

    const formData = new FormData();
    formData.append("vendor_name", "package");
    formData.append(
      "txt_pickup_address_1",
      alternateLocationData?.txt_pickup_address_1 ||
      currentLocationData?.txt_pickup_address_1
    );
    formData.append(
      "txt_pickup_address_2",
      alternateLocationData?.txt_pickup_address_2 ||
      currentLocationData?.txt_pickup_address_2
    );
    formData.append(
      "txt_pickup_landmark",
      alternateLocationData?.txt_pickup_landmark ||
      currentLocationData?.txt_pickup_landmark
    );
    formData.append(
      "txt_pickup_pincode",
      alternateLocationData?.txt_pickup_pincode ||
      currentLocationData?.txt_pickup_pincode
    );
    formData.append(
      "txt_pickup_city",
      alternateLocationData?.txt_pickup_city ||
      currentLocationData?.txt_pickup_city
    );
    formData.append(
      "txt_pickup_state",
      alternateLocationData?.txt_pickup_state ||
      currentLocationData?.txt_pickup_state
    );
    formData.append("txt_drop_address_1", hospitalLocation?.address1 || '');
    formData.append("txt_drop_address_2", hospitalLocation?.address2 || '');
    formData.append("txt_drop_landmark", hospitalLocation?.landmark || '');
    formData.append("txt_drop_pincode", hospitalLocation?.pincode || '');
    formData.append(
      "txt_drop_city",
      pincodeData?.district || hospitalLocation.city
    );
    formData.append(
      "txt_drop_state",
      pincodeData?.state || hospitalLocation.state
    );
    formData.append("lat", location?.latitude);
    formData.append("long", location?.longitude);
    formData.append("user_package_id", user_package_id);

    

    localStorage.setItem(
      "hospitalLocationData",
      JSON.stringify({
        vendor_name: "package",
        txt_pickup_address_1:
          alterlocation.address1 || currentLocationData.address1,
        txt_pickup_address_2:
          alterlocation.address2 || currentLocationData.address2,
        txt_pickup_landmark:
          alterlocation.landmark || currentLocationData.landmark,
        txt_pickup_pincode:
          alterlocation.pincode || currentLocationData.pincode,
        txt_pickup_city: alterlocation.city || currentLocationData.city,
        txt_pickup_state: alterlocation.state || currentLocationData.state,
        txt_drop_address_1: hospitalLocation.address1,
        txt_drop_address_2: hospitalLocation.address2,
        txt_drop_landmark: hospitalLocation.landmark,
        txt_drop_pincode: hospitalLocation.pincode,
        txt_drop_city: pincodeData?.district || hospitalLocation.city,
        txt_drop_state: pincodeData?.state || hospitalLocation.state,
        lat: location.latitude,
        long: location.longitude,
        user_package_id: user_package_id,
      })
    );

    try {
      const homeDataRes = await fetch(`${AMBULANCE_REQUEST_API}`, {
        headers: {
          Authorization: `${token}`,
        },
        method: "POST",
        body: formData,
      });

      if (!homeDataRes.ok) {
        console.error("API error:", homeDataRes.status, homeDataRes.statusText);
        const errorData = await homeDataRes.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      const medicineData = await homeDataRes.json();
      if (medicineData.status === 200) {
        setSuccessModal(true);
        setSuccessMessage(medicineData.message);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setSuccessModal(false);

          localStorage.removeItem("alternateLocationData");
          localStorage.removeItem("currentlocation");
          localStorage.removeItem("hospitalLocationData");

          navigate("/home");
        }, 3000);
      } else {
        alert("Unsuccessful submission");
      }
    } catch (error) {
      console.error("Error fetching home data:", error);
    }
  };
  const getAlternateLocationData = () => {
    console.log("Alternate Location Data:", {
      alterlocation,
    });

    const formData = new FormData();
    formData.append("vendor_name", "package");
    formData.append("txt_pickup_address_1", alterlocation.address1);
    formData.append("txt_pickup_address_2", alterlocation.address2);
    formData.append("txt_pickup_landmark", alterlocation.landmark);
    formData.append("txt_pickup_pincode", alterlocation.pincode);
    formData.append("txt_pickup_city", pincodeData?.district);
    formData.append("txt_pickup_state", pincodeData?.state);

    localStorage.setItem(
      "alternateLocationData",
      JSON.stringify({
        vendor_name: "package",
        txt_pickup_address_1: alterlocation.address1,
        txt_pickup_address_2: alterlocation.address2,
        txt_pickup_landmark: alterlocation.landmark,
        txt_pickup_pincode: alterlocation.pincode,
        txt_pickup_city: pincodeData?.district,
        txt_pickup_state: pincodeData?.state,
      })
    );

    console.log("Alternate Location Form Data:", formData);
    alert("success for alternate location");
  };

  const handleCurrentChange = (e) => {
    const { name, value } = e.target;
    setLocation((prev) => ({ ...prev, [name]: value }));
  };
  const toggleSection = () => {
    setShowAmbulanceAddress(false);
    setDropAddress(false);
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header nutrition-repo-box">
         
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p>Emergency Ambulance Assistance</p>
            </div>
          </div>
        </div>

        <div class="ambulance-cover-section">
          <div class="ambulance-header-image">
            <img src={appAmbulanceImgage} width="100%" alt="review" />
            <p>Emergency Ambulance Assistance</p>
          </div>
        </div>

        <div className="ambulance-location locationdiv">
          <img src={appLocationAmbulance} width="100%" alt="review" />
          <p>Select location for Pick Up</p>
        </div>
        {loadingHomeData && loadingHomeData ? (
          <p>
            <Loader />
          </p>
        ) : (
          <>
            {showambulanceaddress && (
              <div className="ambulance-location-section-box">
                <div className="ambulance-location-box-cover locationdiv">
                  <Form.Check
                    type="radio"
                    label="Current Location"
                    name="gender"
                    id="current-radio"
                    className="location-repo"
                    checked={!isAlternateLocation}
                    onChange={handleRadioChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Alternate Location"
                    name="gender"
                    id="Alternate-radio"
                    className="location-repo"
                    checked={isAlternateLocation}
                    onChange={handleRadioChange}
                  />
                </div>
                {!isAlternateLocation &&
                  googleApiLoaded &&
                  !isAlternateLocation ? (
                  <div className="ambulance-current-location-section myDiv locationdiv">
                    <div className="google-map-repo">
                      <GoogleMap
                        className="google-map-repo"
                        mapContainerStyle={{ width: "90%", height: "200px" }}
                        zoom={14}
                        center={{
                          lat: location.latitude,
                          lng: location.longitude,
                        }}
                        options={{
                          mapTypeControl: false,
                          streetViewControl: false,
                          fullscreenControl: false,
                          keyboardShortcuts: false,
                          zoomControl: false,
                        }}
                      >
                        <Marker
                          position={{
                            lat: location.latitude,
                            lng: location.longitude,
                          }}
                        />
                      </GoogleMap>
                    </div>
                    <div className="ambulance-form-box">
                      <label>Address Line 1</label>
                      <input
                        type="text"
                        name="address1"
                        value={location.shortAddress}
                        placeholder="Enter Your Address Line 1"
                      />
                      <label>Address Line 2 (Optional)</label>
                      <input
                        type="text"
                        name="address2"
                        value={location.shortAddress2}
                        onChange={handleCurrentChange}
                        placeholder="Enter Your Address Line 2"
                      />
                      <label>Landmark (Optional)</label>
                      <input
                        type="text"
                        name="landmark"
                        onChange={handleCurrentChange}
                        placeholder="Enter Landmark"
                      />
                      <label>Pincode</label>
                      <input
                        type="text"
                        name="pincode"
                        value={location.pincode}
                        placeholder="Enter Pincode"
                      />
                      <label>State</label>
                      <input
                        type="text"
                        name="state"
                        value={location.state}
                        placeholder="Enter State"
                      />
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        value={location.city}
                        placeholder="Enter City"
                      />

                      <button
                        id="submitButton"
                        onClick={() => {
                          getCurrentLocationData();
                          toggleSection();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="ambulance-form-box">
                    <label>Address Line 1</label>
                    <input
                      type="text"
                      name="address1"
                      onChange={handleInputChange}
                      placeholder="Enter Your Address Line 1"
                    />
                    <label>Address Line 2</label>
                    <input
                      type="text"
                      name="address2"
                      onChange={handleInputChange}
                      placeholder="Enter Your Address Line 2"
                    />
                    <label>Landmark</label>
                    <input
                      type="text"
                      name="landmark"
                      onChange={handleInputChange}
                      placeholder="Enter Landmark"
                    />
                    <label>Pincode</label>
                    <input
                      type="text"
                      name="pincode"
                      value={pincode}
                      onChange={handleInputChange}
                      placeholder="Enter Pincode"
                    />

                    <label>State</label>
                    <input
                      type="text"
                      name="state"
                      onChange={handleInputChange}
                      value={pincodeData ? pincodeData?.state : ""}
                      placeholder="Enter State"
                    />
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      value={pincodeData ? pincodeData?.district : ""}
                      onChange={handleInputChange}
                      placeholder="Enter City"
                    />

                    <button
                      id="submitButton"
                      onClick={() => {
                        getAlternateLocationData();
                        toggleSection();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                )}
                {!googleApiLoaded && (
                  <LoadScript
                    googleMapsApiKey="AIzaSyBz8qUh5g91GyggiZf6eNqOC6jKMErllxA"
                    onLoad={() => {
                      setGoogleApiLoaded(true);
                    }}
                  />
                )}
              </div>
            )}
          </>
        )}

        {!dropaddress && (
          <div className="ambulance-form-box">
            <label>Hospital Name</label>
            <input
              type="text"
              name="address1"
              onChange={(e) => handleHospitalChange(e)}
              placeholder="Enter Your Address Line 1"
            />
            <label>Address Line 1</label>
            <input
              type="text"
              name="address2"
              onChange={handleHospitalChange}
              placeholder="Enter Your Address Line 1"
            />
            <label>Address Line 2 (optional)</label>
            <input
              type="text"
              name="pickupaddress2"
              onChange={handleHospitalChange}
              placeholder="Enter Your Address Line 2"
            />
            <label>Landmark</label>
            <input
              type="text"
              name="landmark"
              onChange={handleHospitalChange}
              placeholder="Enter Landmark"
            />
            <label>Pincode</label>
            <input
              type="text"
              name="pincode"
              value={pincode}
              onChange={handleHospitalChange}
              placeholder="Enter Pincode"
            />
            <label>State</label>
            <input
              type="text"
              name="state"
              value={pincodeData ? pincodeData?.state : userPincode}
              onChange={handleHospitalChange}
              placeholder="Enter State"
            />
            <label>City</label>
            <input
              type="text"
              name="city"
              value={pincodeData ? pincodeData?.district : ""}
              onChange={handleHospitalChange}
              placeholder="Enter City"
            />

            <button id="submitButton" onClick={gethospitalData}>
              Submit
            </button>
          </div>
        )}

        {successmodal && (
          <SuccessFull show={modalShow} successMessage={successMessage} />
        )}
      </div>
    </>
  );
};

export default AmbulanceAssistance;
