import React from 'react'
import Medicine_Header from '../Components/Medicine_Header'
import '../Medicine_Style/doctor.css'
import useLocationState from '../hooks/useLocationState'
const Medicine_Doctor = () => {
    const Title = useLocationState();
    return (
        <div className="home-review-banner">
            <Medicine_Header Title={Title}/>

            <div className="doctor-text-repo">
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
            </div>

            <div className="doctor-form-cover-repo">
                <div className="input-cover-form">
                    <label>Name</label>
                    <input type="text" placeholder='Enter Name' />
                </div>
                <div className="input-cover-form">
                    <label>Mobile Number</label>
                    <input type="text" placeholder='Enter Mobile Number' />
                </div>
                <div className="input-cover-form">
                    <label>Email Address</label>
                    <input type="text" placeholder='Enter Email Address' />
                </div>
                <div className="input-cover-form">
                    <label>Remark</label>
                    <textarea placeholder='Remark'/>
                </div>
            </div>

            <div className="doctor-submit-btn">
                <button>SUBMIT</button>
            </div>
        </div>
    )
}

export default Medicine_Doctor