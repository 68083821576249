import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import { Toaster, toast } from "react-hot-toast";
import Form from "react-bootstrap/Form";
import { ADD_ADDRESS, MEDICINE_CART_MANAGER_LIST, USER_ADDRESS_API } from "../../Constants";
import {
  ArrowBack,
  appCartIcon,
  appHeartIcon,
  appDeleteIcon,
  appEdit,
} from "../../images";
import LocationSection from "../LocationSection/LocationSection";
import SuccessFull from "../../Animation/SuccessFull";
import AddAddress from "../../Animation/AddAddress";

const Address_Details = () => {
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [loadingHomeData, setLoadingHomeData] = useState(true);
  const [mainCheckboxChecked, setMainCheckboxChecked] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // const [cartLength,setCartLength]=useState(JSON.parse(localStorage.getItem('medicineCartData')))
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [pincodeData, setPincodeData] = useState(
    JSON.parse(localStorage.getItem("pincode")) || {}
  );
  const [responseData, setResponseData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("ACCESS_TOKEN");
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };

  useEffect(() => {
    MangerListDataRepo();
  }, [])

  const { id } = useParams();
  const MangerListDataRepo = async () => {
    try {
      const ManagerListRes = await fetch(
        `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
        {
          headers: {
            Authorization: `${token}`,
          },
          method: "GET",
        }
      );
      const ListDataRes = await ManagerListRes.json();

      setResponseData(ListDataRes?.skus_res || []);

      setLoadingHomeData(false);
    } catch (error) {
      console.log(error);
      setLoadingHomeData(false);
    }
  };

  useEffect(() => {
    const FetchAddressDetailsList = async (id) => {
      setDataLoading(true);
      try {
        const apiUrl = USER_ADDRESS_API;
        const response = await fetch(apiUrl, requestOptions);

        const res = await response.json();
        if (res.status) {
          setAddressData(res.data);
          setDataLoading(false);
          // console.log(res.data);
        } else {
          // console.log(res);
        }
      } catch (error) {
        console.error(error);
        setDataLoading(false);
      }
    };

    FetchAddressDetailsList();
  }, []);

  const deleteAddress = async (id) => {
    try {
      const apiUrl = `${ADD_ADDRESS}`;
      const formData = new FormData();

      formData.append("post_type", "delete");
      formData.append("address_id", id);
      formData.append("type", "");
      formData.append("address", "");
      formData.append("tower_plot_no", "");
      formData.append("society_name", "");
      formData.append("pincode", "");
      formData.append("city", "");
      formData.append("state", "");
      formData.append("name", "");
      formData.append("email", "");
      formData.append("mobile", "");
      formData.append("landmark", "");

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });

      const res = await response.json();
      if (res.status) {
        setAddressData((prevAddresses) =>
          prevAddresses.filter((addressDetails) => addressDetails.id !== id)
        );
      } else {
        console.log(res.message); // Log any error messages
      }

      if (res.status === 200) {
        setSuccessModal(true);
        setSuccessMessage(res.message);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setSuccessModal(false);
          navigate("/addressdetail");
        }, 3000);
      } else {
        alert("Unsuccessful submission");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleCheckbox = (id) => {
    setMainCheckboxChecked(id);
    setSelectedAddresses((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };
  const storeSelectedAddresses = () => {
    if (mainCheckboxChecked) {
      const selectedData = addressData.filter(
        (addressDetails) => addressDetails.id === mainCheckboxChecked
      );
      localStorage.setItem("SelectedAddresses", JSON.stringify(selectedData));
      navigate("/placeorder");
    } else {
      // Handle the case where no checkbox is checked
      toast.error("Please select an Address");
    }
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="app-header-wraper">
      
          <div className="header-top-section">
            <div className="header-top-section-category-section">
              <h3>Address Details</h3>
              <LocationSection />
            </div>
            <div className="header-top-section-add-to-cart-section">
              <button>
                <img src={appCartIcon} width="100%" alt="Cart-icon" />
                {responseData.length > 0 && (
                  <span className="Header-count-number cart-count-no">{responseData && responseData.length}</span>
                )}
              </button>
            </div>
            <div className="header-top-section-add-to-cart-section-hert">
              <img src={appHeartIcon} width="100%" alt="heart-icon" />
            </div>
          </div>
        </div>
        <div className="address-detail-box">
          {addressData.length > 0 ? (
            addressData.map((addressDetails, index) => {
              return (
                <>
                  <div className="addres-details-wrapper" key={index}>
                    <div className="address-left-wrapper">
                      <div className="address-home">
                        <Form.Check
                          type="checkbox"
                          value={addressDetails.id}
                          checked={mainCheckboxChecked === addressDetails.id}
                          onChange={(e) => toggleCheckbox(addressDetails.id)}
                        />
                        <p>{addressDetails.type} Address</p>
                      </div>
                      <div className="address-edit">
                        <Link to={`/medicine-address/${index}`}>
                          <button>
                            <p>
                              <img src={appEdit} width="100%" alt="Edit" />
                              <span>Edit</span>
                            </p>
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div className="address-content-box">
                      <p>{addressDetails.name}</p>
                      <p>
                        {addressDetails.mobile} {", "}{" "}
                        <span>{addressDetails.email}</span>
                      </p>
                      <p>{addressDetails?.address}</p>
                      <p>
                        
                        <span>{addressDetails.city}</span>
                        {","}
                        <span>{addressDetails.state}</span>
                        {", "}
                        <span>{addressDetails.pincode}</span>
                      </p>
                      <p>Landmark : {addressDetails.landmark}</p>
                    </div>
                    <div className="trash-icon">
                      <button onClick={() => deleteAddress(addressDetails.id)}>
                        <img src={appDeleteIcon} width="100%" alt="Delete" />
                      </button>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="animation-add-address">
              <AddAddress />
              <div className="add-address-btn">
                <NavLink to={`/medicine-address`}>
                  <button>ADD ADDRESS</button>
                </NavLink>
              </div>
            </div>
          )}
        </div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 3000,
            style: {
              background: "#fff",
              color: "#363636",
            },
          }}
        />
      </div>

      {successmodal && (
        <SuccessFull show={modalShow} successMessage={successMessage} animationtype={successmodal} />
      )}
      {addressData.length > 0 && (
        <div className="add-continue-button">
          <div className="add-button-repo">
            <NavLink to="/medicine-address">
              <button>ADD ADDRESS</button>
            </NavLink>
          </div>
          <div className="continue-button-repo">
            <>
              <button onClick={storeSelectedAddresses}>CONTINUE</button>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default Address_Details;
