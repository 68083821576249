import React, { useEffect, useState } from "react";
import { headerLogo } from "../../images";
import {
  REQUESTOPTIONS,
  TP_PRO_FIT_PACKAGE_API,
  TP_BUY_CULT_PACKAGE_API,
} from "../../Constants";
import "react-datepicker/dist/react-datepicker.css";
import useQuery from "../../CustomHooks/useQuery";
import SuccessFull from "../../Animation/SuccessFull";
import Loader from "../../Animation/Loader";

const FitnessPro = (props) => {
  const package_id = useQuery("package_id");
  const module_id = useQuery("module_id");
  const title = useQuery("title");
  const description = useQuery("description");
  const [profitPackageData, setProfitPackageData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading , setLoading] = useState(false);
  const [ispackageLoading , setPackageLoading] = useState(false)
  const token = localStorage.getItem("ACCESS_TOKEN");

  
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };

  useEffect(() => {
    async function getTpProFitPackageData() {
      setPackageLoading(true)
      try {
        const response = await fetch(
          `${TP_PRO_FIT_PACKAGE_API}`,
          requestOptions
        );
        const res = await response.json();
        setProfitPackageData(res);
        setPackageLoading(false)
      } catch (error) {
        console.error(error);
      }
    }
    getTpProFitPackageData();
  }, []);

  const handleBuyCultPackage = (cultPackageData) => {
    // console.log(cultPackageData)
    const formData = new FormData();
    formData.append("user_package_id", package_id);
    formData.append("module_id", module_id);
    formData.append("package_type_id", cultPackageData?.package_code);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function submitCultPackage() {
      try {
        const response = await fetch(
          `${TP_BUY_CULT_PACKAGE_API}`,
          requestOptionsCopy
        );
        const res = await response.json();
        setLoading(true)
        if (res.status) {
          setSuccessModal(true);
          setSuccessMessage(res.message);
          setModalShow(true);
          setLoading(false);
          setTimeout(() => {
            window.history.back();
          }, 3000);
        }
      } catch (error) {
        console.error(error);
      }
    }
    submitCultPackage();
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p className="ml-3">{title}</p>
            </div>
          </div>
        </div>

        <div className="opd-reimbursement-main-wrapper-cover">
          <div className="book-now-form-main-cover">
            <div className="reimburse-header-title fitness-title p-3 pb-0">
              <h5>{description}</h5>
            </div>

            <div className="fitness-main-cover-wrapper p-3 pb-0">
              <div className="fitness-cards-cover-wrapper">
                {ispackageLoading ? <Loader /> : (
                  profitPackageData?.pro_fit_packages?.length > 0 &&
                    profitPackageData?.pro_fit_packages?.map((package_data) => {
                      return (
                        <div
                          className="fitness-card-box"
                          onClick={() => handleBuyCultPackage(package_data)}
                        >
                          <img src={package_data?.image}></img>
                        </div>
                      );
                    })
                )}
              </div>
            </div>
          </div>
        </div>

        {successmodal && (
          <div className="fitness-modal">
            <SuccessFull
              show={modalShow}
              successMessage={successMessage}
              animationtype={true}
            />
          </div>
        )}
        {
          isLoading && (
            <Loader/> 
          )
        }
      </div>
    </>
  );
};

export default FitnessPro;
