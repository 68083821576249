import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import { Toaster, toast } from "react-hot-toast";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../Animation/Loader";
import GotoCart from "./GotoCart";
import { PATIENTS_MEMBERS_API, PATIENTS_MEMBER_DETAILS_API } from "../../Constants";
import {
  ArrowBack,
  appCartIcon,
  patientsUser,
  patientDelete,
  patientEdit,
  appPackageIcon,
  appHeartIcon,
} from "../../images";

const Patients = () => {
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [mainCheckboxChecked, setMainCheckboxChecked] = useState(false);
  const [mainCheckBoxArray, setMainCheckboxArray] = useState([]);
  const [patientData, setpatientData] = useState([]);
  const [postType, setPostType] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [showModal, setShowModal] = useState(false);
  const [memberType, setMemberType] = useState(false);

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };
  const [isSavedMember, setisSavedMember] = useState(false);
  const [checkisDataDeleted, setDataDeleted] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [editDetails, setEditDetails] = useState({
    postType: "",
    patientId: "",
    patientName: "",
    patientAge: "",
    relationship: "",
  });

  const handleCheckboxChange = (
    e,
    testId,
    packId,
    patientId,
    patientName,
    patient_age,
    patient_gender,
    patient_relation,
    myTestData
  ) => {
    // Check if the patient already exists in selectedTests
    const existingPatientIndex = selectedTests.findIndex(
      (patient) => patient.patientId === patientId
    );

    if (existingPatientIndex !== -1) {
      // If the patient exists, update the testIds and packIds
      const updatedSelectedTests = [...selectedTests];
      const updatedPatient = {
        ...updatedSelectedTests[existingPatientIndex],
        testIds: e.target.checked
          ? [
            ...updatedSelectedTests[existingPatientIndex].testIds,
            myTestData?.test_id !== undefined && myTestData?.test_id === testId ? myTestData : testId,
          ]
          : updatedSelectedTests[existingPatientIndex].testIds.filter(
            (data) => data?.test_id !== myTestData?.test_id
          ),
        packIds: e.target.checked
          ? [...updatedSelectedTests[existingPatientIndex].packIds, myTestData?.pack_id !== undefined && myTestData?.pack_id === packId ? myTestData : packId]
          : updatedSelectedTests[existingPatientIndex].packIds.filter(
            (data) => data?.pack_id !== myTestData?.pack_id
          ),
      };

      updatedSelectedTests[existingPatientIndex] = updatedPatient;
      localStorage.setItem(
        "SELECTED_TEST",
        JSON.stringify(updatedSelectedTests)
      );
      setSelectedTests(updatedSelectedTests);
    } else {
      // If the patient doesn't exist, add a new entry
      const newPatient = {
        testIds: e.target.checked
          ? [myTestData?.test_id !== undefined && myTestData?.test_id === testId ? myTestData : testId]
          : [],
        packIds: e.target.checked ? [myTestData?.pack_id !== undefined && myTestData?.pack_id == packId ? myTestData : packId] : [],
        patientId,
        patientName,
        patient_gender,
        patient_age,
        patient_relation,
      };

      setSelectedTests([...selectedTests, newPatient]);
    }
  };

  const handleMainCheckboxChange = (e, test_id) => {
    setMainCheckboxChecked(e.target.checked);

    if (e.target.checked) {
      setMainCheckboxArray((prev_ids) => [...prev_ids, test_id]);
    } else {
      setMainCheckboxArray((prev_ids) =>
        prev_ids.filter((id) => id !== test_id)
      );
    }
  };
  useEffect(() => {
    // Log the updated state after the component has re-rendered
    localStorage.setItem("SELECTED_TEST", JSON.stringify(selectedTests));
  }, [selectedTests]);



  const FetchPatientList = async () => {
    setDataLoading(true);
    try {
      const response = await fetch(PATIENTS_MEMBERS_API, requestOptions);

      const res = await response.json();
      if (res.status) {
        setpatientData(res.data);
        setDataLoading(false);
      } else {
        // console.log(res);
      }
    } catch (error) {
      console.error(error);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    FetchPatientList();
  }, []);

  const openModal = (type) => {
    setPostType(type);
    setShowModal(true);
    setEditDetails(null);
    setMemberType(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleEditpatient = (
    postType,
    patientId,
    patient_name,
    age,
    relationship,
    gender
  ) => {
    setEditDetails({
      patientId: patientId,
      patientName: patient_name,
      patientAge: age,
      relationship: relationship,
      gender: gender,
    });
    setPostType(postType);
    setShowModal(true);
    setMemberType(false);
  };
  const handleDeletepatient = (
    postType,
    patient_name,
    age,
    relationship,
    patientId,
    gender
  ) => {
    const formData = new FormData();
    // console.log(selectedTeleConsultationDetails);
    formData.append("post_type", postType);
    formData.append("patient_type", "member");
    formData.append("member_id", patientId);
    formData.append("patient_name", patient_name);
    formData.append("relationship", relationship);
    formData.append("gender", gender);
    formData.append("age", age);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function submitPatientDetails() {
      try {
        const response = await fetch(PATIENTS_MEMBER_DETAILS_API, requestOptionsCopy);
        const res = await response.json();
        if (res.status === 200) {
          setDataDeleted(true);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error(error);
      }
    }

    submitPatientDetails();

    setTimeout(() => {
      FetchPatientList();
    }, 2000);
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="app-header-wraper">

          <div className="header-top-section">
            <div className="header-top-section-category-section">
              <h3>Select Patients</h3>
            </div>
            <div className="header-top-section-add-to-cart-section patient-cart-section">
              <button className="">
                <img src={appCartIcon} width="100%" alt="Cart-icon" />
                <span className="Header-count-number lab-count-no">{CartData.length}</span>
              </button>
            </div>
            <div className="header-top-section-add-to-cart-section-hert">
              <img src={appHeartIcon} width="100%" alt="heart-icon" />
            </div>
          </div>
        </div>
        <div className="patients-main-wrapper-cover">
          <>
            <div className="patient-main-box">
              <img src={patientsUser} width="100%" alt="review" />
              <p>You can add upto 6 patients details</p>
            </div>
            <>
              {Array.isArray(patientData) && patientData.length !== 6 && (
                <div class="patient-add-button d-flex justify-content-center">
                  <button
                    className=""
                    type="button"
                    onClick={() => openModal("add")}
                  >
                    <p>ADD MEMBER</p>
                  </button>
                </div>
              )}

              <PatientDetailsModal
                showModal={showModal}
                closeModal={closeModal}
                requestOptions={requestOptions}
                postType={postType}
                setShowModal={setShowModal}
                toast={toast}
                editDetails={editDetails}
                patientData={patientData}
                setisSavedMember={setisSavedMember}
                setEditDetails={setEditDetails}
                FetchPatientList={FetchPatientList}
                isSavedMember={isSavedMember}
                memberType={memberType}
                selectedTests={selectedTests}
              />
            </>
            {isDataLoading ? (
              <Loader />
            ) : (
              <div className="patient-detail-main-box-cover">
                {patientData.length > 0 && (
                  patientData.map((data, index) => {
                    return (
                      <div className="patient-detail-box">
                        <div className="patient-main-detail">
                          <div className="patient-detail-content">
                            <p>{data.patient_name}</p>
                            <p>
                              {data.gender}, {data.age}, {data.relationship}
                            </p>
                          </div>
                          <div className="patient-buttons-box">
                            <button
                              onClick={() =>
                                handleEditpatient(
                                  "update",
                                  data.id,
                                  data.patient_name,
                                  data.age,
                                  data.relationship,
                                  data.gender
                                )
                              }
                            >
                              <img
                                src={patientEdit}
                                width="100%"
                                alt="review"
                              />
                            </button>
                            <button>
                              <img
                                src={patientDelete}
                                width="100%"
                                alt="review"
                                onClick={() =>
                                  handleDeletepatient(
                                    "delete",
                                    data.patient_name,
                                    data.age,
                                    data.relationship,
                                    data.id,
                                    data.gender
                                  )
                                }
                              />
                            </button>
                            <input
                              type="checkbox"
                              class="main-checkbox form-check-input"
                              onChange={(e) =>
                                handleMainCheckboxChange(e, data.id)
                              }
                            />
                          </div>
                        </div>
                        <hr />
                        {CartData.length > 0 &&
                          CartData.map((Data, index) => {
                            return (
                              <div className="patient-member-detail-box d-flex align-items-center justify-content-between">
                                <div className="patient-member-image">
                                  <img
                                    src={appPackageIcon}
                                    width="100%"
                                    alt="review"
                                  />
                                  <p>
                                    {Data.test_heading || Data.package_name}
                                  </p>
                                </div>
                                <div className="patient-check">
                                  <input
                                    type="checkbox"
                                    disabled={
                                      !mainCheckBoxArray.includes(data.id)
                                    }
                                    className="form-check-input small-check"
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        e,
                                        Data.test_id,
                                        Data.pack_id,
                                        data.id,
                                        data.patient_name,
                                        data.age,
                                        data.gender,
                                        data.relationship,
                                        Data
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })
                )}
              </div>
            )}
          </>
        </div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 3000,
            style: {
              background: "#fff",
              color: "#363636",
            },
          }}
        />
        {mainCheckBoxArray.length > 0 && (
          <GotoCart
            CartData={CartData}
            selectedTests={selectedTests}
            patientData={patientData}
            toast={toast}
            mainCheckBoxArray={mainCheckBoxArray}
          />
        )}
      </div>

    </>
  );
};

const PatientDetailsModal = ({
  showModal,
  requestOptions,
  setShowModal,
  postType,
  toast,
  editDetails,
  setisSavedMember,
  FetchPatientList,
  memberType,
  selectedTests,
  patientData
}) => {
  const [patientName, setPatientName] = useState("");
  const [patientId, setPatientId] = useState("");
  const [age, setAge] = useState(null);
  const [gender, setGender] = useState("");
  const [relation, setRelation] = useState("");
  const [patientNameError, setPatientNameError] = useState("");
  const [ageError, setAgeError] = useState("");

  useEffect(() => {
    if (editDetails && !memberType) {
      setPatientName(editDetails.patientName || "");
      setAge(editDetails.patientAge || null);
      setGender(editDetails.gender || "");
      setRelation(editDetails.relationship || "");
      setPatientId(editDetails.patientId || "");
    } else if (memberType) {
      setPatientName("");
      setAge("");
      setGender("");
      setRelation("");
      setPatientId("");
    }
  }, [editDetails, memberType]);


  const handleSubmitPatientDetails = () => {

    if (!patientName || !patientName.trim()) {
      setPatientNameError("Patient Name is required");
      toast.error("Please Select All Fields");
      return;
    }

    const nameExists = patientData.some(
      (patient) => patient.patient_name && patient.patient_name.toLowerCase() === patientName.toLowerCase()
    );

    if (nameExists) {
      setPatientNameError("Patient name already exists.");
      return;
    }

    if (age === null || age === "") {
      setAgeError("Please Enter your Age");
      // toast.error("Please Select All Fields");
      return;
    }

    if (age < 18) {
      setAgeError("Age must be 18");
      return;
    }

    if (!gender) {
      toast.error("Please select gender");
      return;
    }

    if (!relation) {
      toast.error("Please select relation");
      return;
    }

    const formData = new FormData();
    formData.append("post_type", postType);
    formData.append("patient_type", "member");
    formData.append("patient_name", patientName);
    formData.append("relationship", relation);
    formData.append("gender", gender);
    formData.append("age", age);

    if (postType === "update") {
      formData.append("member_id", patientId);
    }

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    setPatientNameError("");
    setisSavedMember(true);

    async function submitPatientDetails() {
      try {
        const response = await fetch(PATIENTS_MEMBER_DETAILS_API, requestOptionsCopy);
        const res = await response.json();
        if (res.status === 200) {
          toast.success(res.message);
          FetchPatientList();
          setPatientName("");
          setAge("");
          setGender("");
          setRelation("");
          setPatientId("");
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    }

    submitPatientDetails();
    setShowModal(false);
  };



  useEffect(() => {
    if (patientName.length > 0) {
      setPatientNameError(null)
    } else if (age >= 101) {
      setAgeError("Age is invalid");
    } else if (age != "") {
      setAgeError(null)
    }
  }, [patientName, age])

  useEffect(() => {
    localStorage.setItem("SELECTED_TEST", JSON.stringify(selectedTests));
  }, [selectedTests]);

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Patient Details</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="patient-modal-form">
          <div className="patient-details-input-box">
            <label htmlFor="patientName">Patient Name</label>
            <input
              type="text"
              id="patientName"
              placeholder="Enter Patient Name"
              value={patientName}
              onChange={(e) => setPatientName(e.target.value)}
            />
            {patientNameError && (
              <span className="error text-danger">{patientNameError}</span>
            )}
          </div>

          <div className="patient-half-boxs">
            <div className="patient-details-input-box age-gender-box d-flex flex-column">
              <label htmlFor="age">Age test</label>
              <input
                type="text"
                id="age"
                className="age-input"
                value={age}
                onChange={(e) => setAge((e.target.value))}
                placeholder="Enter Age"
                minLength={0}
                maxLength={3}
                required={true}
              />
              {ageError && (
                <span className="error text-danger">{ageError}</span>
              )}
            </div>
            <div className="patient-details-input-box age-gender-box d-flex flex-column">
              <label htmlFor="gender">Gender</label>
              <select
                name="gender"
                id=""
                className="patient-gender-input gender-input"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                required={true}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>

          <div className="patient-details-input-box">
            <label htmlFor="relation">Relationship</label>
            <select
              name="relation"
              id="relation"
              className="relation-input"
              value={relation}
              onChange={(e) => setRelation(e.target.value)}
              required={true}
            >
              <option value="">Select Relationship</option>
              <option value="Self">Self</option>
              <option value="Spouse">Spouse</option>
              <option value="Child">Child</option>
              <option value="Mother">Mother</option>
              <option value="Father">Father</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmitPatientDetails}>
          Save Member
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Patients;
