import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import {
  ArrowBackButton,
  handleNavigateSearch,
  fetchApi,
} from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import GotoCart from "../Cart/GotoCart";
import NoDataFoundComp from "../../components/NoDataFound";
import { Toaster, toast } from "react-hot-toast";
import {
  REQUESTOPTIONS,
  CATEGORY_TEST_API,
  HEALTH_PACKAGE_API,
  DIAGNOSTIC_TEST_API,
  MY_ACCOUNT_API
} from "../../Constants";
import {
  ArrowBack,
  appCartIcon,
  appCommonlyBookTest,
  appHeartIcon,
  appHomeCollectionIcon,
  appIncludeTestIcon,
  appLabReportIcon,
  appLabUploadImg,
  appMachineTest,
  appPackageIcon,
  NoDataFound,
} from "../../images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const Labtest = (props) => {
  const [packageData, setPackageData] = useState([]);
  const [ispackageLoading, setPackageLoading] = useState(true);
  const [testData, setTestData] = useState([]);
  const [isAllpackageLoading, setAllPackageLoading] = useState(true);
  const [categoryListData, setCategoryListData] = useState([]);
  const [isCategoryLoading, setisCategoryLoading] = useState(true);
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [isErrorData, setErrorData] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const V2token = queryParams.get("token");
  const token = localStorage.getItem("ACCESS_TOKEN");

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (!token && V2token !== null) {
      localStorage.setItem("ACCESS_TOKEN", V2token);
    }
  }, [token]);

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };

  const handleNavigation = () => {
    props.token ? window.history.go(-2) : window.history.back();
  }

  useEffect(() => {
    if (token) {
      // Labtest Categories Api
      fetchApi(
        CATEGORY_TEST_API,
        requestOptions,
        setCategoryListData,
        setisCategoryLoading,
        setErrorData
      );

      //Common Test Api
      fetchApi(
        DIAGNOSTIC_TEST_API,
        requestOptions,
        setTestData,
        setAllPackageLoading,
        setErrorData
      );

      //packageApi
      fetchApi(
        HEALTH_PACKAGE_API,
        requestOptions,
        setPackageData,
        setPackageLoading,
        setErrorData
      );
    }
  }, []);

  const handleCart = (id) => {
    const isItemInCart = CartData.some((item) => item.test_id === id);

    if (!isItemInCart) {
      setCartData((prevData) => [
        ...prevData,
        ...testData.data.filter((data) => data.test_id === id),
      ]);
      toast.success("Item Added");
    } else {
      const updatedCart = CartData.filter((item) => item.test_id !== id);
      setCartData(updatedCart);
      toast.success("Item Removed Succesfully");
    }
  };


  const handlePakageCart = (id) => {
    const isItemInCart = CartData.some((item) => item.pack_id === id);

    if (!isItemInCart) {
      setCartData((prevData) => [
        ...prevData,
        ...packageData.data.filter((data) => data.pack_id === id),
      ]);
    } else {
      const updatedCart = CartData.filter((item) => item.pack_id !== id);
      setCartData(updatedCart);
    }
  };

  const handleNavigate = () => {
    navigate("/cart/diagnostictest");
  };

  useEffect(() => {
    localStorage.setItem("CartData", JSON.stringify(CartData));
  }, [CartData]);

  useEffect(() => {
    const userData = async () => {
      const UserRes = await fetch(`${MY_ACCOUNT_API}`, requestOptions)
      const userResData = await UserRes.json();
      localStorage.setItem("userinfo", JSON.stringify(userResData.data?.profile_details))
    }
    userData();
  }, [])
  const id = 1

  return (
    <>
      <div className="home-review-banner">
        <div className="app-header-wraper">

          <div className="header-top-section d-flex justify-content-between">
            <div className="header-top-section-category-section">
              <h3>Lab Test</h3>
            </div>
            <div className="header-top-section-add-to-cart-section lab-cart-section custom-cart">
              <button onClick={handleNavigate}>
                <img src={appCartIcon} width="100%" alt="Cart-icon" />
                {CartData.length > 0 && (
                  <span className="Header-count-number lab-count-no">
                    {CartData.length > 0 && CartData.length}
                  </span>
                )}
              </button>
            </div>
            <div className="header-top-section-add-to-cart-section-hert">
              <img src={appHeartIcon} width="100%" alt="heart-icon" />
            </div>
          </div>
        </div>

        <div className="app-searh-section lab-search-bar">
          <div
            className="inputWithIcon"
            onClick={() => handleNavigateSearch(navigate)}
          >
            <input type="text" placeholder="Search" />
            <button>
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="lab-upload">
          <div className="container upload-repo">
            <div className="lab-prescription-upload-box">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-7 lab-content-repo">
                  <div className="lab-prescription-content">
                    <h6>Upload Prescription</h6>
                    <p>Placed order with prescription</p>
                  </div>
                  <div className="lab-prescription-image">
                    <NavLink to={`/UploadPrescription?id=${id}`}>
                      <button>
                        <img
                          src={appLabUploadImg}
                          width="100%"
                          alt="lab-upload"
                        />
                        <p>Upload Prescription</p>
                      </button>
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-5 d-flex align-items-center justify-content-center">
                  <div className="test-image">
                    <img src={appMachineTest} width="100%" alt="test-machine" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lab-book-categories">
          <div className="book-test">
            <h6>Book Test by categories</h6>
            <NavLink to="/categories-test">
              <p>View All</p>
            </NavLink>
          </div>
          {isCategoryLoading ? (
            <Loader />
          ) : (
            <div className="lab-book-test-box">
              <Swiper
                spaceBetween={10}
                slidesPerView={3}
                loop="true"
                modules={[Autoplay]}
                pagination={{ clickable: true }}
              // autoplay={{ delay: 2500, disableoninteraction: false, }}
              >
                <div className="swiper-wrapper">
                  {categoryListData.data.length > 0 ? (
                    categoryListData.data.map((List) => {
                      return (
                        <SwiperSlide key={List?.cat_id}>
                          <NavLink
                            to={`/test-by-categories/${List.cat_id}/${List.cat_name}`}
                            className="categories-test-link"
                          >
                            <div className="lab-img-box">
                              <img
                                src={`${List.cart_icon}`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src =
                                    "https://medibhai.co.in/assets/uploads/cart_icon/lifestyle-disease-related-test.svg";
                                }}
                              />
                              <p>{List?.cat_name}</p>
                            </div>
                          </NavLink>
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <NoDataFoundComp />
                  )}
                </div>
              </Swiper>
            </div>
          )}
        </div>
        <div className="common-test-wrapper">
          <div className="book-test">
            <h6>Commonly Book Test</h6>
            <NavLink to="/common-book-test">
              <p>View All</p>
            </NavLink>
          </div>
          {isAllpackageLoading ? (
            <Loader />
          ) : (
            <div className="container">
              <Swiper
                spaceBetween={10}
                slidesPerView={1.7}
                loop="true"
                modules={[Autoplay]}
                pagination={{ clickable: true }}
              >
                <div className="swiper-wrapper">
                  {testData.data?.length > 0 ? (
                    testData.data?.map((elem, index) => (
                      <SwiperSlide key={index}>
                        <div className="col-lg-12 common-repo mb-4">
                          <div className="common-test-box mb-3">
                            <NavLink to={`/test-description/${elem.test_id}`}>
                              <div className="common-test-image">
                                <img
                                  src={appCommonlyBookTest}
                                  width="100%"
                                  alt="Book-test"
                                />
                              </div>
                              <div className="offer-common">
                                <p>{elem?.discount_percent} OFF</p>
                              </div>
                              <div className="common-test-heading">
                                <h6>{elem.test_heading}</h6>
                              </div>
                              <hr />
                              <div className="common-test-content text-center">
                                <p>
                                  Included Tests :{" "}
                                  {elem.test_parameter.length > 0 &&
                                    elem.test_parameter.length}
                                </p>
                                <h6>
                                  Medibhai Price : ₹ {elem?.test_medi_price}
                                </h6>
                                <span>MRP: ₹ {elem?.test_mrp}</span>
                              </div>
                            </NavLink>
                            {CartData.some(
                              (item) => item.test_id === elem.test_id
                            ) ? (
                              <button
                                className="remove-cart-btn"
                                onClick={() => handleCart(elem.test_id)}
                              >
                                Remove
                              </button>
                            ) : (
                              <div className="common-add-cart">
                                <button
                                  onClick={() => handleCart(elem.test_id)}
                                >
                                  <p>ADD TO CART</p>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <NoDataFoundComp />
                  )}
                </div>
              </Swiper>
            </div>
          )}
        </div>

        {packageData?.data?.length > 0 && (
          <div className="health-package-wrapper">
            <div className="book-test">
              <h6>Health Packages</h6>
              <NavLink to="/health-packages">
                <p>View All</p>
              </NavLink>
            </div>
            {ispackageLoading ? (
              <Loader />
            ) : (
              packageData.data.length > 0 && (
                <div className="health-package-box-cover">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={1.4}
                    loop="true"
                    modules={[Autoplay]}
                    pagination={{ clickable: true }}
                  // autoplay={{ delay: 2500, disableoninteraction: false, }}
                  >
                    <div className="swiper-wrapper">
                      {packageData.data?.length > 0 &&
                        packageData.data.map((elem) => (
                          <SwiperSlide key={elem.id}>
                            <div className="health-pack-main-box">
                              <NavLink
                                to={`/package-description/${elem.pack_id}`}
                              >
                                <div className="health-pack-image-box">
                                  <img
                                    src={appPackageIcon}
                                    width="100%"
                                    alt="package"
                                  />
                                  <p>{elem.package_name}</p>
                                </div>
                                <hr />
                                <div className="health-pack-price">
                                  <h6>MB Price ₹ {elem.pack_medi_price}</h6>
                                  <p>₹ {elem.pack_mrp}</p>
                                  <span>{elem.discount_percent} OFF</span>
                                </div>
                                <div className="health-pack-content-box">
                                  <img
                                    src={appIncludeTestIcon}
                                    width="100%"
                                    alt="include-test"
                                  />
                                  <p>{elem.parameter_count} Included Tests</p>
                                </div>
                                <div className="health-pack-content-box">
                                  <img
                                    src={appHomeCollectionIcon}
                                    width="100%"
                                    alt="home"
                                  />
                                  <p className="sample-repo">
                                    Home Sample Collection
                                  </p>
                                </div>
                              </NavLink>
                              {CartData.some(
                                (item) => item.pack_id === elem.pack_id
                              ) ? (
                                <button
                                  className="remove-cart-btn"
                                  onClick={() => handlePakageCart(elem.pack_id)}
                                >
                                  Remove Cart
                                </button>
                              ) : (
                                <div className="common-add-cart">
                                  <button
                                    onClick={() =>
                                      handlePakageCart(elem.pack_id)
                                    }
                                  >
                                    <p>ADD TO CART</p>
                                  </button>
                                </div>
                              )}
                            </div>
                          </SwiperSlide>
                        ))}
                    </div>
                  </Swiper>
                </div>
              )
            )}
          </div>
        )}

        <div className="health-work">
          <div className="book-test">
            <h6>How it works</h6>
          </div>
          <div className="health-how-it-works">
            <Swiper
              spaceBetween={10}
              slidesPerView={1.5}
              loop="true"
              modules={[Autoplay]}
              pagination={{ clickable: true }}
            // autoplay={{ delay: 2500, disableoninteraction: false, }}
            >
              <div className="swiper-wrapper">
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>01</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>Test Booking</h6>
                      <p>
                        Select your test as per your condition and requirement
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>02</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>Health Expert Deputed</h6>
                      <p>Highly skilled Technicians will collect your sample</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>03</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>Sample Collection</h6>
                      <p>
                        100% safe and Hygienic sample collection at your
                        convenient space
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>04</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>Test Investigation</h6>
                      <p>
                        NABL accreditation laboratories will investigate your
                        sample
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>05</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>E-Reports</h6>
                      <p>
                        You will receive your E-report on your register email
                        id.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
          <div className="lab-total-cover" id="hiddenDiv1">
            <div className="lab-total-item">
              <div className="lab-total-item-content">
                <p>
                  Total <span>₹ 91.00</span>
                </p>
                <p className="add-item">1 Item(s)</p>
              </div>
              <div className="lab-total-item-cart">
                <a href="#">
                  <button>
                    <p>GO TO CART</p>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 2000,
            style: {
              background: "#fff",
              color: "#363636",
            },
          }}
        />
        {CartData.length > 0 && (
          <GotoCart link="diagnostictest" CartData={CartData} />
        )}
      </div>
    </>
  );
};

export default Labtest;
