import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { ArrowBackButton, fetchApi } from "../../utils/AllFunction";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Autoplay } from "swiper/modules";
import Loader from "../../Animation/Loader";
import GotoCart from "../Cart/GotoCart";
import { REQUESTOPTIONS, DIAGNOSTIC_TEST_API, TEST_DESCRIPTION_API } from "../../Constants";
import {
  ArrowBack,
  appFastingTime,
  appRecommended,
  appAge,
  appFasting,
  appTestTube,
  appCartIcon,
  appCommonlyBookTest,
  appPackageIcon,
  appReports,
} from "../../images";
import NoDataFoundComp from "../../components/NoDataFound";

const Test_Description = () => {
  const [TestDetailsData, setTestDetailsData] = useState([]);
  const [isPackageDataLoading, setPackageLoading] = useState(true);
  const [testData, setTestData] = useState([]);
  const [testparameter, setTestparameter] = useState([]);
  const [isErrorData, setErrorData] = useState([]);
  const [isAllpackageLoading, setAllPackageLoading] = useState(true);
  const { test_id } = useParams();
  const navigate = useNavigate();



  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );

  const handleCart = (id) => {
    const isItemInCart = CartData.some((item) => item.test_id === id);

    if (!isItemInCart) {
      setCartData((prevData) => [
        ...prevData,
        ...testData.data.filter((data) => data.test_id === id),
      ]);
    } else {
      const updatedCart = CartData.filter((item) => item.test_id !== id);
      setCartData(updatedCart);
    }
  };

  const handleBuyCart = (id) => {
    const isItemInCart = CartData.some((item) => item.test_id === id);

    if (!isItemInCart) {
      setCartData((prevData) => [
        ...prevData,
        ...testData.data.filter((data) => data.test_id === id),
      ]);
      setTimeout(() => {
        navigate("/cart/diagnostictest")
      }, 100)
    } else {
      navigate("/cart/diagnostictest")
    }
  };

  useEffect(() => {
    fetchApi(DIAGNOSTIC_TEST_API, REQUESTOPTIONS, setTestData, setAllPackageLoading, setErrorData);
  }, []);

  useEffect(() => {
    localStorage.setItem("CartData", JSON.stringify(CartData));
  }, [CartData]);

  useEffect(() => {
    const GetPackageDescription = async () => {
      setPackageLoading(true);
      try {
        const apiUrl = `${TEST_DESCRIPTION_API}${test_id}`;
        const response = await fetch(apiUrl, REQUESTOPTIONS);
        const res = await response.json();
        if (res.status) {
          setTestDetailsData(res.data.test_data);
          setPackageLoading(false);
          setTestparameter(res.data.test_parameter);
        } else {
          console.log(res);
        }
      } catch (error) {
        console.error(error);
        setPackageLoading(false);
      }
    };

    GetPackageDescription();
  }, [test_id]);

  return (
    <>
      <div className="home-review-banner">
        <div className="cart-header-banner package-header">
          <div className="lab-test-box">
            <div className="cart-heading">
              <h5>Test Description</h5>
            </div>
            <div className="cart-icon lab-icon">
              <NavLink>
                <img src={appCartIcon} width="100%" alt="review" />
              </NavLink>
              <span id="hiddenDiv2">1</span>
            </div>
          </div>
        </div>
        {isPackageDataLoading ? (
          <Loader />
        ) : (
          <div className="package-details-main-cover-wrapper">
            <div className="pack-detail-cover-box">
              <div className="pack-deatil-img-box">
                <img src={appPackageIcon} width="100%" alt="review" />
                <h6>{TestDetailsData?.test_heading}</h6>
              </div>
              <hr />
              <div className="pack-detail-content-box">
                <h6>MB Price ₹ {TestDetailsData?.test_medi_price}</h6>
                <p>₹ {TestDetailsData?.test_mrp}</p>
                <span>{TestDetailsData?.discount_percent}OFF</span>
              </div>
            </div>
            <div className="description-tabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="">
                  <Col lg={12}>
                    <Nav
                      variant="pills"
                      className="d-flex justify-content-around"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first" className="description-tab">
                          Test Description
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second" className="description-tab">
                          Included Tests
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col lg={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <>
                          {TestDetailsData.test_description && (
                            <div className="test-description-content-box">
                              <p>{TestDetailsData.test_description}</p>
                            </div>
                          )}

                          <div className="test-desc-tabs">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-6 desc-detail-box-repo">
                                  <div className="desc-cover-box">
                                    <div className="desc-image-box">
                                      <img
                                        src={appReports}
                                        width="100%"
                                        alt="review"
                                      />
                                    </div>
                                    <div className="desc-detail-box">
                                      <p>E-Reports</p>
                                      <p>{TestDetailsData.test_report_time}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 desc-detail-box-repo">
                                  <div className="desc-cover-box">
                                    <div className="desc-image-box">
                                      <img
                                        src={appFastingTime}
                                        width="100%"
                                        alt="review"
                                      />
                                    </div>
                                    <div className="desc-detail-box">
                                      <p>Fasting Time</p>
                                      <p>
                                        {TestDetailsData.test_fasting_required}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 desc-detail-box-repo">
                                  <div className="desc-cover-box">
                                    <div className="desc-image-box">
                                      <img
                                        src={appRecommended}
                                        width="100%"
                                        alt="review"
                                      />
                                    </div>
                                    <div className="desc-detail-box">
                                      <p>Recommended</p>
                                      <p>for - {TestDetailsData.test_gender}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 desc-detail-box-repo">
                                  <div className="desc-cover-box">
                                    <div className="desc-image-box">
                                      <img
                                        src={appAge}
                                        width="100%"
                                        alt="review"
                                      />
                                    </div>
                                    <div className="desc-detail-box">
                                      <p>Age</p>
                                      <p>{TestDetailsData.test_age_type}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="test-accordion-box">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item>
                              <Accordion.Header>
                                <h6>{TestDetailsData?.test_heading}</h6>
                                <span className="test-count">
                                  {testparameter?.length}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="test-main-body">
                                  <ol>
                                    {testparameter?.length > 0 &&
                                      testparameter.map((data, ind) => (
                                        <li key={ind}>{data?.params_name}</li>
                                      ))}
                                  </ol>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>

              <div className="pack-detail-buttons">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 packe-det-repo">
                      <div className="pack-detail-add-to-cart">
                        {CartData.some(
                          (item) => item.test_id === TestDetailsData.test_id
                        ) ? (
                          <div className="pack-detail-buy-now">
                            <button
                              onClick={() =>
                                handleCart(TestDetailsData.test_id)
                              }
                            >
                              <p>Remove</p>
                            </button>
                          </div>
                        ) : (
                          <div className="common-add-cart">
                            <button
                              className="add-to-cart"
                              onClick={() =>
                                handleCart(TestDetailsData.test_id)
                              }
                            >
                              <p>ADD TO CART</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 packe-det-repo">
                      <div className="pack-detail-buy-now">
                        <button onClick={() => handleBuyCart(TestDetailsData.test_id)}>
                          <p>BUY NOW</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pack-why-book">
                <div className="pack-why-book-heading">
                  <hr />
                  <h6>Why book with us ?</h6>
                  <hr />
                </div>
                <div className="pack-why-book-cover">
                  <div className="pack-why-book-desc">
                    <div className="pack-why-book-image">
                      <img src={appFasting} width="100%" alt="review" />
                    </div>
                    <div className="pack-why-book-content">
                      <p>Safe sample collection from comfort of your home</p>
                    </div>
                  </div>
                  <div className="pack-why-book-desc">
                    <div className="pack-why-book-image">
                      <img src={appTestTube} width="100%" alt="review" />
                    </div>
                    <div className="pack-why-book-content">
                      <p>Wide range of tests available</p>
                    </div>
                  </div>
                  <div className="pack-why-book-desc">
                    <div className="pack-why-book-image">
                      <img src={appFastingTime} width="100%" alt="review" />
                    </div>
                    <div className="pack-why-book-content">
                      <p>Doctor advise with Health tests</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div className="pack-recommended-wrapper mb-5">
                <div className="pack-recommended-heading">
                  <hr />
                  <p>Recommended Packages</p>
                  <hr />
                </div>
                <div className="health-package-box-cover mb-4">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={1.9}
                    loop="true"
                    modules={[Autoplay]}
                    pagination={{ clickable: true }}
                  // autoplay={{ delay: 2500, disableoninteraction: false, }}
                  >
                    <div className="swiper-wrapper">
                      {testData.data?.length > 0 ? (
                        testData.data.slice(0, 6).map((elem) => (
                          <SwiperSlide key={elem.pack_id}>
                            <div className="col-12">
                              <div className="common-test-box mb-3">
                                <NavLink
                                  to={`/test-description/${elem.test_id}`}
                                >
                                  <div className="common-test-image">
                                    <img
                                      src={appCommonlyBookTest}
                                      width="100%"
                                      alt="Book-test"
                                    />
                                  </div>
                                  <div className="offer-common">
                                    <p>{elem?.discount_percent} OFF</p>
                                  </div>
                                  <div className="common-test-heading">
                                    <h6>{elem.test_heading}</h6>
                                  </div>
                                  <hr />
                                  <div className="common-test-content text-center">
                                    <p>
                                      Included Tests :{" "}
                                      {elem.test_parameter.length > 0 &&
                                        elem.test_parameter.length}
                                    </p>
                                    <h6>
                                      Medibhai Price : ₹ {elem?.test_medi_price}
                                    </h6>
                                    <span>MRP: ₹ {elem?.test_mrp}</span>
                                  </div>
                                </NavLink>
                                {CartData.some(
                                  (item) => item.test_id === elem.test_id
                                ) ? (
                                  <button
                                    className="remove-cart-btn"
                                    onClick={() => handleCart(elem.test_id)}
                                  >
                                    Remove
                                  </button>
                                ) : (
                                  <div className="common-add-cart">
                                    <button
                                      onClick={() => handleCart(elem.test_id)}
                                    >
                                      <p>ADD TO CART</p>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </SwiperSlide>
                        ))
                      ) : (
                        <NoDataFoundComp />
                      )}
                    </div>
                  </Swiper>
                </div>
              </div>
              <div className="lab-total-cover" id="hiddenDiv1">
                <div className="lab-total-item">
                  <div className="lab-total-item-content">
                    <p>
                      Total <span>₹ 91.00</span>
                    </p>
                    <p className="add-item">1 Item(s)</p>
                  </div>
                  <div className="lab-total-item-cart">
                    <a href="#">
                      <button>
                        <p>GO TO CART</p>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {CartData.length > 0 && <GotoCart link="labtest" CartData={CartData} />}
      </div>
    </>
  );
};

export default Test_Description;
