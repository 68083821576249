import React, { useState, useEffect } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import { IoInformationCircle } from "react-icons/io5";
import { useParams } from "react-router-dom";
import GotoCart from "../Cart/GotoCart";
import { Tab, Row, Col, Nav } from "react-bootstrap";

import { Tooltip } from 'react-tooltip'
import { Toaster, toast } from "react-hot-toast";
import {
  ArrowBack,
  appCartIcon,
  appEmptyCart,
  appHeartIcon,
  appTabletMed,
  appLabCart,
  appCommonlyBookTest,
  appDeleteIcon,
  appPXImg,
  DummyMedicine,
  LabTestIcon,
} from "../../images";
import Mediloader from "../../Animation/Mediloader";
import { MEDICINE_CART_MANAGER_LIST, MEDICINE_DELETE, MEDICINE_UPDATE, DIAGNOSTIC_TEST_API } from "../../Constants";
import Deleteloader from "../../Animation/DeleteLoader";

const MainCart = () => {
  const { activeTab } = useParams();
  const [modalShow, setModalShow] = React.useState(false);
  const [key, setKey] = useState(activeTab ? "second" : "first");
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );

  const [cartTotal, setcartTotal] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [testData, setTestData] = useState([]);
  const [isAllpackageLoading, setAllPackageLoading] = useState(true);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };

  const handleTabClick = (tab) => {
    setKey(tab);
    // Additional logic if needed
  };

  // Medicines State
  const [responseData, setResponseData] = useState([]);
  const [cartDataTotal, setCartDataTotal] = useState([]);
  const [successmodal, setSuccessModal] = useState(false);
  const [loadingHomeData, setLoadingHomeData] = useState(true);
  const [loadingData, setLoadingData] = useState({});
  const [pincodeData, setPincodeData] = useState(
    JSON.parse(localStorage.getItem("pincode")) || ""
  );

  useEffect(() => {
    setcartTotal(() => {
      return CartData.reduce(
        (total, item) => total + item.test_mrp || total + item.pack_mrp,
        0
      );
    });
    const DiscountPriceCount = () => {
      const totalDiscountPrice = CartData.reduce((total, item) => {
        // Assuming test_mrp, pack_mrp, test_medi_price, and pack_medi_price are strings representing numbers
        const testMrp = parseFloat(item.test_mrp) || 0;
        const packMrp = parseFloat(item.pack_mrp) || 0;
        const testMediPrice = parseFloat(item.test_medi_price) || 0;
        const packMediPrice = parseFloat(item.pack_medi_price) || 0;
        const totalMrp = testMrp + packMrp;
        const totalMediPrice = testMediPrice + packMediPrice;
        const discountPrice = totalMrp - totalMediPrice;
        return total + discountPrice;
      }, 0);

      return totalDiscountPrice;
    };
    setDiscountPrice(DiscountPriceCount);
    localStorage.setItem("CartData", JSON.stringify(CartData));
  }, [CartData]);

  useEffect(() => {
    if (key === "second") {
      const FetchCommonTest = async () => {
        setAllPackageLoading(true);
        try {
          const response = await fetch(DIAGNOSTIC_TEST_API, requestOptions);

          const res = await response.json();
          if (res.status) {
            setTestData(res.data);
            setAllPackageLoading(false);
          } else {
          }
        } catch (error) {
          console.error(error);
          setAllPackageLoading(false);
        }
      };

      FetchCommonTest();
    }
  }, [key]);

  useEffect(() => {
    MangerListDataRepo();
  }, [])

  // TO Compare If data in manager_list_api
  const MangerListDataRepo = async () => {
    setSuccessModal(true)
    setModalShow(true)
    try {
      const ManagerListRes = await fetch(`${MEDICINE_CART_MANAGER_LIST}${pincodeData}`, requestOptions);
      const ListDataRes = await ManagerListRes.json();
      // console.log(ListDataRes)

      setResponseData(ListDataRes?.skus_res || []);
      setCartDataTotal(ListDataRes?.vas_charges || []);

      setLoadingHomeData(false);
    } catch (error) {
      console.error(error);
      setLoadingHomeData(false);
    } finally {
      setSuccessModal(false)
      setModalShow(false)
    }
  }

  const handleCart = (id, pack_id) => {
    if (id) {
      const isItemInCart = CartData.some((item) => item.test_id === id);

      if (!isItemInCart) {
        setCartData((prevData) => [
          ...prevData,
          ...testData.filter((data) => data.test_id === id),
        ]);
      } else {
        const updatedCart = CartData.filter((item) => item.test_id !== id);
        setCartData(updatedCart);
        toast.success("Item Removed Succesfully")
      }
    } else {
      const isItemInCart = CartData.some((item) => item.pack_id === pack_id);
      if (!isItemInCart) {
        setCartData((prevData) => [
          ...prevData,
          ...testData.filter((data) => data.pack_id === pack_id),
        ]);
      } else {
        const updatedCart = CartData.filter((item) => item.pack_id !== pack_id);
        setCartData(updatedCart);
        toast.success("Item Removed Succesfully")
      }
    }
  };
  // To delete
  const handleDelete = async (id) => {
    setSuccessModal(true);
    setModalShow(true);
    try {

      const deleteRes = await fetch(`${MEDICINE_DELETE}${id}/${pincodeData}`, requestOptions)
      const res = await deleteRes.json();

      if (res.status === 200) {
        const manageDataAgain = await fetch(`${MEDICINE_CART_MANAGER_LIST}${pincodeData}`, requestOptions)

        const resAgain = await manageDataAgain.json();
        setResponseData(resAgain?.skus_res)
      }

    } catch (error) {
      console.error(error)
    }
    finally {
      setSuccessModal(false);
      setModalShow(false);
    }
  }

  // To Increment
  const handleIncrement = async (filteredItem) => {
    try {
      setLoadingData(prevStates => ({
        ...prevStates,
        [filteredItem.id]: true
      }));
      const updatedQuantity = Math.max(filteredItem.qty + 1);

      // Update the cart locally
      const updatedCart = responseData.map(item => {
        if (item.id === filteredItem.id) {
          return {
            ...item,
            qty: updatedQuantity
          };
        }
        return item;
      });

      // Update the state with the updated cart
      setResponseData(updatedCart);

      // Construct the request body data
      const requestBodyData = {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: filteredItem.id,
            quantity: updatedQuantity,
            min_quantity: filteredItem.min_quantity,
            max_quantity: filteredItem.max_quantity
          }
        ]
      };

      // Call cart_manager_update API to update the cart
      const updateData = await fetch(`${MEDICINE_UPDATE}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        },
        body: JSON.stringify(requestBodyData)
      });

      if (updateData.ok) {
        // If the update is successful, fetch the updated cart data
        const manageDataAgain = await fetch(`${MEDICINE_CART_MANAGER_LIST}${pincodeData}`, requestOptions);

        const resAgain = await manageDataAgain.json();
        setCartDataTotal(resAgain?.vas_charges || []);

        // Set loading to false after receiving response
        setLoadingData(prevStates => ({
          ...prevStates,
          [filteredItem.id]: false
        }));
      } else {
        console.error("Failed to fetch", updateData.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // To Decrement
  const handleDecrement = async (filteredItem) => {
    try {
      setLoadingData(prevStates => ({
        ...prevStates,
        [filteredItem.id]: true
      }));
      // Calculate updated quantity ensuring it respects minimum quantity
      let updatedQuantity = Math.max(filteredItem.qty - 1, 0);

      // If the updated quantity is less than the minimum quantity, set it to 0
      if (updatedQuantity < filteredItem.min_quantity) {
        updatedQuantity = 0;
      }

      // Update the cart locally
      const updatedCart = responseData.map(item => {
        if (item.id === filteredItem.id) {
          return {
            ...item,
            qty: updatedQuantity
          };
        }
        return item;
      });

      // Update the state with the updated cart
      setResponseData(updatedCart);

      // Construct the request body data
      const requestBodyData = {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: filteredItem.id,
            quantity: updatedQuantity,
            min_quantity: filteredItem.min_quantity,
            max_quantity: filteredItem.max_quantity
          }
        ]
      };
      // Call cart_manager_update API to update the cart
      const updateData = await fetch(`${MEDICINE_UPDATE}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        },
        body: JSON.stringify(requestBodyData)
      });

      if (updateData.ok) {
        const manageDataAgain = await fetch(`${MEDICINE_CART_MANAGER_LIST}${pincodeData}`, requestOptions);

        const resAgain = await manageDataAgain.json();
        setCartDataTotal(resAgain?.vas_charges || []);
        setResponseData(resAgain?.skus_res);

        setLoadingData(prevStates => ({
          ...prevStates,
          [filteredItem.id]: false
        }));
      } else {
        console.error("Failed to fetch", updateData.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="app-header-wraper">
          <div className="header-top-section">
            <div className="header-top-section-category-section">
              <h3>Cart</h3>
              {/* <LocationSection /> */}
            </div>
            <div className="header-top-section-add-to-cart-section custom-cart">
              <button className="">
                <img src={appCartIcon} width="100%" alt="Cart-icon" />
                {(responseData.length > 0 || CartData.length > 0) && (
                  <span className="Header-count-number cart-count-no">
                    {responseData.length + CartData.length}
                  </span>
                )}

              </button>
            </div>
            <div className="header-top-section-add-to-cart-section-hert">
              <img src={appHeartIcon} width="100%" alt="heart-icon" />
            </div>
          </div>
        </div>

        <div className="medicine-cover">
          <div className="container medi-cover-repo">
            <div className="tab-main-cover-wrapper">
              <Tab.Container id="left-tabs-example" defaultActiveKey={key}>
                <Row>
                  <Col lg={12} className=" tab-cover tab-cover2">
                    <div className="cart-header-tabs d-flex justify-content-center">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first"
                            onSelect={() => setKey("first")}
                            onClick={() => handleTabClick('first')}
                            className={key === "first" ? "active-link" : "not-active"}
                          >
                            <div className="medicine-box">
                              <div className="medi-image">
                                <img
                                  src={appTabletMed}
                                  width="100%"
                                  alt="Tablet-capsule"
                                />
                              </div>
                              <div className="box-heading">
                                <p>Medicines {responseData && responseData.length > 0 ? `(${responseData.length})` : ''}</p>

                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                            onSelect={() => setKey("second")}
                            onClick={() => handleTabClick('second')}
                            className={key === "second" ? "active-link" : "not-active"}
                          >
                            <div className="lab-box">
                              <div className="medi-image">
                                <img
                                  src={LabTestIcon}
                                  width="100%"
                                  alt="Lab-Test"
                                />
                              </div>
                              <div className="box-heading">
                                <p>Lab Test{CartData && CartData.length > 0 ? `(${CartData.length})` : ""}</p>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="tab-content-main-cover">
                      <Tab.Content>
                        <Tab.Pane eventKey="first">

                          <div className="medicines-tab-content-main-wrapper">
                            {successmodal && successmodal ? (
                              <Deleteloader show={modalShow} />
                            ) : responseData && responseData?.length > 0 ? (

                              //Medicine Cart Page
                              <div className="cart-app-cover">
                                <>
                                  <div className="product-box-repo">
                                    <div className="container">
                                      {responseData.map((storemedicine) => (
                                        <div className={`row cart-page-item ${loadingData[storemedicine.id] ? 'loading' : ''}`} key={storemedicine.id}>
                                          <div className="col-lg-4 col-md-4 col-4">
                                            <div
                                              className="product-image"
                                              style={{
                                                backgroundImage: `url(${storemedicine.image || DummyMedicine
                                                  })`,
                                              }}
                                            >
                                              {storemedicine.image !== undefined &&
                                                storemedicine.image !== "" ? (
                                                <></>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            <div className="medicine-rx-image">
                                              {storemedicine.rx_required === false ? (
                                                <></>
                                              ) : (
                                                <img src={appPXImg} alt={storemedicine.name} />
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-lg-8 col-md-8 col-8">
                                            <div className="product-detail">
                                              <div className="product-trash-data">
                                                <div className="product-heading">
                                                  <p
                                                    dangerouslySetInnerHTML={{
                                                      __html: storemedicine.name?.replace(
                                                        /(<? *script)/gi,
                                                        "illegalscript"
                                                      ),
                                                    }}
                                                  ></p>
                                                </div>
                                                <div className="trash-icon trash-cart-icon">
                                                  <button
                                                    onClick={() => {
                                                      handleDelete(storemedicine.id, storemedicine);
                                                    }}
                                                  >
                                                    <img src={appDeleteIcon} width="100%" alt="Delete" />
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="product-box">
                                                <div className="product-price">
                                                  <p>
                                                    ₹ {storemedicine.price}
                                                    <span>₹ {storemedicine.mrp}</span>
                                                    <p className="min-cart-qty">Min Qty:{storemedicine.min_quantity}</p>
                                                  </p>
                                                </div>

                                                <div className="tranding-medicine-container">
                                                  {
                                                    responseData
                                                      .filter(item => item.id === storemedicine.id)
                                                      .map(filteredItem => (
                                                        <div className={`tranding-medicine-add-cart-add trand-data-bar ${loadingData[filteredItem.id] ? 'loading' : ''}`} key={filteredItem.id}>
                                                          {loadingData[filteredItem.id] && <p><Mediloader /></p>}
                                                          {!loadingData[filteredItem.id] && filteredItem.qty > 0 && (
                                                            <>
                                                              <div className="image-add-icon" onClick={() => handleDecrement(filteredItem)}>
                                                                <p>-</p>
                                                              </div>
                                                              <span>{filteredItem.qty}</span>
                                                              <div className="image-add-icon" onClick={() => handleIncrement(filteredItem)}>
                                                                <p>+</p>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                      ))
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                      <hr />
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <div className="add-more-btn">
                                            <p>Add More Items</p>
                                            <NavLink to={"/buymedicines"}>
                                              <button>
                                                <span>+</span>
                                              </button>
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <>
                                    <div className="payment-summery-wrapper">
                                      {cartDataTotal.map((item, index) => (
                                        <div key={index} className="pay-box">
                                          <p>{item.display_text}</p>
                                          <p style={{ color: index === 1 || index === 2 ? '#000000' : '#0CB875' }}>{item.amount}</p>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="address-box">
                                      <div className="total-box">
                                        <p>
                                          Total <span>{cartDataTotal[cartDataTotal.length - 1]?.amount}</span>
                                        </p>
                                        <p className="item">{responseData && responseData.length} Item(s)</p>
                                      </div>
                                      <div className="address-box-repo">
                                        <NavLink to={`/addressdetail`}>
                                          <button>
                                            <p>SELECT ADDRESS</p>
                                          </button>
                                        </NavLink>
                                      </div>
                                    </div>
                                  </>
                                </>
                              </div>

                            ) : (
                              <>
                                <div className="medicines-empty-cart-cover">
                                  <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                                    <div className="empty-cart-image">
                                      <img
                                        src={appEmptyCart}
                                        width="100%"
                                        alt="EmptyCart"
                                      />
                                    </div>
                                    <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                                      <p>Your Medicine Cart is empty</p>
                                      <NavLink to="/buymedicines">
                                        <button>
                                          <p>ADD MEDICINES</p>
                                        </button>
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className="lab-test-tab-content-box">
                            {CartData.length > 0 ? (
                              <div className="lab-Cart-data-main-wrapper">
                                <div className="lab-cart-box-cover">
                                  <div className="added-test-cart-main-wrapper my-3">
                                    {CartData.length > 0 && (
                                      <div className="added-test-box">
                                        {CartData.map((labData) => {
                                          return (
                                            <div className="test-cart-box mb-3">
                                              <div className="cart-test-header d-flex ">
                                                <div className="common-test-image">
                                                  <img
                                                    src={appCommonlyBookTest}
                                                    width="100%"
                                                    alt="Book-test"
                                                  />
                                                </div>
                                                <div className="lab-cart-heading mx-2">
                                                  <h6>
                                                    {labData.test_heading ||
                                                      labData.package_name}
                                                  </h6>
                                                </div>
                                              </div>
                                              <div className="added-cart-details d-flex justify-content-between">
                                                <div className="health-pack-price mb-0">
                                                  <h6>
                                                    MB Price{" "}
                                                    {labData.test_medi_price ||
                                                      labData.pack_medi_price}
                                                  </h6>
                                                  <p>
                                                    ₹{" "}
                                                    {labData.test_mrp ||
                                                      labData.pack_mrp}
                                                  </p>
                                                  <span>
                                                    {labData.discount_percent}{" "}
                                                    OFF
                                                  </span>
                                                </div>
                                                <FaRegTrashCan
                                                  onClick={() =>
                                                    handleCart(
                                                      labData.test_id,
                                                      labData.pack_id
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                    <div className="add-more-items-box-cover">
                                      <NavLink to="/common-book-test">
                                        <h6>
                                          Add More Items <FiPlusCircle />
                                        </h6>
                                      </NavLink>
                                    </div>
                                  </div>
                                  <div className="medibhai-payment-summary mb-4">
                                    <div className="medibhai-payment-summary-container">
                                      <h4>Payment Summary</h4>
                                      <div className="payment-summary-list">
                                        <p>Cart Total</p>
                                        <p>₹ {cartTotal}</p>
                                      </div>
                                      <div className="payment-summary-list">
                                        <p>Discount Applied</p>
                                        <p>- ₹ {discountPrice}</p>
                                      </div>
                                      <div className="payment-summary-list">
                                        <p>Price After Discount</p>
                                        <p>₹ {cartTotal - discountPrice}</p>
                                      </div>
                                      <div className="payment-summary-list">
                                        <p>Collection Charges <span>
                                          <a
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="Additional 150 Home collection charges will be added with offer rate less than 300"
                                            data-tooltip-place="top"
                                          >
                                            <IoInformationCircle />
                                          </a>
                                          <Tooltip id="my-tooltip"
                                            style={{ width: "220px" }}
                                          />

                                        </span></p>
                                        {cartTotal - discountPrice <= 300 ? (
                                          <p>+ ₹ 150</p>
                                        ) : (
                                          <p>+ ₹ 0</p>
                                        )}

                                      </div>
                                      {/* {console.log((cartTotal + 10))} */}

                                      <hr className="payment-summary-list-dash" />
                                      <div className="payment-summary-list total-payable">
                                        <p>Total Payable</p>
                                        <p>
                                          ₹{" "}
                                          {cartTotal - discountPrice <= 300
                                            ? cartTotal - discountPrice + 150
                                            : cartTotal - discountPrice}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="payment-summary-list total-saving">
                                      <p>Total Savings</p>
                                      <p>₹ {discountPrice}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="medicines-empty-cart-cover">
                                <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                                  <div className="empty-cart-image">
                                    <img
                                      src={appLabCart}
                                      width="100%"
                                      alt="EmptyCart"
                                    />
                                  </div>
                                  <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                                    <p>Your Cart is empty</p>
                                    <NavLink to="/diagnostictest">
                                      <button>
                                        <p>ADD TESTS</p>
                                      </button>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 2500,
            style: {
              background: "#fff",
              color: "#363636",
            },
          }}
        />

        {successmodal && (
          <Deleteloader show={modalShow} />
        )}

        {
          CartData.length > 0 && key === 'second' && (
            <GotoCart
              total={
                cartTotal - discountPrice <= 300
                  ? cartTotal - discountPrice + 150
                  : cartTotal - discountPrice
              }
              CartData={CartData}
              link="diagnostictest"
            />
          )
        }
      </div>

    </>
  );
};

export default MainCart;
