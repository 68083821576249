import { DentalIcon, LabTestIcon, MedicalloanIcon, MedicinesIcon, OPDIcon, RadiologyIcon, TeleconsultationIcon, appDignosticsupport } from "./images";

export const WellnessSixModules = [
    {
        "app_index_id": 1,
        "show_hide": 1,
        "name": "Diagnostic\nTest",
        "image": LabTestIcon
    },
    {
        "app_index_id": 2,
        "show_hide": 1,
        "name": "Tele\nconsultation",
        "image": TeleconsultationIcon
    },
    {
        "app_index_id": 3,
        "show_hide": 1,
        "name": "Buy\nMedicines",
        "image": MedicinesIcon
    },
    {
        "app_index_id": 4,
        "show_hide": 1,
        "name": "In-Person\nConsultation",
        "image": OPDIcon
    },
    {
        "app_index_id": 6,
        "show_hide": 1,
        "name": "Medical\nLoan",
        "image": MedicalloanIcon
    },
    {
        "app_index_id": 7,
        "show_hide": 1,
        "name": "Dental\nConsultation",
        "image":
            DentalIcon
    },
    {
        "app_index_id": 8,
        "show_hide": 1,
        "name": "Radiology\nScreening",
        "image": RadiologyIcon
    },
];

export const WellnessCorner = [
    {
        "app_index_id": 1,
        "show_hide": 1,
        "name": "Fitness",
        "image": "https://medibhai.com/assets/images/medibhai_images/fitness.svg"
    },
    {
        "app_index_id": 2,
        "show_hide": 1,
        "name": "Yoga",
        "image": "https://medibhai.com/assets/images/medibhai_images/yoga.svg"
    },
    {
        "app_index_id": 3,
        "show_hide": 1,
        "name": "Weight Loss",
        "image":
            "https://medibhai.com/assets/images/medibhai_images/weight-loss.svg"
    },
    {
        "app_index_id": 4,
        "show_hide": 1,
        "name": "Nutrition",
        "image":
            "https://medibhai.com/assets/images/medibhai_images/nutrition.svg"
    },
    {
        "app_index_id": 5,
        "show_hide": 1,
        "name": "Life Style",
        "image":
            "https://medibhai.com/assets/images/medibhai_images/Life_Style.svg"
    }
];

export const genderList = [
    { "value": '1', "text": "Male" },
    { "value": '2', "text": "Female" },
    { "value": '3', "text": "Others" }
];

export const relationshipList = [
    { 'value': '1', 'text': 'Self' },
    { 'value': '2', 'text': 'Spouse' },
    { 'value': '3', 'text': 'Father' },
    { 'value': '4', 'text': 'Mother' },
    { 'value': '6', 'text': 'Brother' },
    { 'value': '7', 'text': 'Sister' },
    { 'value': '9', 'text': 'Son' },
    { 'value': '10', 'text': 'Daughter' },
];

export const WalletTransactionList = [
    {
        'id': 1,
        'value': 'All',
    },
    {
        'id': 2,
        'value': 'Medicine',
    },
    {
        'id': 3,
        'value': 'Diagnostics',
    },
    {
        'id': 4,
        'value': 'Teleconsultation',
    },
    {
        'id': 5,
        'value': 'Fitness',
    },
    {
        'id': 6,
        'value': 'OPD',
    },
    {
        'id': 7,
        'value': 'Dental',
    },
    {
        'id': 8,
        'value': 'Eye',
    }
];
