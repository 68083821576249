import createApiReducer from "../ApiReducer/ApiReducer";


// GET METHODS APIS
export const medicineReducer = createApiReducer('FETCH_MEDICINE');
export const medicineListReducer = createApiReducer('FETCH_MEDICINE_LIST');
export const medicineRecentListReducer = createApiReducer('FETCH_MEDICINE_RECENT_LIST');
export const prescriptionListReducer = createApiReducer('FETCH_PRESCRIPTION_LIST');
export const medicineCartDetailsReducer = createApiReducer('FETCH_MEDICINE_CART_LIST');
export const singleMedicineReducer = createApiReducer('FETCH_SINGLE_MEDICINE_LIST');



// POST METHODS APIS
export const uploadPrescriptionReducer = createApiReducer('UPLOAD_PRESCRIPTION');
export const deletePrescriptionReducer = createApiReducer('DELETE_PRESCRIPTION');
export const medicineCartData = createApiReducer('MEDICINE_CART_ADD');
export const medicineCartDelete = createApiReducer('MEDICINE_CART_DELETE');