import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import SuccessFull from "../Animation/SuccessFull";
import SuccessRadiology from "../Animation/SuccessRadiology";
import { STATE_CITY_LIST_API, REQUESTOPTIONS, PACKAGES_API } from "../Constants";

const TeleconsultantModal = (props) => {
    // New state for selected city
    const [successmodal, setSuccessModal] = useState(false);
    const navigate = useNavigate();
    const [modalShow, setModalShow] = React.useState(false);
    const [selectedPackage, setSelectedPackage] = useState('');
    const [rehit, setRehit] = useState(false)

    const { handleShow, show } = props

    useEffect(() => {
        if (props.packageList && props.packageList.length === 0) {
            setModalShow(false);
        }
    }, [props.packageList])

    const handleSelectChange = (event) => {
        const selectedPackage = JSON.parse(event.target.value);
        setSelectedPackage(selectedPackage);

    };

    const handleSubmit = () => {
        // Check if selectedPackage is defined and has the necessary properties
        if (selectedPackage && selectedPackage.id && selectedPackage.package_name) {
            const selectDataPackage = {
                id: selectedPackage.id,
                package_name: selectedPackage.package_name,
            };

            localStorage.setItem("PackageSelectData", JSON.stringify(selectDataPackage));
        } else {
            console.error("Selected package is not defined or missing required properties.");
        }

        // Update the state
        setRehit(true);
        props.onHide();
        setSuccessModal(true);
        setModalShow(true);
    };

    useEffect(() => {
        if (successmodal) {
            setTimeout(() => {
                setSuccessModal(false);
                setModalShow(false);
                // Navigate after setting rehit to true
                navigate("/teleconsultation", { state: { rehit } });
            }, 3000);
        }
    }, [successmodal, rehit, navigate]);

    const handleClose = () => {
        const pincodeData = localStorage.getItem("PackageSelectData");
        if (!pincodeData) {
            window.history.go(-2);
        }else{
           props.onHide()
        }
    };


    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="tele-modal-data"
            >
                <Modal.Header closeButton onClick={() => handleClose()}>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        className="modal-heading-text"
                    >
                        Select Package Name
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-box-content">
                        <Form.Select
                            aria-label="Default select example"
                            className="radio-form"
                            onChange={handleSelectChange}
                            value={selectedPackage ? JSON.stringify(selectedPackage) : ''}
                        >
                            <option value='' disabled>Select Package</option>
                            {props.packageList?.map((state) => (
                                <option key={state.id} value={JSON.stringify(state)}>
                                    {state?.package_name}
                                </option>
                            ))}
                        </Form.Select>
                        <br />
                        <button onClick={handleSubmit} disabled={selectedPackage === ''}>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>
            {successmodal && <SuccessRadiology show={modalShow} />}
        </>
    );
};

export default TeleconsultantModal;
