import { React, useState, useEffect } from "react";
import {
  ArrowBack,
  headerLogo,
  appDentalTick
} from "../../images";
import { ArrowBackButton , validateNumberInput } from "../../utils/AllFunction";
import { NavLink , useLocation} from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay} from "swiper/modules";
import { fetchApi } from "../../utils/AllFunction";
import PreviewBooking from "./PreviewBooking";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { FaLocationDot } from "react-icons/fa6";
import Loader from "../../Animation/Loader";
import NoDataFoundComp from "../../components/NoDataFound";
import { useForm } from "react-hook-form";
import PaymentModal from "../../Animation/PaymentModal";
import PaymentLoader from "../../Animation/PaymentLoader";
import { DENTAL_CONSULTATION_API , REQUESTOPTIONS , GET_AREA_API , MY_ACCOUNT_API,BOOK_DENTAL_CONSULTATION_API ,DENTAL_TIME_SLOT_API} from "../../Constants";

const DentalConsultation = (props) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const V2token = queryParams.get("token");
 
  // const [dentalConsultationData, setDentalConsultationData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [pageDescription, setPageDescription] = useState("");
  const [treatmentsList, setTreatmentsList] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [isErrorData, setisErrorData] = useState(false);
  const [selectedCity, setselectedCity] = useState("");
  const [show, setShow] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [optionsWithAttribute, setOptionsWithAttribute] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [response_insert_id , setResponse_insert_id] = useState(null);
  const [dentalBookingDate, sethandleDentalBookingDate] = useState(null);

  const [userInfo, setUserInfo] = useState([]);
  const [showPreviewBooking , setShowPreviewBooking] = useState(false)
  const [errorUserData , setErrorData]  =useState(null)
  
  const [isPaymentModalOpen, setPaymentModal] = useState(false);
  const [paymentMsg, setpaymentMsg] = useState("");
  const [paymentAnimation, setPaymentAnimation] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePreviewBooking = () => setShowPreviewBooking(false);

  const token = localStorage.getItem("ACCESS_TOKEN");

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };
  // console.log("requestOptions", requestOptions);
  useEffect(() => {
    setDataLoading(true);
    const FetchDentalList = async () => {
      try {
        const response = await fetch(
          `${DENTAL_CONSULTATION_API}`,requestOptions);
        const res = await response.json();
        if (res.status) {
          setBannerData(res?.banner);
          setPageDescription(res?.description);
          setTreatmentsList(res?.treatments_data?.treatments_list);
          setDataLoading(false);
          setStateOptions(
            res.nearest_city?.map((state) => ({
              value: state.trim(),
              label: state,
            }))
          );
        } else {
          setDataLoading(false);
        }
      } catch (error) {
        console.log(error);
        setisErrorData(true);
      }
    };

    FetchDentalList();
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("city_name", selectedCity);
    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function submitTeleConsultationBooking() {
      try {
        const response = await fetch(
          `${GET_AREA_API}`,
          requestOptionsCopy
        );
        const res = await response.json();
        if (res.status) {
          // setAreaList(res?.areas);

          setAreaOptions(
            res?.areas?.map((state) => ({
              value: state.area,
              label: state.area,
            }))
          );

          const mappedOptions =
            res.areas?.map((state) => ({
              value: state.area,
              label: state.area,
              customAttribute: state.address,
              dep_id: state.id,
              map_link: state.map_link, // Add your custom attribute here
            })) || [];
          // console.log(areaOptions)
          setOptionsWithAttribute(mappedOptions);
        } else {
          alert("UnsuccessFull");
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (selectedCity !== "") {
      submitTeleConsultationBooking();
    }
  }, [selectedCity]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: "rgb(99 99 99 / 5%) 0px 2px 8px 0px",
      borderRadius: "10px",
      border: "0.5px solid #70d3ff",
      padding: "2px 10px",
      fontSize: "14px",
      margin: "0px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#36a2eb" : "white",
      color: state.isSelected ? "white" : "black",
      borderRadius: "10px",
      zIndex: 999,
    }),
  };

  const handle_Book_Dental = () =>{
    if(selectedCity && selectedOption ){
      setShow(true)
    }else if(!selectedCity ){
      toast.error('Please select City')
    }else if(!selectedOption ){
      toast.error('Please select State')
    }else{
      toast.error("Please select city and state")
    }
  }

  
  const handleNavigation = () =>{
    V2token ?  window.history.go(-2) : window.history.back();
  }

  useEffect(() =>{
    fetchApi(MY_ACCOUNT_API, REQUESTOPTIONS, setUserInfo , setErrorData);
  },[])

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header d-flex justify-content-start align-items-center">
          
          <div className="header-logo mx-3">
            <img
              src={headerLogo}
              className="header-medibhai-logo"
              width="100%"
              alt="header-logo"
            />
          </div>
        </div>

        <div className="dental-consultation-main-wrapper-cover">
          {isDataLoading ? (
            <Loader />
          ) : (
            <>
              <div className="dental-main-banner">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  loop="true"
                  // autoplay={{ delay: 2500, disableoninteraction: false, }}
                  modules={[Autoplay]}
                >
                  <div className="swiper-wrapper">
                    {bannerData.length > 0 && (
                      bannerData.map((bannerslide, ind) => {
                        return (
                          <SwiperSlide className="applabtest-repo" key={ind}>
                            <div className="banner-wrapper-cover">
                              <img src={bannerslide} alt="banner-img"></img>
                            </div>
                          </SwiperSlide>
                        );
                      })
                    ) }
                  </div>
                </Swiper>
              </div>
              <div className="dental-description-box">
                <p>{pageDescription}</p>
              </div>
              <div className="dental-list-box-cover">
                {treatmentsList.length > 0 ? (
                  treatmentsList.map((listdata) => {
                    return (
                      <div className="dental-list-box">
                        <div className="dental-icon-box">
                          <img src={appDentalTick} alt="dental-tick" />
                        </div>
                        <h6>{listdata}</h6>
                      </div>
                    );
                  })
                ) : (
                    <NoDataFoundComp />
                )}
              </div>
              <div className="book-now-form-main-cover">
                <h5>Nearest Clove Dental Clinic</h5>
                <div className="book-now-form">
                  <Select
                    className="mb-3"
                    options={stateOptions}
                    isSearchable={true}
                    styles={customStyles}
                    placeholder="Select City"
                    required
                    value={
                      selectedCity
                        ? {
                            value: selectedCity,
                            label: selectedCity,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setselectedCity(selectedOption.value);
                      } else {
                        setselectedCity("");
                      }
                    }}
                  />
                  <Select
                    className="mb-3"
                    options={optionsWithAttribute}
                    isSearchable={true}
                    placeholder="Select Area"
                    styles={customStyles}
                    value={selectedOption}
                    required
                    onChange={(selectedOption) =>
                      setSelectedOption(selectedOption)
                    }
                    // other props...
                  />
                  <div className="book-form-area-cover">
                    <div className="address-form">
                      <h6 className="Address">Address : </h6>
                      {selectedOption && (
                        <div>
                          
                          <p className="address-text">
                            {selectedOption.customAttribute}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="direction-box">
                    <span>
                      <NavLink target="_blank" to={selectedOption?.map_link}>
                        <FaLocationDot /> <h5>Direction</h5>
                      </NavLink>
                    </span>
                  </div>
                </div>
              </div>
              <div className="book-teleconsultation-button d-flex justify-content-center">
                <button className="mb-5" onClick={() =>handle_Book_Dental()}>
                  Book DentalConsultation
                </button>
              </div>
            </>
          )}
          {paymentAnimation && (
            <PaymentModal
              show={isPaymentModalOpen}
              successMessage={paymentMsg}
              paymentAnimation={paymentAnimation}
            />
          )}

          {isPaymentLoading && (
            <div className="payment-loading-page">
              <PaymentLoader />
            </div>
          )}

          <div className="book-now-modal-main-cover-wrapper">
            <BookNowModal
              show={show}
              handleClose={handleClose}
              setShow={setShow}
              stateOptions={stateOptions}
              requestOptions={requestOptions}
              userInfo={userInfo}
              selectedOption={selectedOption}
              BOOK_DENTAL_CONSULTATION_API={BOOK_DENTAL_CONSULTATION_API}
              REQUESTOPTIONS={REQUESTOPTIONS}
              setShowPreviewBooking={setShowPreviewBooking}
              setResponse_insert_id={setResponse_insert_id}
              setSelectedTimeSlot={setSelectedTimeSlot}
              selectedTimeSlot={selectedTimeSlot}
              sethandleDentalBookingDate={sethandleDentalBookingDate}
              dentalBookingDate={dentalBookingDate}
              DENTAL_TIME_SLOT_API={DENTAL_TIME_SLOT_API}

            />
          </div>
          <PreviewBooking showPreviewBooking={showPreviewBooking} 
            handlePreviewBooking={handlePreviewBooking}
            response_insert_id={response_insert_id}
            requestOptions={requestOptions}
            userInfo={userInfo}
            selectedTimeSlot={selectedTimeSlot}
            dentalBookingDate={dentalBookingDate}
            token={props.token}
            setPaymentAnimation={setPaymentAnimation}
            setpaymentMsg={setpaymentMsg}
            setPaymentModal={setPaymentModal}
            setPaymentLoading={setPaymentLoading} />

        </div>  
        
      </div>
    </>
  );
};

export default DentalConsultation;

function BookNowModal({
  show,
  setShow,
  requestOptions,
  selectedOption,
  setShowPreviewBooking,
  setResponse_insert_id,
  userInfo,
  selectedTimeSlot , 
  setSelectedTimeSlot,
  dentalBookingDate , sethandleDentalBookingDate ,
  BOOK_DENTAL_CONSULTATION_API , DENTAL_TIME_SLOT_API,
  REQUESTOPTIONS
}) {
  const handleClose = () => setShow(false);
  const [BookingDate, setBookingDate] = useState("");
  const [selectedGender, setSelectedGender] = useState();
  const [patientAge, setPatientAge] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientMobile, setPatientMobile] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [timeSlotData, setTimeSlotData] = useState([]);
  
  
  const [timeSlotOption, setTimeSlotOptions] = useState([]);
    
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      patientName:patientName,
      patientMobile: patientMobile,
      gender: selectedGender,
      patientEmail: patientEmail,
      // Add default values for other fields as needed
    },
  });

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleTimeSlotChange = (event) => {
    setSelectedTimeSlot(event.target.value);
  };

  useEffect(() => {
    const TimeSlotDetails = async () => {
      try {
        const response = await fetch(DENTAL_TIME_SLOT_API, requestOptions);

        const res = await response.json();
        if (res.status) {
          setTimeSlotData(res.data);
          // console.log(res.data);
          setTimeSlotOptions(
            res?.data?.tomorrow_time_slot?.length > 0
              ? res?.data?.tomorrow_time_slot
              : []
          );
        } else {
          // console.log(res);
        }
      } catch (error) {
        console.error(error);
      }
    };

    TimeSlotDetails();
  }, []);



  useEffect(() =>{
    setValue("patientName" ,userInfo?.data?.profile_details?.fullname)
    setPatientName(userInfo?.data?.profile_details?.fullname)
    setPatientMobile(userInfo?.data?.profile_details?.mobile)
    setValue("patientEmail" ,userInfo?.data?.profile_details?.email)
    setPatientEmail(userInfo?.data?.profile_details?.email)
  },[userInfo])


  const onSubmit = () => {
    const formData = new FormData();

    formData.append("patient_name", patientName);
    formData.append("mobile_number", patientMobile);
    formData.append("email_id", patientEmail);
    formData.append("patient_age", patientAge);
    formData.append("appointment_date", BookingDate);
    formData.append("gender", selectedGender);
    formData.append("dept_id", selectedOption?.dep_id);
    formData.append("visit_date", dentalBookingDate);
    formData.append("visit_time", selectedTimeSlot);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;
    // console.log(formData)

    // setPaymentLoading(true);

    async function submitTeleConsultationBooking() {
      try {
        const response = await fetch(
          BOOK_DENTAL_CONSULTATION_API,
          requestOptionsCopy
        );
        const res = await response.json();
        setShow(false)
        if (res.status === 200) { 
          setResponse_insert_id(res.insert_id);
          setTimeout(() => {
            setShowPreviewBooking(true);
          }, 1000);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    submitTeleConsultationBooking();
  };

  const handleDentalBookingDate = (e) => {
    sethandleDentalBookingDate(e.target.value);
  };


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="book-now-modal-main-wrapper"
      >
        <Modal.Header closeButton className="book-now-header">
          <Modal.Title>
            <h6>Enter Person Details</h6>
            <p className="book-now-title-description">
              Dentalconsultation Will be Conduct for this person
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="book-now-modal-body">
          <div className="book-now-form-main-cover">
            <div className="book-now-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 book-now-input-box">
                  <input
                    type="text"
                    placeholder="Patient Name"
                    {...register("patientName", {
                      required: "Please enter your Name",
                    })}
                    onChange={(e) => {
                      setPatientName(e.target.value);
                    }}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientName && errors.patientName.message}
                  </span>
                </div>
                <div className="mb-3 book-now-input-box">
                  <input
                    type="text"
                    placeholder="Age"
                    {...register("patientAge", {
                      required: "Please enter your Age",
                      pattern: {
                        value: /^\d+$/,
                        message: "Please enter a valid age",
                      },
                    })}
                    maxLength={2}
                    onInput={(e) => validateNumberInput(e)}
                    defaultValue={patientAge}
                    onChange={(e) => {
                      setPatientAge(e.target.value);
                    }}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientAge && errors.patientAge.message}
                  </span>
                </div>
                <div className="gender-input-radio-box mb-3">
                  <Form.Group>
                    <Form.Label className="radio-title-label">
                      Select Gender
                    </Form.Label>
                    <InputGroup>
                      <div className="radio-input-box d-flex align-items-center">
                        <InputGroup.Radio
                          name="gender"
                          value="male"
                          checked={selectedGender === "male"}
                          onChange={handleGenderChange}
                          class="gender-input-radio p-0"
                          required
                        />
                        <Form.Label className="mr-2">Male</Form.Label>
                      </div>

                      <div className="radio-input-box d-flex align-items-center">
                        <InputGroup.Radio
                          name="gender"
                          value="female"
                          checked={selectedGender === "female"}
                          onChange={handleGenderChange}
                          class="gender-input-radio"
                        />
                        <Form.Label>Female</Form.Label>
                      </div>

                      <div className="radio-input-box d-flex align-items-center">
                        <InputGroup.Radio
                          name="gender"
                          value="other"
                          checked={selectedGender === "other"}
                          onChange={handleGenderChange}
                          class="gender-input-radio"
                        />
                        <Form.Label>Other</Form.Label>
                      </div>
                    </InputGroup>
                  </Form.Group>
                </div>
                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder="Mobile Number"
                    value={patientMobile}
                    onChange={(e) => setPatientMobile(e.target.value)}
                    maxLength={10}
                    onInput={(e) => validateNumberInput(e)}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientMobile && errors.patientMobile.message}
                  </span>
                </Form.Group>
                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    {...register("patientEmail", {
                      required: "Please enter your Email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please enter a valid email address",
                      },
                    })}
                    value={patientEmail}
                    onChange={(e) => {
                      setPatientEmail(e.target.value);
                    }}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientEmail && errors.patientEmail.message}
                  </span>
                </Form.Group>
                <div className="patient-input-form">
                  <select
                    name="time"
                    id="time"
                    className="relation"
                    onChange={handleDentalBookingDate}
                  >
                    <option value="">Select Date</option>
                    {timeSlotData?.date_range?.length > 0 &&
                      timeSlotData?.date_range?.map((timeSlot, index) => (
                        <option key={index} value={timeSlot}>
                          {timeSlot}
                        </option>
                      ))}
                  </select>
                </div>
                {dentalBookingDate === null ? (
                  <h6 className="no_slot_msg">No Slot Available for Selected Date</h6>
                ) : (
                  <div className="patient-input-form">
                    <select
                      name="time"
                      id="time"
                      className="relation"
                      onChange={handleTimeSlotChange}
                    >
                      <option value="">Select Time</option>
                      {timeSlotOption.length > 0 &&
                        timeSlotOption.map((timeSlot, index) => (
                          <option key={index} value={timeSlot}>
                            {timeSlot}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
                <p className="wallet_summary-point"></p>
                <div className="submit-btn-box d-flex justify-content-center">
                  <button type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
