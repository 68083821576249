import { createApiAction } from "./apiActions";

export const uploadPrescription = (prescriptionData) => createApiAction('UPLOAD_PRESCRIPTION', (options) =>
    fetch('https://wellness.medibhai.co.in/api/medicine/save-selected-prescription', {
        method: 'POST',
        body: prescriptionData,
        ...options
    })
);
export const deletePrescription = (prescriptionData) => createApiAction('DELETE_PRESCRIPTION', (options) =>
    fetch('https://wellness.medibhai.co.in/api/medicine/delete-uploaded-prescription', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(prescriptionData),
        ...options
    })
);

export const medicineCartAdd = (cartData) => createApiAction('MEDICINE_CART_ADD', (options) =>
    fetch('https://wellness.medibhai.co.in/api/medicine/medicine-cart-store', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: cartData,
        ...options
    })
);
export const medicineCartDelete = (cartData) => createApiAction('MEDICINE_CART_DELETE', (options) =>
    fetch('https://wellness.medibhai.co.in/api/medicine/remove-cart-item', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(cartData),
        ...options
    })
);
