import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { medicineCartAdd, medicineCartDelete } from '../../../Redux/actions/PostApiCall';
import { fetchMedicineCartList, fetchMedicineRecent } from '../../../Redux/actions/GetApiCall';

const useCartFunction = () => {
    const [cart, setCart] = useState([]);
    const pincode = localStorage.getItem("pincode");
    const [loadingItem, setLoadingItem] = useState(null);
    const dispatch = useDispatch();
    const [deletedItems, setDeletedItems] = useState(new Set());
    const { data: mediData } = useSelector(state => state.medicine_recent_list);
    const cartStatus = useSelector(state => state.medicine_cart_add.status);
    const DeleteStatus = useSelector(state => state.medicine_cart_delete.status); // Added this assuming it's needed
    const CartData = useSelector(state => state.medicine_cart_add.data);// Assuming this is where CartData comes from
    const [availability, setAvailability] = useState({});
    const { data: MedicineData } = useSelector(state => state.medicine_cart_list);

    useEffect(() => {
        if (cartStatus === 'success' && CartData) {
            const updatedCart = CartData.data.reduce((acc, cartItem) => {
                const itemAvailability = cartItem.is_available;
                setAvailability(prev => ({ ...prev, [cartItem.sku_id]: itemAvailability }));

                if (itemAvailability) {
                    const itemIndex = acc.findIndex(item => item.sku_id === cartItem.sku_id);
                    if (itemIndex !== -1) {
                        acc[itemIndex].quantity = cartItem.quantity || acc[itemIndex].quantity;
                    } else {
                        acc.push({ ...cartItem, quantity: cartItem.min_quantity });
                    }
                } else {
                    acc = acc.filter(item => item.sku_id !== cartItem.sku_id);
                }

                return acc;
            }, [...cart]);

            setCart(updatedCart);
            if (cartStatus !== 'loading') {
                setLoadingItem(null);
            }
        }
    }, [cartStatus, CartData]);

    if (window.location.href.includes("medicine-cart")) {
        console.log("hi")
    }



    const updateCartInServer = async (updatedCart) => {
        const cartAddData = JSON.stringify({
            pincode: pincode,
            cart: updatedCart,
        });
        await dispatch(medicineCartAdd(cartAddData));
    };

    const MedicineCartList = MedicineData?.available_stock;
    console.log(MedicineCartList)
    const MedicineDatas = mediData?.data;

    useEffect(() => {
        const newCartItems = [];

        // Determine which array to iterate over
        const itemsToProcess = MedicineDatas || MedicineCartList || [];

        itemsToProcess.forEach(item => {
            if (item?.is_available === 1 && !deletedItems.has(item.sku_id)) {
                const updateCart = {
                    sku_id: item.sku_id,
                    quantity: item.quantity,
                    min_quantity: item.min_quantity,
                    max_quantity: item.max_quantity,
                };

                const isItemInCart = cart.some(cartItem => cartItem.sku_id === item.sku_id);
                if (!isItemInCart) {
                    newCartItems.push(updateCart);
                }
            }
        });

        if (newCartItems.length > 0) {
            setCart(prevCart => [...prevCart, ...newCartItems]);
        }
    }, [MedicineDatas, cart, deletedItems, MedicineCartList]);


    useEffect(() => {
        if (DeleteStatus === "success") {
            setCart(prevCart => prevCart.filter(item => item.sku_id !== loadingItem));
            setDeletedItems(prev => new Set(prev).add(loadingItem));
            if (window.location.href.includes("medicine-cart")) {
                dispatch(fetchMedicineCartList())
            } else {
                dispatch(fetchMedicineRecent());
            }
        }
    }, [DeleteStatus]);

    const handleCartAdd = async (item) => {
        setLoadingItem(item.sku_id);

        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === item.sku_id
                ? {
                    ...cartItem,
                    quantity: Math.min(cartItem.quantity + item.min_quantity, item.max_quantity),
                }
                : cartItem
        );

        if (!updatedCart.some(cartItem => cartItem.sku_id === item.sku_id)) {
            updatedCart.push({
                sku_id: item.sku_id,
                quantity: item.min_quantity,
                min_quantity: item.min_quantity,
                max_quantity: item.max_quantity,
            });
        }

        await updateCartInServer(updatedCart);
    };

    const handleIncrement = async (sku_id) => {
        setLoadingItem(sku_id);
        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === sku_id && cartItem.quantity < cartItem.max_quantity
                ? { ...cartItem, quantity: cartItem.quantity + 1 }
                : cartItem
        );

        await updateCartInServer(updatedCart);
    };

    const handleDecrement = async (sku_id) => {
        setLoadingItem(sku_id);

        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === sku_id && cartItem.quantity > cartItem.min_quantity
                ? { ...cartItem, quantity: cartItem.quantity - 1 }
                : cartItem
        );

        await updateCartInServer(updatedCart);
    };

    const handleDelete = async (id) => {
        setLoadingItem(id);
        const CartId = { sku_id: id };
        await dispatch(medicineCartDelete(CartId));
        setDeletedItems(prev => new Set(prev).add(id));
    };

    return {
        cart,
        loadingItem,
        handleCartAdd,
        handleIncrement,
        handleDecrement,
        handleDelete,
        cartStatus,
        DeleteStatus,
        availability,
        CartData
    };
};

export default useCartFunction;
