import { React, useEffect, useState, useContext, useParams } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  appCartIcon,
  headerLogo,
  appTestTube,
  Nutition_banner,
  ArrowBack,
} from "../../../images";
import {
  handleNavigate,
  validateNumberInput,
  fetchApi,
  ArrowBackButton,
} from "../../../utils/AllFunction";
import { Toaster, toast } from "react-hot-toast";
import { validateTextInput } from "../../../utils/AllFunction";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useRazorpay from "react-razorpay";
import { useForm, Controller } from "react-hook-form";
import { InputGroup } from "react-bootstrap";
import PaymentModal from "../../../Animation/PaymentModal";
import PaymentLoader from "../../../Animation/PaymentLoader";
import SuccessFull from "../../../Animation/SuccessFull";
import { parseISO, format } from 'date-fns';



import {
  BOOK_TELECONSULTATION_API,
  WALLET_API,
  IMG_PATH,
  TELECONSULTATION_LIST_API, GET_TIME_SLOT_API,
  MY_ACCOUNT_API,
} from "../../../Constants";

const BookTeleConsultation = (props) => {
  const navigate = useNavigate();

  // const Userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [BookingDate, setBookingDate] = useState(new Date());
  const [selectedGender, setSelectedGender] = useState("");
  const [patientAge, setPatientAge] = useState(null);
  const [patientName, setPatientName] = useState([]);
  const [patientMobile, setPatientMobile] = useState([]);
  const [patientEmail, setPatientEmail] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [isErrorData, setErrorData] = useState([]);
  const [isDataLoading, setDataLoading] = useState();
  const [BookedTeleConsultData, setBookedTeleconsult] = useState(
    JSON.parse(localStorage.getItem("BOOKED_TELECONSULTATION_DATA")) || []
  );
  const [mbConsulttype, setMbconsultType] = useState(
    (localStorage.getItem("mbConsultType")) || ""
  );
  const [bookingStatus, setBookingStatus] = useState(false);
  const [bookingMsg, setBookingMsg] = useState("");
  const [isPaymentModalOpen, setPaymentModal] = useState(false);
  const [paymentMsg, setpaymentMsg] = useState("");
  const [paymentAnimation, setPaymentAnimation] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [timeSlotOptions, setTimeSlotoptions] = useState(null);
  const [submitLoader, setSubmitLoader] = useState(false)
  const [bookingData, setBookingData] = useState(null);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const V2HREF = localStorage.getItem("V2HREF");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const show_slot = queryParams.get('type');
  const dept_id = queryParams.get("dept_id");


  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      patientName: patientName || "",
      patientEmail: patientEmail || "",
      patientMobile: patientMobile || "",
      // Add default values for other fields as needed
    },
  });

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };

  const isV2token = queryParams.get("v2Token");

  if (isV2token) {
    window.history.pushState({}, '', window.location.pathname);
  }

  useEffect(() => {
    const userData = async () => {
      const UserRes = await fetch(`${MY_ACCOUNT_API}`, requestOptions);
      const userResData = await UserRes.json();
      setPatientName(userResData.data?.profile_details?.fullname);
      setPatientMobile(userResData.data?.profile_details?.mobile);
      setPatientEmail(userResData.data?.profile_details?.email);
    };
    const data = {
      dept_id: 10,
      department_name: "Dietitian",
      department_image: "nutrition-and-dietetics_2024-01-30-16-51-26.png",
      department_descriptoon:
        "Diet and nutrition counseling and coaching for healthy eating, weight management, and chronic illness dietary management.",
      doctor_id: 13,
      doctor_name: "Dr Reshma",
      doctor_mobile: 9819412221,
      doctor_email: "info@medibhai.com",
      cosult_charge: 249, // Corrected property name
      created_on: "2024-01-30 16:51:26",
      is_free: 1,
      min_date_time: "2024-03-24",
      max_date_time: "2024-03-30",
    };
    localStorage.setItem("BOOKED_TELECONSULTATION_DATA", JSON.stringify(data));
    userData();
  }, []);

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const [Razorpay] = useRazorpay();

  useEffect(() => {
    const formData = new FormData();
    formData.append("module_id", 3);
    formData.append("price", BookedTeleConsultData.cosult_charge);
    formData.append("mb_consult_type", mbConsulttype);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    fetchApi(
      WALLET_API,
      requestOptionsCopy,
      setWalletData,
      setDataLoading,
      setErrorData
    );
  }, []);

  const onSubmit = () => {
    setSubmitLoader(true)
    const formData = new FormData();
    // console.log(selectedTeleConsultationDetails);
    formData.append("mb_consult_type", "speciality");
    formData.append("otp_verify", 1);
    formData.append("mb_price", show_slot == "1" ? 0 : BookedTeleConsultData.cosult_charge);
    formData.append("user_id", walletData.data.user_id);
    formData.append("patient_name", patientName);
    formData.append("mobile_number", patientMobile);
    formData.append("email_id", patientEmail);
    formData.append("patient_age", patientAge);
    formData.append("appointment_date", bookingData);
    formData.append("gender", selectedGender);
    formData.append("bookid", BookedTeleConsultData.doctor_id);
    formData.append("dept_id", BookedTeleConsultData.dept_id);

    // if (show_slot == "1") {
    formData.append("appointment_time", selectedTimeSlot);
    // }

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function submitTeleConsultationBooking() {
      try {
        const response = await fetch(
          BOOK_TELECONSULTATION_API,
          requestOptionsCopy
        );
        const res = await response.json();
        if (res.status === 200) {
          setBookingStatus(true);
          setBookingMsg(res.message);
          if ((walletData.data.show_button = "online")) {
            if (typeof res?.razorpay_details.amount != "undefined") {
              handlePlaceOrder(res.razorpay_details);
            }
            else {
              setTimeout(() => {
                window.location.href = V2HREF;
                setPaymentModal(false)
              }, 2000)
            }
          }
        } else {
          setBookingStatus(false);
          setBookingMsg(res.msg);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error(error);
      } finally {
        setSubmitLoader(false)
      }
    }
    submitTeleConsultationBooking();
  };

  const handlePlaceOrder = async (razorpay_details) => {
    const handlePayment = async (razorpay_details) => {
      // Define a custom handler function
      const customSuccessHandler = function (response) {
        MakeOnlinePayment(
          razorpay_details.call_back_url,
          response.razorpay_payment_id,
          razorpay_details.insert_id
        );
      };

      setBookingStatus(false);

      const customOptions = {
        handler: customSuccessHandler,
        modal: {
          ondismiss: function () {
            toast.error("Payment Cancelled");
            setTimeout(() => {
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }, 2000)
          }
        }
      };

      const rzp1 = new Razorpay({ ...razorpay_details, ...customOptions });

      rzp1.on("payment.failed", function (response) {
        console.log(response.error.description);
        console.log(response.error.reason);
      });

      rzp1.open();
    };

    handlePayment(razorpay_details);

    const payment_data = {
      insert_id: "",
      transaction_id: "",
    };

    const paymentOptions = { ...requestOptions };
    paymentOptions.method = "POST";
    paymentOptions.body = JSON.stringify(payment_data);
    paymentOptions.contentType = false;
    paymentOptions.processData = false;

    const MakeOnlinePayment = async (
      callBackUrl,
      transaction_id,
      insert_id
    ) => {
      setPaymentLoading(true);
      const url = new URL(callBackUrl);

      const payment_Transaction_data = new FormData();
      payment_Transaction_data.append("insert_id", insert_id);
      payment_Transaction_data.append("transaction_id", transaction_id);

      const updatedRequestOption = {
        ...paymentOptions,
        body: payment_Transaction_data,
      };

      try {
        const response = await fetch(url.href, updatedRequestOption);

        const res = await response.json();
        if (res.status) {
          setPaymentAnimation(true);
          setpaymentMsg(res.message);
          setPaymentLoading(false);
          setPaymentModal(true);

          if (V2HREF) {
            setTimeout(() => {
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }, 500)
          } else {
            setTimeout(() => {
              setPaymentModal(false);
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }, 1500);
          }

        } else {
          setPaymentAnimation(false);
          setpaymentMsg(res.message);
          setPaymentModal(true);
          if (isV2token) {
            setTimeout(() => {
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }, 500)
          } else {
            setTimeout(() => {
              setPaymentModal(false);
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }, 1500);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

  };

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 6);

  const handleNavigation = () => {
    window.history.back();
  };

  useEffect(() => {
    const formData = new FormData();

    formData.append("date", formatDate(BookingDate));
    formData.append("dept_id", BookedTeleConsultData.dept_id);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;

    async function getTimeSlot() {
      try {
        const response = await fetch(
          GET_TIME_SLOT_API,
          requestOptionsCopy
        );
        const res = await response.json();
        if (res) {
          setTimeSlotoptions(res.time_slot)
        }
      } catch (error) {
        console.error(error);
      }
    }
    // if (show_slot == "1") {
    getTimeSlot();
    // }
  }, [BookingDate])

  useEffect(() => {
    if (timeSlotOptions && timeSlotOptions.length > 0) {
      setSelectedTimeSlot(timeSlotOptions[0]); // Set to the first value in timeSlotOptions
    }
  }, [timeSlotOptions]);


  const formatDate = (date) => {
    if (!date) return '';

    const formattedDate = format(date, 'yyyy-MM-dd'); // Format the date to 'yyyy-MM-dd'
    setBookingData(formattedDate)
    return formattedDate;
  };


  // useEffect(() =>{
  //   if(show_slot == "1"){
  //     BookedTeleConsultData?.cosult_charge = 0
  //   }
  // },[])

  useEffect(() => {
    if (BookedTeleConsultData) {
      setValue("patientMobile", patientMobile || "");
      setValue("patientName", patientName || "");
      setValue("patientEmail", patientEmail || "");
    }
  }, [BookedTeleConsultData, setValue, patientEmail, patientName, patientMobile])

  return (
    <>
      <div className="home-review-banner mb-4">
        <div className="home-app-header d-flex justify-content-start align-items-center">

          <div className="home-header-teleconsultation d-flex justify-content-between align-items-center">
            <div className="header-navigation-icon">
              <h6 className="mb-0">Nutrition</h6>
            </div>
          </div>
        </div>
        <div className="banner-cover">
          <img src={Nutition_banner}></img>
        </div>

        <div className="Booking-teleconsult-main-wrapper px-3">
          <p className="book-teleconsult-inner-heading">
            Teleconsultation will be conducted for this person
          </p>
          <div className="book-now-form-main-cover">
            <div className="book-now-form">
              <h6 className="tele-form-heading">Enter Patient Details</h6>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 book-now-input-box">
                  <label className="tele-labels">Patient Name</label>
                  <input
                    type="text"
                    placeholder="Patient Name"
                    {...register("patientName", {
                      required: "Please enter your Name",
                    })}
                    value={patientName}
                    onChange={(e) => {
                      setPatientName(e.target.value);
                    }}
                    onInput={(e) => validateTextInput(e.target)}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientName && errors.patientName.message}
                  </span>
                </div>
                <label className="tele-labels">Set Appointment Date</label>
                <DatePicker
                  name="start_date"
                  selected={BookingDate}
                  onChange={(date) => setBookingDate(date)}
                  placeholderText="Select a Booking Date"
                  showYearDropdown={false}
                  showMonthDropdown={false}
                  autoComplete="off"
                  className="mb-3 booking-date"
                  minDate={new Date()}
                  maxDate={maxDate}
                  dateFormat="yyyy-MM-dd" // Set the dateFormat directly in the DatePicker component
                  value={bookingData} // Use formatDate to format the value for display
                />
                {/* {show_slot === "1" && ( */}
                <div className="time-slot-input-box mb-3">
                  <label htmlFor="selectOption" className="tele-labels">Select Appointment Time</label>
                  <select
                    id="selectOption"
                    value={selectedTimeSlot}
                    onChange={(e) => setSelectedTimeSlot(e.target.value)}
                    required
                  >
                    {timeSlotOptions?.length > 0 && timeSlotOptions?.map((timeslot) => (
                      <option key={timeslot} value={timeslot}>{timeslot}</option>
                    ))}
                  </select>
                </div>
                {/* )} */}


                <div className="mb-3 book-now-input-box">
                  <label className="tele-labels">Age</label>
                  <input
                    type="text"
                    maxLength={2}
                    placeholder="Age"
                    {...register("patientAge", {
                      required: "Please enter your Age",
                      pattern: {
                        value: /^\d+$/,
                        message: "Please enter a valid age",
                      },
                    })}
                    defaultValue={patientAge}
                    onChange={(e) => {
                      setPatientAge(e.target.value);
                    }}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientAge && errors.patientAge.message}
                  </span>
                </div>
                <div className="gender-input-radio-box mb-3">
                  <Form.Group>
                    <Form.Label className="radio-title-label">
                      Select Gender
                    </Form.Label>
                    <InputGroup>
                      <div className="radio-input-box d-flex align-items-center">
                        <InputGroup.Radio
                          name="gender"
                          value="male"
                          checked={selectedGender === "male"}
                          onChange={handleGenderChange}
                          class="gender-input-radio p-0"
                          required
                        />
                        <Form.Label className="mr-2">Male</Form.Label>
                      </div>

                      <div className="radio-input-box d-flex align-items-center">
                        <InputGroup.Radio
                          name="gender"
                          value="female"
                          checked={selectedGender === "female"}
                          onChange={handleGenderChange}
                          class="gender-input-radio"
                        />
                        <Form.Label>Female</Form.Label>
                      </div>

                      <div className="radio-input-box d-flex align-items-center">
                        <InputGroup.Radio
                          name="gender"
                          value="other"
                          checked={selectedGender === "other"}
                          onChange={handleGenderChange}
                          class="gender-input-radio"
                        />
                        <Form.Label>Other</Form.Label>
                      </div>
                    </InputGroup>
                  </Form.Group>
                </div>
                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="tele-labels">Phone Number</label>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    {...register("patientMobile", {
                      required: "Please enter your number",
                    })}
                    value={patientMobile}
                    onChange={(e) => setPatientMobile(e.target.value)}
                    onInput={(e) => validateNumberInput(e)}
                    maxLength={10}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientMobile && errors.patientMobile.message}
                  </span>
                </Form.Group>
                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="tele-labels">Email Address</label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    {...register("patientEmail", {
                      // pattern: {
                      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      //   message: "Please enter a valid email address",
                      // },
                    })}
                    value={patientEmail}
                    onChange={(e) => {
                      setPatientEmail(e.target.value);
                    }}
                  />
                  <span className="text-danger validation-error">
                    {errors.patientEmail && errors.patientEmail.message}
                  </span>
                </Form.Group>
                {paymentType === "wallet" && (
                  <Form.Check
                    type="checkbox"
                    className="payment_method"
                    label="Make Payment from Wallet"
                  />
                )}
                <p className="wallet_summary-point"></p>
                <div className="submit-btn-box d-flex justify-content-center">
                  <button type="submit" disabled={submitLoader}>{submitLoader ? "Submitting" : "Submit"}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="middle_toaster">
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "custom-toast position-middle-center",
              duration: 2000,
              style: {
                background: "#fff",
                color: "#363636",
                fontSize: "14px",
                width: "300px",
                top: "55px !important",
              },
            }}
          />
        </div>
        {bookingStatus && (
          <SuccessFull
            show={bookingStatus}
            successMessage={bookingMsg}
            animationtype={bookingStatus}
          />
        )}
        {paymentAnimation && (
          <PaymentModal
            show={isPaymentModalOpen}
            successMessage={paymentMsg}
            paymentAnimation={paymentAnimation}
          />
        )}

        {isPaymentLoading && (
          <div className="payment-loading-page">
            <PaymentLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default BookTeleConsultation;
