// src/hooks/useFetchMedicine.js
import { useQuery } from '@tanstack/react-query';
const pincode = localStorage.getItem("pincode")

const fetchMedicine = async () => {
  const response = await fetch('https://wellness.medibhai.co.in/api/medicine/medicine-category-details');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchMedicineList = async (medicineName) => {
  const response = await fetch(`https://wellness.medibhai.co.in/api/medicine/medicine-list?pincode=${pincode}&medicine_name=${medicineName}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const useFetchMedicine = () => {
  return useQuery({
    queryKey: ['fetchMedicine'],
    queryFn: fetchMedicine,
    staleTime: 86400000,
    cacheTime: 86400000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
const useFetchMedicineList = (medicineName) => {
  return useQuery({
    queryKey: ['fetchMedicineList', medicineName],
    queryFn: () => fetchMedicineList(medicineName),
    staleTime: 86400000,
    cacheTime: 86400000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!medicineName, // Only run the query if medicineName is not empty
  });
};

export { useFetchMedicine, useFetchMedicineList };
