import React, { useEffect, useState } from 'react'
import Medicine_Header from '../Components/Medicine_Header'
import '../Medicine_Style/home.css'
import { no_preview, Prescripti_Doctor_Icon, Prescription_Icon, } from '../Images'
import Medicine_Search from '../Components/Medicine_Search'
import Medicine_Product from '../Components/Medicine_Product'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Medicine_Recent from '../Components/Medicine_Recent'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMedicinelist, fetchMedicineRecent } from '../../../Redux/actions/GetApiCall'
import { useFetchMedicineList, useFetchMedicine } from '../../../QueryClient/MedicineQuery'
import { Controller } from 'swiper/modules'
import { useNavigate } from "react-router-dom";
import MediLoader from '../animation/MediLoader'

const Medicine_Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loader: mediloader, data: mediData, error: mediError } = useSelector(state => state.medicine_recent_list);

    const [medicineData, setMedicineData] = useState([]);

    const { data: medicineQueryData, error: medicineQueryError, isLoading: isLoadingMedicine } = useFetchMedicine();
    const { data: medicineList, isLoading: isLoadingList, error: medicineListError } = useFetchMedicineList(medicineQueryData?.selected_cateogry?.value);

    useEffect(() => {
        dispatch(fetchMedicineRecent());
        dispatch(fetchMedicinelist())
    }, [dispatch]);



    useEffect(() => {
        if (medicineQueryData) {
            setMedicineData(medicineQueryData);
        }
    }, [medicineQueryData]);

    // useEffect(() => {
    //     setMedicineData(mediData)
    // }, [mediData])

    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);

    const handleCorner = (item) => {
        const cornerData = [item]
        navigate('/health-corner', { state: cornerData })
    }

    const handlePresciption = () => {
        const Title = "Upload Prescription"
        navigate('/prescription', { state: Title })
    }
    const handleCallDoctor = () => {
        const Title = "Call a doctor"
        navigate('/medicine-doctor', { state: Title })
    }

    const height = "25%"

    return (

        <div className="home-review-banner">
            <Medicine_Header />

            <Medicine_Search />

            <div className="data-pro-max-repo-prescription">
                <div className="prescription-repo-box-cover">
                    <div className="prescription-box-data" onClick={() => handlePresciption()}>
                        <div className="prescription-text-head">
                            <p>Upload Prescription</p>
                        </div>
                        <div className="prescription-image-box-repo-img">
                            <img src={Prescription_Icon} alt="prescription-icon" />
                        </div>
                    </div>
                    <div className="prescription-box-data doctor-prescription-data" onClick={() => handleCallDoctor()}>
                        <div className="prescription-text-head">
                            <p>Call doctor for prescription</p>
                        </div>
                        <div className="prescription-image-box-repo-img doctor-prescription-data-img">
                            <img src={Prescripti_Doctor_Icon} alt="prescription-doctor-icon" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="categories-repo-box-data">
                <div className="categories-data">
                    <p>Categories</p>
                </div>
            </div>

            {isLoadingMedicine ? (
                <>
                    <MediLoader height={height} />
                </>
            ) : (
                <Medicine_Product medicineData={medicineData} />
            )}

            <div className="medicine-slider-repo-box">
                <Swiper className="mySwiper" slidesPerView={1.2}>
                    {medicineData && medicineData?.medicine_banner && medicineData?.medicine_banner?.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="image-box-slider-repo">
                                <img src={item?.url} alt="" />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className="explore-categories-wrapper">
                <div className="explore-head-text-box">
                    <p>Explore Wellness & Nutrition</p>
                </div>

                <div className="explore-product-repo-box-wrapper">
                    {medicineList && medicineList?.data.slice(0, 4).map((item, index) => (
                        <div className="explore-product-repo-box-cover" key={index}>
                            <div className="box-repo-data-content">
                                <img src={item?.banner_url ? item?.banner_url : no_preview}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = no_preview;
                                    }}
                                    alt="product-icon" />
                            </div>
                            <p className='ellipsis-text'>{item?.name}</p>
                        </div>
                    ))}
                </div>

                <div className="explore-btn-repo-box">
                    <button onClick={() => handleCorner({ value: medicineQueryData?.selected_cateogry?.value, label: medicineQueryData?.selected_cateogry?.label })}>
                        <p>EXPLORE</p>
                    </button>
                </div>
            </div>

            <div className="offer-text-repo-boxs">
                <div className="offer-text-box">
                    <p>Exclusive Offers</p>
                </div>
                <div className="offer-view-all">
                    <p>VIEW ALL</p>
                </div>
            </div>

            <div className="medicine-slider-repo-box">
                <Swiper className="mySwiper" slidesPerView={1.25}>
                    {medicineData && medicineData?.offer_banner && medicineData?.offer_banner?.map((item, index) => (
                        <SwiperSlide>
                            <div className="image-box-slider-repo">
                                <img src={item?.url} alt="" />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className="recent-text-repo-boxs">
                <div className="recent-text-box">
                    <p>Recent Ordered Medicines</p>
                </div>
                <div className="recent-view-all">
                    <p>VIEW ALL</p>
                </div>
            </div>

            <Medicine_Recent mediData={mediData} />

            <div className="shop-wrapper-box-cover">
                <div className="shop-head-text">
                    <h6>Shop by health Concerns</h6>
                </div>

                <div className="shop-slider-repo-wrapper">
                    <Swiper className="mySwiper" slidesPerView={2.15}
                        modules={[Controller]}
                        onSwiper={setFirstSwiper}
                        controller={{ control: secondSwiper }}
                    >
                        {medicineData && medicineData?.health_concern && medicineData?.health_concern.slice(0, 5).map((item, index) => (
                            <SwiperSlide key={index} className='repo-conner'>
                                <div onClick={() => handleCorner({ value: item?.value, label: item?.label })} className="shop-image-box-slider-repo" style={{ backgroundImage: `url(${item?.logo})` }}>
                                </div>

                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <Swiper className="mySwiper" slidesPerView={2.15}
                        modules={[Controller]}
                        onSwiper={setSecondSwiper}
                        controller={{ control: firstSwiper }}
                    >
                        {medicineData && medicineData?.health_concern && medicineData?.health_concern.slice(5, 10).map((item, index) => (
                            <SwiperSlide key={index} className='repo-conner'>
                                <div onClick={() => handleCorner({ value: item?.value, label: item?.label })} className="shop-image-box-slider-repo" style={{ backgroundImage: `url(${item?.logo})` }}>
                                </div>

                            </SwiperSlide>
                        ))}
                    </Swiper>


                </div>

            </div>

        </div>
    )
}

export default Medicine_Home