export const RadioData = () => {
    const dataradio = [
        {
            id: 1,
            name: "Radiology",
        },
        {
            id: 2,
            name: 'Categories',
        },
        {
            id: 3,
            name: 'CT Scan',
        },
    ];

    return dataradio;
};
