import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import Medicine_Header from '../Components/Medicine_Header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicinelist } from '../../../Redux/actions/GetApiCall';
import '../Medicine_Style/medicine-corner.css';
import MediLoader from '../animation/MediLoader';
import Medicine_Card from '../Components/Medicine_Card';


const Medicine_Corner = () => {
    const location = useLocation();
    const Title = location.state;
    const searchData = location.state?.[0] || {}
    console.log(searchData)
    const item = location.state?.[0] || {};
    const dispatch = useDispatch();
    const [medicineCorner, setMedicineCorner] = useState([])
    const [loaderStatus, setLoaderStatus] = useState(false);
    const { loader: mediloader, data: mediData, error: mediError } = useSelector(state => state.medicine_list);

    useEffect(() => {
        if (item?.value !== undefined || searchData?.inputData !== undefined) {
            dispatch(fetchMedicinelist(item?.value || searchData?.inputData))
        }
    }, [dispatch])

    useEffect(() => {
        setMedicineCorner(mediData)
    }, [mediData])

    const height = "75%"
    const corner = "data"
    console.log(mediData)

    return (
        <div className="home-review-banner">
            <Medicine_Header Title={item?.label || searchData?.Title} />

            <div className="corner-wrapper-box">
                {mediloader && !loaderStatus ? (
                    <MediLoader height={height} />
                ) : (
                    <div className={mediData?.data.length > 1 ? "corner-data-container" : ""}>
                        <div className="row">
                            <Medicine_Card
                                mediData={medicineCorner}
                                corner={corner}
                                inputPara={item?.value || searchData?.inputData}
                                setLoaderStatus={setLoaderStatus}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Medicine_Corner