import React from 'react';
import './cartloader.css'

const CartLoader = () => {
    return (
        /* From Uiverse.io by Shoh2008 */
        <div class="loader3">
            <div class="circle1"></div>
            <div class="circle1"></div>
            <div class="circle1"></div>
            <div class="circle1"></div>
        </div>


    )
}

export default CartLoader