import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import {
  ArrowBack,
  Ct_Scan,
  DummyMedicine,
  LabCenter,
  LabReportTest,
  Radiology_check,
  appCartIcon,
  appHeartIcon,
  appUploadIcon,
} from "../../images";
import { IoIosSearch } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import RadiologySection from "../LocationSection/RadiologySection";
import BookTest from "./pages/BookTest";
import HeaderRadio from "./HeaderRadio";
import { RadioData } from "../../Constants/RadioHeaderData";
import Loader from "../../Animation/Loader";
import NoDataFoundComp from "../../components/NoDataFound";
import toast from "react-hot-toast";

const Radiology = () => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [booktest, setBookTest] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [frequentlyData, setFrequentlyData] = useState(null);
  const [loadingHomeData, setLoadingHomeData] = useState(true);
  const [ctdata, setCtData] = useState(
    JSON.parse(localStorage.getItem("CTDATA")) || []
  );
  const totalMrp = ctdata.reduce(
    (total, item) => total + item.medibhai_price,
    0
  );
  useEffect(() => {
    const fetchBookTest = async () => {
      try {
        const BooktestRes = await fetch(`/api/radiology/get_category_list`, {
          headers: {
            Authorization: `${token}`,
          },
          method: "GET",
        });

        if (!BooktestRes.ok) {
          console.error(
            "API error:",
            BooktestRes.status,
            BooktestRes.statusText
          );
          const errorData = await BooktestRes.json().catch(() => null);
          console.error("Error details:", errorData);
          throw new Error("Network response was not ok");
        }

        const BooktestData = await BooktestRes.json();
        setBookTest(BooktestData?.category_list);
        setImageUrl(BooktestData?.image_base_url);
      } catch (error) {
        console.error("Error fetching Book Test data:", error);
      } finally {
        setLoadingHomeData(false);
      }
    };

    fetchBookTest();
  }, [token]);

  useEffect(() => {
    const fetchFrequentlyData = async () => {
      const formdata = new FormData();
      formdata.append("category_id", 2);

      try {
        const frquentlyRes = await fetch(
          `/api/radiology/get_test_list_by_category`,
          {
            headers: {
              Authorization: `${token}`,
            },
            method: "POST",
            body: formdata,
          }
        );
        if (!frquentlyRes.ok) {
          console.error(
            "API error:",
            frquentlyRes.status,
            frquentlyRes.statusText
          );
          const errorData = await frquentlyRes.json().catch(() => null);
          console.error("Error details:", errorData);
          throw new Error("Network response was not ok");
        }

        const FreqData = await frquentlyRes.json();
        setFrequentlyData(FreqData?.test_list);
        // console.log(FreqData?.test_list)
      } catch (error) {
        console.error("Error in Fetching Frequently Data");
      } finally {
        setLoadingHomeData(false);
      }
    };
    fetchFrequentlyData();
  }, []);

  const handleCart = (id) => {
    const isItemInCart = ctdata.some((item) => item.id === id);

    if (!isItemInCart) {
      setCtData((prevData) => [
        ...prevData,
        ...frequentlyData.filter((data) => data.id === id),
      ]);
      toast.success("Item Added");
    } else {
      const updatedData = ctdata.filter((item) => item.id !== id);
      setCtData(updatedData);
      toast.success("Item Removed Successfully");
    }
  };

  useEffect(() => {
    localStorage.setItem("CTDATA", JSON.stringify(ctdata));
  }, [ctdata]);
  return (
    <>
      <div className="home-review-banner">
        <HeaderRadio />
        <div
          className={`app-searh-section
        
             ? "hidden" : ""}`}
        >
          <div className="inputWithIcon">
            <input
              type="text"
              placeholder="Search"
            />
            <button
         
            >
              <IoIosSearch />
            </button>
          </div>
        </div>

        <div className="lab-upload">
          <div className="container upload-repo">
            <div className="lab-prescription-upload-box">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-7 lab-content-repo">
                  <div className="lab-prescription-content">
                    <h6>Upload Prescription</h6>
                    <p>Placed order with prescription</p>
                  </div>
                  <div className="lab-prescription-image">
                    <NavLink to={`/UploadPrescription/0`}>
                      <button>
                        <img src={appUploadIcon} width="100%" alt="review" />
                        <p>Upload Prescription</p>
                      </button>
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-5 d-flex align-items-center justify-content-center">
                  <div className="test-image">
                    <img src={Radiology_check} width="100%" alt="review" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lab-book-categories radiology-categories">
          <div className="book-test">
            <h6>Book Test by categories</h6>
            <NavLink to={`/categories`}>
              <p>View All</p>
            </NavLink>
          </div>
          <Swiper slidesPerView={3.6} spaceBetween={20}>
            {loadingHomeData ? (
              <Loader />
            ) : booktest && booktest.length > 0 ? (
              booktest.map((bookData) => (
                <SwiperSlide>
                  <div className="lab-book-test-box" key={bookData?.category}>
                    <button>
                      <div className="lab-img-box">
                        <img
                          src={
                            bookData?.image &&
                            bookData?.image !== "undefined" ? (
                              `${imageUrl}/${bookData?.image}`
                            ) : (
                              <DummyMedicine />
                            )
                          }
                          width="100%"
                          alt={bookData?.category}
                        />
                        <p>{bookData?.category}</p>
                      </div>
                    </button>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <NoDataFoundComp />
            )}
          </Swiper>
        </div>

        <div className="freq-book-test-wrapper">
          <div className="freq-book-heading">
            <h6>Frequently Booked Test</h6>
          </div>

          {loadingHomeData ? (
            <Loader />
          ) : frequentlyData && frequentlyData.length > 0 ? (
            frequentlyData.slice(0, 4).map((frequent) => (
              <div className="freq-book-box-cover" key={frequent.id}>
                <div className="freq-book-test-box">
                  <div className="heading-test-boxs">
                    <img src={Ct_Scan} width="100%" alt="review" />
                    <h6>{frequent?.testname}</h6>
                  </div>
                  <div className="test-center">
                    <div className="test-center-icon">
                      <img src={LabCenter} width="100%" alt="review" />
                      <p>Lab Centre Visit</p>
                    </div>
                    <div className="test-center-heading-content">
                      <h6>Medibhai Price : ₹ {frequent.medibhai_price}</h6>
                      <span>MRP: ₹ {frequent?.mrp}</span>
                    </div>
                  </div>
                  <div className="test-add-carts-btn">
                    {ctdata.some((item) => item.id === frequent.id) ? (
                      <button
                        className="remove-ct-data"
                        onClick={() => handleCart(frequent.id)}
                      >
                        Remove
                      </button>
                    ) : (
                      <div className="common-add-cart">
                        <button onClick={() => handleCart(frequent.id)}>
                          <p>ADD TO CART</p>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoDataFoundComp />
          )}
           <div className="test-view-all">
            <NavLink to={`/ct-scan`}>
              <h6>VIEW ALL</h6>
            </NavLink>
          </div>
        </div>

        <div className="health-work">
          <div className="book-test">
            <h6>How it works</h6>
          </div>
          <div className="health-how-it-works">
            <Swiper slidesPerView={1.4} spaceBetween={0}>
              <SwiperSlide>
                <div className="health-test-work">
                  <div className="health-test-number">
                    <span>01</span>
                  </div>
                  <div className="health-test-box-cover">
                    <img src={LabReportTest} width="100%" alt="review" />
                    <h6>Test Booking</h6>
                    <p>
                      Select your test as per your condition and requirement
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="health-test-work">
                  <div className="health-test-number">
                    <span>02</span>
                  </div>
                  <div className="health-test-box-cover">
                    <img src={LabReportTest} width="100%" alt="review" />
                    <h6>Health Expert Deputed</h6>
                    <p>Highly skilled Technicians will collect your sample</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="health-test-work">
                  <div className="health-test-number">
                    <span>03</span>
                  </div>
                  <div className="health-test-box-cover">
                    <img src={LabReportTest} width="100%" alt="review" />
                    <h6>Sample Collection</h6>
                    <p>
                      100% safe and Hygienic sample collection at your
                      convenient space
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="health-test-work">
                  <div className="health-test-number">
                    <span>04</span>
                  </div>
                  <div className="health-test-box-cover">
                    <img src={LabReportTest} width="100%" alt="review" />
                    <h6>Test Investigation</h6>
                    <p>
                      NABL accreditation laboratories will investigate your
                      sample
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="health-test-work">
                  <div className="health-test-number">
                    <span>05</span>
                  </div>
                  <div className="health-test-box-cover">
                    <img src={LabReportTest} width="100%" alt="review" />
                    <h6>E-Reports</h6>
                    <p>
                      You will receive your E-report on your register email id.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        {!loadingHomeData  && ctdata && ctdata.length > 0 && (
          <div className="ct-total-item">
            <div className="lab-total-item-content ct-heading-total">
              <p>
                Total <span>₹ {totalMrp}</span>
              </p>
              <p className="add-item ct-add-item">{ctdata.length} Test Added</p>
            </div>
            <div className="lab-total-item-cart ct-btn-select">
              <NavLink to={'/centers'}>
                <button>
                  <p>Select Center</p>
                </button>
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Radiology;
