import React, { useEffect, useState } from 'react'
import { headerLogo } from '../../images'
import { MY_ACCOUNT_API, PACKAGES_API, SAVE_PACKAGES_API } from '../../Constants';
import { genderList, relationshipList } from '../../data';
import { FaRegCircleXmark } from 'react-icons/fa6';
import { PiUploadBold } from "react-icons/pi";
import WaitLoader from '../../Animation/WaitLoader';
import DatePicker from 'react-date-picker';
import { useForm } from 'react-hook-form';
import SuccessFull from '../../Animation/SuccessFull';
import { useNavigate } from 'react-router';
import OpdLoader from '../../Animation/OpdLoader';



const NewOpdHome = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [successmodal, setSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [packagesData, setPackagesData] = useState([]);
    const [userData, setUserData] = useState([])
    const [loader, setLoader] = useState(false)
    const [walletData, setWalletData] = useState([])
    const [selectedPackage, setSelectedPackage] = useState('');
    const [selectedPackageData, setSelectedPackageData] = useState([])
    const [paymentLoading, setPaymentLoading] = useState(false)
    const token = localStorage.getItem("ACCESS_TOKEN");
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm(
    );
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `${props.token ?? token}`,
        },
    };
    const PackagesData = async () => {
        try {
            const fetchpackage = await fetch(`${PACKAGES_API}`, requestOptions);
            if (!fetchpackage.ok) {
                console.error(
                    "API error:",
                    fetchpackage.status,
                    fetchpackage.statusText
                );
                const errorData = await fetchpackage.json().catch(() => null);
                console.error("Error details:", errorData);
                throw new Error("Network response was not ok");
            }

            const packagesDataResponse = await fetchpackage.json();
            setPackagesData(packagesDataResponse?.user_package)
        } catch (error) {
            console.error(error)
        }
    }
    const UserData = async () => {
        setLoader(true)
        try {
            const fetchUser = await fetch(`${MY_ACCOUNT_API}/${selectedPackage}`, requestOptions);
            if (!fetchUser.ok) {
                console.error(
                    "API error:",
                    fetchUser.status,
                    fetchUser.statusText
                );
                const errorData = await fetchUser.json().catch(() => null);
                console.error("Error details:", errorData);
                throw new Error("Network response was not ok");
            }

            const UserDataResponse = await fetchUser.json();
            setUserData(UserDataResponse?.data?.profile_details)
            setWalletData(UserDataResponse?.data?.wallet_data?.module_wise_pending)
        } catch (error) {
            console.error(error)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        PackagesData();
    }, [])

    useEffect(() => {
        UserData();
    }, [selectedPackage])


    const currentDate = new Date();
    const minDate = new Date(currentDate.getFullYear() - 100, 11, 31);
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    const defaultValue = new Date(maxDate); // Change to Date object

    const [opdDate, setOpdDate] = useState(defaultValue);
    const [selectedDates, setSelectedDates] = useState({ opd_date: defaultValue });

    const handleChange = (dateKey, date) => {
        if (date instanceof Date && !isNaN(date)) {
            setSelectedDates(prevState => ({
                ...prevState,
                [dateKey]: date
            }));

            if (dateKey === 'opd_date') {
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();

                const formattedDate = `${day}-${month}-${year}`;
                setOpdDate(date); // Change to Date object
            }
        } else {
            console.error('Invalid date:', date);
        }
    };

    useEffect(() => {
        // Pre-select the first option initially
        if (packagesData && packagesData.length > 0) {
            setSelectedPackage(packagesData[0].id);
            setSelectedPackageData(packagesData[0])
        }
    }, [packagesData]);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const selectedPackageData = packagesData.find(data => data.id.toString() === selectedValue);
        setSelectedPackageData(selectedPackageData);
        setSelectedPackage(selectedValue);
    };


    const [files, setFiles] = useState({
        Invoice: [],
        Prescription: [],
        BankDetails: [],
        OtherDoucments: [],
    });

    const docTypes = [
        {
            Invoice: "Invoice/Receipt",
            Prescription: "Prescription",
            BankDetails: "Bank Details",
            OtherDoucments: "Other Doucments",
        },
    ];

    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');

    const handleChangeAmount = (event) => {
        const value = event.target.value;
        const valid = value.replace(/\D/g, ''); // Remove all non-numeric characters
        setAmount(valid); // Update the state with the valid numeric value

        if (valid) {
            if (parseFloat(valid) > parseFloat(walletData?.DOCTOR)) {
                setError('The amount entered is more than the reimbursement amount');
            } else {
                setError('');
            }
        }
    };


    const [encodedFiles, setEncodedFiles] = useState([]);

    const documentTypes = Object.keys(files);

    const handleFileChange = (e, documentData) => {
        const newFiles = Array.from(e.target.files);

        setFiles((prevFiles) => {
            // Filter out files with the same name
            const uniqueNewFiles = newFiles.filter(
                (newFile) =>
                    !prevFiles[documentData].find(
                        (prevFile) => prevFile.name === newFile.name
                    )
            );
            return {
                ...prevFiles,
                [documentData]: [...prevFiles[documentData], ...uniqueNewFiles],
            };
        });
    };

    useEffect(() => {
        console.log(files)
    }, [files])

    const handleFileDelete = (e, documentType, file, index) => {
        e.preventDefault();
        const updatedDoc = files[documentType].filter(
            (myfile) => myfile.name !== file.name
        );
        setFiles((prevFiles) => ({
            ...prevFiles,
            [documentType]: updatedDoc,
        }));
    };

    useEffect(() => {
        const readFiles = async () => {
            const readFile = (file, documentType) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64Data = reader.result.split(",")[1];
                        const documentData = {
                            file_type: docTypes[0] ? docTypes[0][documentType] : "",
                            file_name: file.name,
                            file_path: base64Data,
                        };
                        resolve(documentData);
                    };
                    reader.onerror = () =>
                        reject(new Error(`Error reading file: ${file.name}`));
                    reader.readAsDataURL(file);
                });
            };

            const promises = [];
            Object.entries(files).forEach(([documentType, fileInputs]) => {
                if (fileInputs.length > 0) {
                    Array.from(fileInputs).forEach((file) => {
                        promises.push(readFile(file, documentType));
                    });
                }
            });

            try {
                const documentDataArray = await Promise.all(promises);
                setEncodedFiles((prevState) => {
                    // Only update state if the data has actually changed
                    if (JSON.stringify(prevState) !== JSON.stringify(documentDataArray)) {
                        return documentDataArray;
                    }
                    return prevState;
                });
            } catch (error) {
                console.error("Error reading files", error);
            }
        };

        readFiles();
    }, [files, docTypes]);

    const onSubmit = async (data) => {
        setPaymentLoading(true)
        const onDataSubmit = JSON.stringify({
            mobile_number: userData?.mobile,
            email: userData?.email,
            name: data?.name,
            gender: data?.gender,
            relation: data?.relation,
            dob: opdDate,
            amount: amount,
            lead_type: '2',
            package_code: selectedPackageData?.package_code,
            files: encodedFiles,
            family_user_id: '0',
            user_package_id: selectedPackageData?.id
        })
        try {
            const opdData = await fetch(`${SAVE_PACKAGES_API}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `${token}`,
                    },
                    body: onDataSubmit,
                }
            );

            const responseopdDate = await opdData.json();
            if (responseopdDate.status === true) {
                setSuccessModal(true);
                setSuccessMessage(responseopdDate.message);
                setModalShow(true)
                setTimeout(() => {
                    setModalShow(false)
                    setSuccessModal(false)
                    navigate('/home')
                }, 2000);

            }
        } catch (error) {
            console.error(error)
        } finally {
            setPaymentLoading(false)
        }
    }

    const requiredDocumentTypes = documentTypes.slice(0, documentTypes.length - 1);
    const allRequiredDocumentsHaveData = requiredDocumentTypes.every(documentData => files[documentData]?.length > 0);

    return (
        <div className="home-review-banner">
            <div className="home-app-header d-flex justify-content-start align-items-center">

                <div className="header-logo mx-3">
                    <img
                        src={headerLogo}
                        className="header-medibhai-logo"
                        width="100%"
                        alt="header-logo"
                    />
                    <p className='opd-text'>OPD</p>
                </div>
            </div>
            <div className="opd-wrapper-cover-data">
                {loader && loader ? (
                    <WaitLoader />
                ) : (
                    <div className="container">
                        <div className="ope-select-data-box">
                            <p>Please Select the Package</p>
                            <select value={selectedPackage} onChange={handleSelectChange}>
                                {packagesData?.map((data, index) => (
                                    <option key={index} value={data?.id}>{data?.package_name}</option>
                                ))}
                            </select>
                        </div>

                        {walletData && walletData?.DOCTOR !== 0 ? (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="heading-data-form">
                                    <p>Get in person consultation reimbursement up to 5000</p>
                                </div>
                                <div className="form-data-repo-box">
                                    <input type="text" value={userData?.mobile} disabled />
                                    <input type="text" value={userData?.email} disabled />
                                </div>
                                <div className="form-page-data">
                                    <div className="page-heading-text">
                                        <p>Reimbursement Details</p>
                                    </div>

                                    <div className="form-page">
                                        <input type="text" placeholder='Enter Your Name' {...register('name', { required: true })} />
                                        {errors.name && <span className='error-text-mssg'>This field is Required</span>}
                                        <div className="option-data-page">
                                            <div className="gender-select-page">
                                                <select
                                                    name="gender"
                                                    {...register('gender', { required: true })}>
                                                    <option value="" selected disabled>Select Gender</option>
                                                    {genderList.map((data, index) => (
                                                        <option key={index} value={data?.text}>{data?.text}</option>

                                                    ))}
                                                </select>
                                                {errors.gender && <span className='error-text-mssg gen-text'>Gender is required</span>}
                                            </div>
                                            <div className="gender-select-page">
                                                <select
                                                    name="relation"
                                                    {...register('relation', { required: true })}>
                                                    <option value="">Select Relation</option>
                                                    {relationshipList.map((data, index) => (
                                                        <option key={index} value={data?.text}>{data?.text}</option>
                                                    ))}
                                                </select>
                                                {errors.relation && <span className='error-text-mssg gen-text'>Relation is required</span>}
                                            </div>
                                        </div>
                                        <DatePicker
                                            onChange={(date) => handleChange('opd_date', date)}
                                            clearIcon={null}
                                            value={selectedDates?.opd_date || defaultValue}
                                            name='opd_date'
                                            dateFormat='dd-MM-yyyy'
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            selected={selectedDates?.opd_date || defaultValue}
                                        />
                                        <div>
                                            <input
                                                type="text"
                                                placeholder='Enter amount to be reimburse'
                                                value={amount}
                                                onChange={handleChangeAmount}
                                                pattern='[0-9]*'
                                                inputMode='numeric'
                                                required='true'
                                            />
                                            {error && <p className='error-text'>{error}</p>}
                                            <p className='pending-text-repo'>Pending Amount {walletData && walletData?.DOCTOR}</p>
                                        </div>

                                        <div className="supporting-doucment-wrapper">
                                            <div className="supporting-doucment-heading">
                                                <p>Supporting Documents</p>
                                            </div>
                                            <div className="file-data-box-repo">
                                                {documentTypes.map((documentData, index) => (
                                                    <div className="upload-document-boxlist-cover">
                                                        <div className="upload-document-list-header" key={index}>
                                                            <div className="upload-data-box-repo-site">
                                                                <h6>
                                                                    {docTypes[0][documentData]}
                                                                    {index !== documentTypes.length - 1 && <span style={{ color: 'red' }}>*</span>}
                                                                </h6>
                                                                <div className="upload-document-btn-box">
                                                                    <button>
                                                                        <span>
                                                                            <PiUploadBold />
                                                                        </span>
                                                                        <input
                                                                            type="file"
                                                                            name="claim_upload_file[]"
                                                                            onChange={(e) =>
                                                                                handleFileChange(e, documentData)
                                                                            }
                                                                            style={{
                                                                                opacity: 0,
                                                                                width: 100,
                                                                                position: "absolute",
                                                                                left: "-8px",
                                                                                top: 3,
                                                                            }}
                                                                            multiple
                                                                        />
                                                                        <p>Upload</p>
                                                                    </button>
                                                                </div>
                                                            </div>



                                                            <div
                                                                className={
                                                                    `${files[documentData]?.length > 2}`
                                                                        ? "uploaded-documents-list-box height-style"
                                                                        : "uploaded-documents-list-box"
                                                                }
                                                            >

                                                                {files[documentData].length > 0 ? (
                                                                    files[documentData].map((file, index) => (
                                                                        <div
                                                                            className="uploaded-documents-list"
                                                                            key={index}
                                                                        >
                                                                            <h6>{file.name}</h6>
                                                                            <button
                                                                                onClick={(e) =>
                                                                                    handleFileDelete(
                                                                                        e,
                                                                                        documentData,
                                                                                        file,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FaRegCircleXmark />
                                                                            </button>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>

                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="submit-btn-wrap">
                                    <button disabled={!allRequiredDocumentsHaveData}>Submit</button>
                                </div>
                            </form>
                        ) : (
                            <div className='no-points'>
                                <p>You dont have the pending points to use the reimbursement facility</p>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {paymentLoading && (
                <div className="payment-loading-page">
                    <OpdLoader />
                </div>
            )}

            {successmodal && (
                <SuccessFull show={modalShow} successMessage={successMessage} animationtype={successmodal} />
            )}

        </div>
    )
}

export default NewOpdHome;