import { createContext, useState } from "react";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const [isVisbile, setIsvisible] = useState(false);
  const [videodetaildata, setVideoDetailData] = useState([])
  const [blogdetaildata, setBlogDetailData] = useState([])
  const imgPath = "https://medibhai.com/uploads/department/";
  const token = localStorage.getItem("ACCESS_TOKEN");
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };

  // My Profile State(My Acount) --------
  const [myAccountData, setmyAccountData] = useState([]);
  const [activeToast, setactiveToast] = useState(null);
  const [brokerId, setBrokerId] = useState((localStorage.getItem("broker_id")) || null)
  const [formShow, setFormShow] = useState(false);

  const handleShow = () => setFormShow(true);
  const handleClose = () => setFormShow(false);



  return (
    <AppContext.Provider
      value={{
        handleShow, handleClose, formShow,
        isVisbile,
        myAccountData, setmyAccountData,
        videodetaildata, setVideoDetailData,
        blogdetaildata, setBlogDetailData, imgPath, token, requestOptions, setBrokerId, brokerId
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default ContextProvider;
