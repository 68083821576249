import { createApiAction } from "./apiActions";

const pincode = localStorage.getItem("pincode")

export const fetchMedicine = () => createApiAction('FETCH_MEDICINE', () => fetch('https://wellness.medibhai.co.in/api/medicine/medicine-category-details'));
export const fetchMedicinelist = (medicineName) => createApiAction('FETCH_MEDICINE_LIST', () => fetch(`https://wellness.medibhai.co.in/api/medicine/medicine-list?pincode=${pincode}&medicine_name=${medicineName}`));
export const fetchSingleMedicinelist = (id, type) => createApiAction('FETCH_SINGLE_MEDICINE_LIST', () => fetch(`https://wellness.medibhai.co.in/api/medicine/medicine-details?sku_id=${id}&type=${type}&pincode=${pincode}`));
export const fetchMedicineRecent = () => createApiAction('FETCH_MEDICINE_RECENT_LIST', () => fetch(`https://wellness.medibhai.co.in/api/medicine/previous-ordered-list?pincode=${pincode}`));
export const fetchMedicineCartList = () => createApiAction('FETCH_MEDICINE_CART_LIST', () => fetch(`https://wellness.medibhai.co.in/api/medicine/medicine-cart-details?pincode=${pincode}`));
export const fetchPrescriptionList = () => createApiAction('FETCH_PRESCRIPTION_LIST', () => fetch(`https://wellness.medibhai.co.in/api/medicine/uploaded-prescriptions-list`));
