import React, { useEffect, useState } from "react";
import {
  ArrowBack,
  appCart,
  appHealthRightArrow,
  appNotification,
  headerLogo,
} from "../../images";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import NoDataFoundComp from "../../components/NoDataFound";
import { HOME_DASHBOARD_SCREEN_API, REQUESTOPTIONS, HOME_INDEX_API, MEDICINE_CART_MANAGER_LIST } from "../../Constants";

const PackageDetailBox = (props) => {
  const { id, user_package_id, title, valid_from, valid_to, desciption } =
    useParams();
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [packagedatadetail, setPackagesDataDetail] = useState([]);
  const [combinedpackagesdatadetail, setCombinedPackagesDataDetail] =
    useState(null);
  const [loadingHomeData, setLoadingHomeData] = useState(true);
  const [homedata, setHomeData] = useState(null);
  const [userinfo, setUserInfo] = useState([]);
  const [packageCode, setPackageCode] = useState([]);
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );

  const [pincodeData, setPincodeData] = useState(
    JSON.parse(localStorage.getItem("pincode")) || ""
  );

  const [medicineCartData, setMedicineCartData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const packageDataRes = await fetch(
          `${process.env.REACT_APP_API_URL}/api/home_index/getPackageDetails?package_id=${id}&user_package_id=${user_package_id}`,
          {
            headers: {
              Authorization: `${props.token ?? token}`,
            },
          }
        );

        if (!packageDataRes.ok) {
          console.error(
            "API error:",
            packageDataRes.status,
            packageDataRes.statusText
          );
          const errorData = await packageDataRes.json().catch(() => null);
          console.error("Error details:", errorData);
          throw new Error("Network response was not ok");
        }

        const packageData = await packageDataRes.json();
        console.log(packageData);
        setPackagesDataDetail(packageData?.data?.data);
        setPackageCode(packageData?.data?.package_data);
        console.log(packageData?.data?.data);
        setCombinedPackagesDataDetail(packageData?.data?.combined_modules);
        console.log(packageData?.data?.combined_modules);
      } catch (error) {
        console.error("Error fetching home data:", error);
      } finally {
        setLoadingHomeData(false);
      }
    };
    const fetchDataUser = async () => {
      try {
        const homeDataRes = await fetch(
          HOME_DASHBOARD_SCREEN_API,
          REQUESTOPTIONS
        );

        if (!homeDataRes.ok) {
          console.error(
            "API error:",
            homeDataRes.status,
            homeDataRes.statusText
          );
          const errorData = await homeDataRes.json().catch(() => null);
          console.error("Error details:", errorData);
          throw new Error("Network response was not ok");
        }

        const homeData = await homeDataRes.json();
        setHomeData(homeData);
        setUserInfo(homeData?.user_info);
      } catch (error) {
        console.error("Error fetching home data:", error);
      } finally {
        setLoadingHomeData(false);
      }
    };
    fetchData();
    fetchDataUser();
  }, []);


  const MangerListDataRepo = async () => {
    try {
      const ManagerListRes = await fetch(
        `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
        {
          headers: {
            Authorization: `${props.token ?? token}`,
          },
          method: "GET",
        }
      );
      const ListDataRes = await ManagerListRes.json();
      setMedicineCartData(ListDataRes?.skus_res)

    } catch (error) {
      console.log(error);

    }
  };

  useEffect(() => {
    MangerListDataRepo();
  }, []);



  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">

          <div className="header-logo convent-repo-box">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p>Health Care Package</p>
            </div>
          </div>
          <div className="icons-box">
            <div className="notification-icon">
              <a href="#">
                <img src={appNotification} width="100%" alt="notification" />
              </a>
            </div>
            <div className="cart-icon">
              <NavLink to="/cart">
                <img src={appCart} width="100%" alt="Cart-icon" />
                {(CartData.length > 0 || medicineCartData.length > 0) && (
                  <span className="Header-count-number">
                    {medicineCartData && medicineCartData?.length + +CartData.length}
                  </span>
                )}
              </NavLink>


            </div>
          </div>
        </div>

        <div>
          <div className="health-care-package-shield-back">
            <div className="health-care-background">
              <div className="health-care-content">
                <p>{title}</p>
                <h5 className="text-center">{userinfo?.fullname}</h5>
              </div>
              <div className="health-care-dates">
                <div className="member-dates">
                  <p>Member Since</p>
                  <span>{valid_from}</span>
                </div>
                <div className="valid-dates">
                  <p>Valid Till</p>
                  <span>{valid_to}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="package-care-details">
            <p>Package Details</p>
          </div>
          <div className="package-pop-desc">
            <p>{desciption}</p>
          </div>
          {loadingHomeData ? (
            <p>
              <Loader />
            </p>
          ) : (
            <div className="package-details-wrapper-boxs">
              {combinedpackagesdatadetail &&
                combinedpackagesdatadetail.length > 0 && (
                  <div className="Combined-data-box">
                    <p className="combined-description">{desciption}</p>
                    {combinedpackagesdatadetail.map((combinedPackageDetail) => (
                      <div
                        className="package-detail-boxs"
                        key={combinedPackageDetail.module_id}
                      >
                        <NavLink
                          to={
                            combinedPackageDetail?.module_id === 1
                              ? "/diagnostictest"
                              : combinedPackageDetail?.module_id === 5
                                ? "/buyMedicines"
                                : combinedPackageDetail?.module_id === 2
                                  ? "/in-personconsultation"
                                  : combinedPackageDetail?.module_id === 4
                                    ? "/nutritionconsultation"
                                    : combinedPackageDetail?.module_id === 9
                                      ? `/ambulanceassistance/${user_package_id}`
                                      : combinedPackageDetail?.module_id === 4
                                        ? "/nutritionconsultation"
                                        : combinedPackageDetail?.module_id === 8
                                          ? "/supertopup"
                                          : combinedPackageDetail?.module_id === 10
                                            ? `/superwatch/${combinedPackageDetail.module_id}/${combinedPackageDetail.package_id}/${combinedPackageDetail.description}`
                                            : combinedPackageDetail?.module_id === 16
                                              ? `/reimbursement?module_id=${combinedPackageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${combinedPackageDetail.title}&description=${combinedPackageDetail.description}`
                                              : combinedPackageDetail?.module_id === 15 ?
                                                `/reimbursement?module_id=${combinedPackageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${combinedPackageDetail.title}&description=${combinedPackageDetail.description}`
                                                : combinedPackageDetail?.module_id === 17 ?
                                                  `/reimbursement?module_id=${combinedPackageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${combinedPackageDetail.title}&description=${combinedPackageDetail.description}`
                                                  : combinedPackageDetail?.module_id === 18 ?
                                                    `/fitnesspro?module_id=${combinedPackageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${combinedPackageDetail.title}&description=${combinedPackageDetail.description}`
                                                    : combinedPackageDetail?.module_id === 19 ?
                                                      `/yoga-session?module_id=${combinedPackageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${combinedPackageDetail.title}&description=${combinedPackageDetail.description}`
                                                      : `${combinedPackageDetail.module_id}`
                          }
                        >
                          <div className="package-detail-box-cover">
                            <div className="package-detail-image-box">
                              <img
                                src={combinedPackageDetail.icon}
                                width="100%"
                                alt="package-icon"
                              />
                            </div>
                            <div className="package-detail-content-box">
                              <h5>{combinedPackageDetail.title}</h5>
                              <hr />
                              <p>{combinedPackageDetail.description}</p>
                            </div>
                            <div className="package-detail-arrow-box">
                              <img
                                src={appHealthRightArrow}
                                width="100%"
                                alt="review"
                              />
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    ))}
                  </div>
                )}
              {packagedatadetail &&
                packagedatadetail.length > 0 &&
                packagedatadetail.map((packageDetail, ind) => (
                  <div
                    className="package-detail-boxs"
                    key={packageDetail.module_id}
                  >
                    <NavLink
                      to={
                        packageDetail?.module_id === 1
                          ? "/diagnostictest"
                          : packageDetail?.module_id === 6
                            ? "/dentalconsultation"
                            : packageDetail?.module_id === 5
                              ? "/buyMedicines"
                              : packageDetail?.module_id === 3
                                ? "/teleconsultation"
                                : packageDetail?.module_id === 4
                                  ? "/nutritionconsultation"
                                  : packageDetail?.module_id === 9
                                    ? `/ambulanceassistance/${user_package_id}`
                                    : packageDetail?.module_id === 4
                                      ? "/nutritionconsultation"
                                      : packageDetail?.module_id === 8
                                        ? "/supertopup"
                                        : packageDetail?.module_id === 10
                                          ? `/superwatch/${packageDetail.module_id}/${packageDetail.package_id}/${packageDetail.description}`
                                          : packageDetail?.module_id === 16
                                            ? `/reimbursement?module_id=${packageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${packageDetail.title}&description=${packageDetail.description}`
                                            : packageDetail?.module_id === 15 ?
                                              `/reimbursement?module_id=${packageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${packageDetail.title}&description=${packageDetail.description}`
                                              : packageDetail?.module_id === 17 ?
                                                `/reimbursement?module_id=${packageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${packageDetail.title}&description=${packageDetail.description}`
                                                : packageDetail?.module_id === 18 ?
                                                  `/fitnesspro?module_id=${packageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${packageDetail.title}&description=${packageDetail.description}`
                                                  : packageDetail?.module_id === 19 ?
                                                    `/yoga-session?module_id=${packageDetail.module_id}&package_id=${user_package_id}&package_code=${packageCode.package_code}&title=${packageDetail.title}&description=${packageDetail.description}`
                                                    : `${packageDetail.module_id}`
                      }
                    >
                      <div className="package-detail-box-cover">
                        <div className="package-detail-image-box">
                          <img
                            src={packageDetail.icon}
                            width="100%"
                            alt="package-icon"
                          />
                        </div>
                        <div className="package-detail-content-box">
                          <h5>{packageDetail.title}</h5>
                          <hr />
                          <p>{packageDetail.description}</p>
                        </div>
                        <div className="package-detail-arrow-box">
                          <img
                            src={appHealthRightArrow}
                            width="100%"
                            alt="review"
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                ))}
              {combinedpackagesdatadetail &&
                combinedpackagesdatadetail.length === 0 &&
                packagedatadetail &&
                packagedatadetail.length === 0 && <NoDataFoundComp />}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PackageDetailBox;
