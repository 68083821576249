import { React, useState, useEffect, useContext, useRef } from "react";
import { ArrowBack, headerLogo, dummyDoctorIcon } from "../../images";
import { ArrowBackButton } from "../../utils/AllFunction";
import { NavLink, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Animation/Loader";
import ErrorServer from "../../components/ErrorServer";
import { REQUESTOPTIONS , DOCTOR_NEAR_ME_API } from "../../Constants";
import DoctorNotFoundForm from "./DoctorNotFoundForm";
import { useLocation } from 'react-router-dom';

const OpdDoctor = () => {
  // const {hospital_name, doctor_name , doc_id} = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const doctor_name = params.get('doctor_name');
  const hospital_name = params.get('hospital_name');
  const doc_id = params.get('doc_id');
  // const [dentalConsultationData, setDentalConsultationData] = useState([]);
  const [DoctorsData, setDoctorsData] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [isErrorData, setisErrorData] = useState(false);
  const [stepWiseDoctorData, setStepWiseDoctorData] = useState([]);
  const [profilepath, setProfilepath] = useState("");
  const [btntext, setBtntext] = useState("View More");
  const targetRef = useRef(null);

  useEffect(() =>{
    console.log(doctor_name , hospital_name , doc_id)
  },[])

  

  useEffect(() => {
    const formData = new FormData();
    formData.append(
      "txt_hospital_name",
      hospital_name === undefined || hospital_name === null ? "" : hospital_name
    );
    formData.append("txt_doctor_name", doctor_name === undefined || doctor_name === null ? "" : doctor_name);
    formData.append("txt_department", doc_id === undefined ||  doc_id === null ? "" : doc_id);

    const requestOptionsCopy = { ...REQUESTOPTIONS };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function GetDoctorNearMe() {
      try {
        const response = await fetch(DOCTOR_NEAR_ME_API, requestOptionsCopy);
        const res = await response.json();
        if (res.status === 200) {
          setProfilepath(res?.profile_path);
          setDataLoading(false);
          if (res?.near_by.length) {
            setDoctorsData(res?.near_by);
            setStepWiseDoctorData([]);
            setStepWiseDoctorData((prevStepWiseDoctorData) => {
              return [...prevStepWiseDoctorData, ...res.near_by.slice(0, 30)];
            });
          }
        }
        else {
          // alert("UnsuccessFull");
          console.log("unsuccessFull");
          setDataLoading(false);
        }
      } catch (error) {
        console.error("error", error);
        setisErrorData(true);
        setDataLoading(false);
      }
    }

    GetDoctorNearMe();
  }, []);

  useEffect(() => {
    if (stepWiseDoctorData.length === DoctorsData.length) {
      setBtntext("Back to Top");
    } else {
      setBtntext("View More");
    }
  }, [stepWiseDoctorData]);

  const HandleDoctorStepData = () => {
    if (stepWiseDoctorData.length == DoctorsData.length) {
      setBtntext("Back to Top");
      targetRef.current.scrollIntoView({ behavior: "smooth" });
      setStepWiseDoctorData(() => {
        return [...DoctorsData.slice(0, 30)];
      });
    } else {
      const startIndex = stepWiseDoctorData.length;
      const endIndex = startIndex + 30;

      const newDoctorData = DoctorsData.slice(startIndex, endIndex);
      setStepWiseDoctorData((prevStepWiseDoctorData) => [
        ...prevStepWiseDoctorData,
        ...newDoctorData,
      ]);
    }
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header d-flex justify-content-start align-items-center">
          <div className="header-logo mx-3">
            <img
              src={headerLogo}
              className="header-medibhai-logo"
              width="100%"
              alt="header-logo"
            />
          </div>
        </div>
        {isErrorData ? (
          <ErrorServer Message={"An Error had Occured...!"}/>
        ) : (
          <div className="dental-consultation-main-wrapper-cover">
            {isDataLoading ? (
              <Loader />
            ) : (
              <>
                <div
                  className="doctor-opd-main-wrapper-cover mt-3"
                  ref={targetRef}
                >
                  {/* {console.log(stepWiseDoctorData)} */}
                  {stepWiseDoctorData.length > 0 ? (
                    stepWiseDoctorData.map((doctorList) => {
                      return (
                        <div className="doctor-list-card mb-3">
                          <div className="row">
                            <div className="col-5 p-0">
                              <div className="doctor-image-box">
                                <img
                                  src={`${profilepath}${doctorList.profile}`}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = dummyDoctorIcon;
                                  }}
                                ></img>
                              </div>
                            </div>
                            <div className="col-7 p-0">
                              <div className="doctor-details-box">
                                <h5>{doctorList?.doctor_name}</h5>
                                <p className="formatted-line">
                                  <span>{doctorList?.designation}</span>
                                </p>
                                {doctorList?.department_name && (
                                  <p className="doctor-department">
                                    Dept ({doctorList?.department_name})
                                  </p>
                                )}

                                <div className="view-profile-btn-box">
                                  <button>
                                    <NavLink
                                      to={`/doctordetails/${doctorList?.id}`}
                                    >
                                      View Profile
                                    </NavLink>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <DoctorNotFoundForm />
                  )}
                </div>
                {stepWiseDoctorData.length >= 30 && (
                  <div className="view-more-btn-box mb-4">
                    <button onClick={() => HandleDoctorStepData()}>
                      {btntext}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default OpdDoctor;
