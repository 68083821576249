import React, { useContext, useEffect, useState } from "react";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { MdArrowRightAlt } from "react-icons/md";
import {
  appBlogImage,
  appCartIcon,
  appNotification,
  appPlayButton,
  headerLogo,
} from "../images";
import "swiper/css";
import { Link, NavLink } from "react-router-dom";
import { AppContext } from "../ContextApi";
import Loader from "../Animation/Loader";
import NoDataFoundComp from "./NoDataFound";
import { WellnessSixModules } from "../data";
import { fetchApi } from "../utils/AllFunction";
import { HOME_DASHBOARD_SCREEN_API, HOME_INDEX_API, MY_ACCOUNT_API, HEALTH_FEED_BLOG_API, MEDICINE_CART_MANAGER_LIST } from "../Constants";

const Home = (props) => {
  const [videobox, setVideoBox] = useState([]);
  const [packagesdata, setPackagesData] = useState([]);
  const [bannerslider, setbannerSlider] = useState([]);
  const [medibhaimodules, setMedibhaiModules] = useState([]);
  const [wellnessmodules, setWellnessModules] = useState([]);
  const [loadingHomeData, setLoadingHomeData] = useState(true);
  const [loadingHomeIndex, setLoadingHomeIndex] = useState(true);
  const [blogdata, setBlogData] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const { setVideoDetailData, setBlogDetailData } = useContext(AppContext);
  const [profileData, setProfileData] = useState([]);
  const [errorData, setErrorData] = useState(false);
  const [isAccountLoading , setAccountLoading] = useState(false);

  
  const [pincodeData, setPincodeData] = useState(
    JSON.parse(localStorage.getItem("pincode")) || ""
  );
  const [cartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || ""
  );

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };
  const MangerListDataRepo = async () => {
    try {
      const ManagerListRes = await fetch(
        `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
        {
          headers: {
            Authorization: `${props.token ?? token}`,
          },
          method: "GET",
        }
      );
      const ListDataRes = await ManagerListRes.json();

      console.log(ListDataRes?.skus_res);
      setResponseData(ListDataRes?.skus_res || []);

      setLoadingHomeData(false);
    } catch (error) {
      console.log(error);
      setLoadingHomeData(false);
    }
  };
  useEffect(() => {
    fetchApi(MY_ACCOUNT_API, requestOptions, setProfileData,setAccountLoading, setErrorData);
    MangerListDataRepo();
  }, [])

  const IMAGE_HEALTH_URL = "https://medibhai.com/uploads/health_feed/"

  useEffect(() => {
    const fetchData = async () => {
      try {
        const homeDataRes = await fetch(
          `${HOME_DASHBOARD_SCREEN_API}`, requestOptions);
        if (!homeDataRes.ok) {
          console.error(
            "API error:",
            homeDataRes.status,
            homeDataRes.statusText
          );
          const errorData = await homeDataRes.json().catch(() => null);
          console.error("Error details:", errorData);
          throw new Error("Network response was not ok");
        }

        const homeData = await homeDataRes.json();
        setbannerSlider(homeData?.Home_slider);
        localStorage.setItem("userInfo", JSON.stringify(homeData?.user_info));
        setMedibhaiModules(WellnessSixModules)
        setWellnessModules(homeData?.home_screen?.wellness_modules);
      } catch (error) {
        console.error("Error fetching home data:", error);
      } finally {
        setLoadingHomeData(false);
      }
    };

    const fetchDataIndex = async () => {
      try {
        const homeIndexRes = await fetch(
          `${HOME_INDEX_API}`, requestOptions);

        if (!homeIndexRes.ok) {
          console.error(
            "API error:",
            homeIndexRes.status,
            homeIndexRes.statusText
          );
          const errorData = await homeIndexRes.json().catch(() => null);
          console.error("Error details:", errorData);
          throw new Error("Network response was not ok");
        }

        const homeIndexData = await homeIndexRes.json();
        console.log(homeIndexData)
        localStorage.setItem(
          "medicineindexdata",
          JSON.stringify(homeIndexData?.default_medicine_search)
        );
        setPackagesData(homeIndexData?.my_package?.packages);
        setVideoBox(homeIndexData?.videos);
        setVideoDetailData(homeIndexData?.videos?.videos);
      } catch (error) {
        console.error("Error fetching home index:", error);
      } finally {
        setLoadingHomeIndex(false);
      }
    };

    const fetchBlogData = async () => {
      try {
        const BlogRes = await fetch(HEALTH_FEED_BLOG_API,
          {
            method: "POST",
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        if (!BlogRes.ok) {
          console.error("API error:", BlogRes.status, BlogRes.statusText);
          const errorData = await BlogRes.json().catch(() => null);
          console.error("Error details:", errorData);
          throw new Error("Network response was not ok");
        }

        const BlogData = await BlogRes.json();
        console.log(BlogData)
        setBlogData(BlogData?.health_feed);
        setBlogDetailData(BlogData?.health_feed);
        // console.log(BlogData?.health_feed)
      } catch (error) {
        console.error("Error fetching home index:", error);
      } finally {
        setLoadingHomeIndex(false);
      }
    };

    fetchData();
    fetchDataIndex();
    fetchBlogData();
  }, []);

  

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p> Welcome {profileData.data?.profile_details?.fullname}</p>
            </div>
          </div>
          <div className="icons-box">
            <div className="notification-icon">
              <NavLink to="/notification">
                <img src={appNotification} width="100%" alt="notification" />
              </NavLink>
            </div>
            <div className="cart-icon">
              <NavLink to="/cart">
                <img src={appCartIcon} width="100%" alt="review" />
                {(responseData && responseData.length > 0) || (cartData && cartData.length > 0) ? (
                  <span>{responseData ? (responseData.length) + (cartData.length) : 0}</span>
                ) : (
                  <></>
                )}
              </NavLink>
            </div>
          </div>
        </div>

        {loadingHomeIndex ? (
          <p>
            <Loader />
          </p>
        ) : (
          <div className="home-app-slider">
            <Swiper
              spaceBetween={0}
              slidesPerView={4}
              loop="true"
              autoplay={{ delay: 1000, disableoninteraction: false }}
              speed={2000}
              effect="slide"
              grabCursor={true}
              centeredSlides={true}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              modules={[Autoplay]}
            >
              <div className="swiper-wrapper">
                {medibhaimodules.map((modulesData) =>
                  modulesData?.show_hide === 0 ? null : (
                    <SwiperSlide
                      className="applabtest-repo"
                      key={modulesData.name}
                    >
                      <NavLink
                        to={`/${modulesData.name === "Teleconsultation"
                          ? (modulesData.name = "home-teleconsultation")
                          : modulesData.name.toLowerCase().replace(/\s/g, "")
                          }`}
                      >
                        <button className="btn-slider-repo">
                          <div className="slider-img">
                            <img
                              src={modulesData.image}
                              width="100%"
                              alt="labtest"
                            />
                          </div>
                          <p>{modulesData.name === "home-teleconsultation" ? modulesData.name = "Teleconsultation" : modulesData.name}</p>
                        </button>
                      </NavLink>
                    </SwiperSlide>
                  )
                )}
              </div>
            </Swiper>
          </div>
        )}

        <div className="home-package-slider">
          <Swiper spaceBetween={10} slidesPerView={1}>
            {loadingHomeData ? (
              <Loader />
            ) : packagesdata && packagesdata.length > 0 ? (
              packagesdata.map((packageBox, inde) => (
                <SwiperSlide key={packageBox.id} className="package-repo-data">
                  <NavLink
                    to={`/packagedetailbox/${packageBox.id}/${packageBox.user_package_id}/${packageBox.title}/${packageBox.valid_from}/${packageBox.valid_to}/${packageBox.desciption}`}
                  >
                    <div
                      className="home-section-banner"
                      style={{ backgroundImage: `url(${packageBox.bg_image})` }}
                    >
                      <div className="container">
                        <div className="home-section-main-banner">
                          <div className="row">
                            <div className="col-lg-8 col-8">
                              <div className="banner-left-side">
                                <div className="left-image-box">
                                  <p>{packageBox.desciption}</p>
                                </div>
                                <div className="konw-more-btn">
                                  <a href="#">
                                    <p>
                                      Know more <MdArrowRightAlt />
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-4 pe-0">
                              <div className="banner-right-side">
                                <div className="package-title">
                                  <p>{packageBox.title}</p>
                                </div>
                                <div className="package-date">
                                  <p>
                                    package Valid Till{" "}
                                    <span>{packageBox.valid_to}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </SwiperSlide>
              ))
            ) : (
              <>

              </>)}
          </Swiper>
        </div>

        <div className="wellness-corner-wrapper">
          <div className="wellness-heading">
            <h5>Wellness Wings</h5>
          </div>
          {loadingHomeIndex ? (
            <p>
              <Loader />
            </p>
          ) : (
            <Swiper
              className="wellness-cornor-slider-repo"
              spaceBetween={-10}
              slidesPerView={3.5}
              loop="true"
              autoplay={{ delay: 2000, disableoninteraction: false }}
              modules={[Autoplay]}
            >
              <div className="swiper-wrapper">
                {wellnessmodules.map((wellnessData) => (
                  <SwiperSlide className="swiper-slider-repo">
                    <NavLink
                      to={`/${wellnessData.name
                        .toLowerCase()
                        .replace(/\s/g, "")}`}
                    >
                      <div className="wellness-box">
                        <div className="corner-heading">
                          <p>{wellnessData.name}</p>
                        </div>
                        <div className="corner-image">
                          <img
                            src={wellnessData.image}
                            width="100%"
                            alt="lifestyle"
                          />
                        </div>
                      </div>
                    </NavLink>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          )}
        </div>

        {loadingHomeData ? (
          <p>
            <Loader />
          </p>
        ) : bannerslider.length > 0 ? (
          <div className="dental-wrapper">
            <Swiper
              spaceBetween={15}
              slidesPerView={1}
              loop="true"
              centeredSlides="true"
            >
              <div className="swiper-wrapper">
                {bannerslider.map((bannerslide) => (
                  <SwiperSlide key={bannerslide.id} className="bannersliderimg">
                    <div className="denatl-box">
                      <img
                        src={bannerslide.slider_image}
                        width="100%"
                        alt="app-banner-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        ) : (
          <NoDataFoundComp />
        )}
        <div className="videos-wrapper">
          <div className="video-box">
            <div className="health-heading">
              <h5>Videos</h5>
            </div>
            <div className="view-all">
              <NavLink to="/videobox">
                <p>View All</p>
              </NavLink>
            </div>
          </div>
          {loadingHomeData ? (
            <p>
              <Loader />
            </p>
          ) : videobox.videos && videobox.videos.length > 0 ? (
            <div className="video-box-cover">
              <Swiper spaceBetween={10} slidesPerView={1.8}>
                <div className="swiper-wrapper">
                  {videobox.videos &&
                    videobox.videos.map((videoData, ind) => (
                      <SwiperSlide className="video-slider-repo">
                        <NavLink to={`/videodetailbox/${ind}`}>
                          <div className="video-content-box">
                            <div className="video-time">
                              <p>{videoData.video_time}</p>
                            </div>
                            <div
                              className="video-image"
                              style={{
                                backgroundImage: `url(${videoData.icon})`,
                              }}
                            >
                              {/* <img src={videoData.icon} width="100%" alt="Video-img" /> */}
                              <div className="video-play">
                                <img
                                  src={appPlayButton}
                                  width="100%"
                                  alt="play-button"
                                />
                              </div>
                            </div>
                            <div className="video-text">
                              <p>{videoData.title}</p>
                            </div>
                          </div>
                        </NavLink>
                      </SwiperSlide>
                    ))}
                </div>
              </Swiper>
            </div>
          ) : (
            <NoDataFoundComp />
          )}
        </div>

        <div className="blog-wrapper">
          <h3>Health Corner</h3>
          <div className="blog-main-cover">
            <Swiper spaceBetween={10} slidesPerView={1.1} loop="true">
              <div className="swiper-wrapper">
                {loadingHomeData ? (
                  <p>
                    <Loader />
                  </p>
                ) : blogdata && blogdata.length > 0 ? (
                  <div className="blog-wrapper">
                    <h3>Health Corner</h3>
                    <div className="blog-main-cover">
                      <Swiper spaceBetween={10} slidesPerView={1.1} loop="true">
                        <div className="swiper-wrapper">
                          {blogdata &&
                            blogdata.map((blogData, index) => (
                              <SwiperSlide className="blogslide-repo">
                                <Link to={`/blogdetail/${index}`}>
                                  <div className="blog-content-box">
                                    <div className="blog-image-box">
                                      <img
                                        src={`${IMAGE_HEALTH_URL}${blogData?.feature_image
                                          }`}
                                        width="100%"
                                        alt="health-care"
                                      />
                                    </div>
                                    <div className="blog-heading-box blog-subject-text">
                                      <p>{blogData.subject}</p>
                                    </div>
                                    <div className="blog-text-box short-desc-repo">
                                      <p>{blogData.short_description}</p>
                                    </div>
                                    <div className="blog-post">
                                      <p>
                                        Posted on{" "}
                                        {
                                          new Date(blogData.created_on)
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </SwiperSlide>
                            ))}
                        </div>
                      </Swiper>
                    </div>
                  </div>
                ) : (
                  <NoDataFoundComp />
                )}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
