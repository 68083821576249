import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FaStar } from "react-icons/fa6";
import { no_preview } from '../Images';
import CartLoader from '../animation/CartLoader';
import useCartFunction from '../hooks/useCartFunction';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';


const Medicine_Recent = ({ mediData }) => {

    const { availability, cartStatus, cart, CartData, DeleteStatus, loadingItem, handleCartAdd, handleIncrement, handleDecrement, handleDelete } = useCartFunction();

    return (
        <>
            <div className='recent-order-repo-box'>
                <Swiper className="mySwiper" slidesPerView={2.1}>
                    {mediData?.data?.map((item, index) => {
                        const cartItem = cart.find(cartItem => cartItem.sku_id === item.sku_id);
                        return (
                            <SwiperSlide key={index}>
                                <div className="recent-product-data-repo-cover">
                                    <div className="image-cover-recent">
                                        <img
                                            src={item?.banner_url || no_preview}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = no_preview;
                                            }}
                                            alt="product-icon"
                                        />
                                    </div>
                                    <p className='recent-ellipsis-text'>{item?.name}</p>
                                    <div className="price-repo-text">
                                        <p>₹{item?.prices?.discounted_price ?? item?.prices?.mrp}</p>
                                        {item?.prices?.discounted_price && <span>₹{item?.prices?.mrp}</span>}
                                    </div>
                                    <div className="offer-recent-text-box">
                                        <p>{item?.prices?.discount}</p>
                                        {item?.average_rating && (
                                            <span><FaStar />{item?.average_rating}</span>
                                        )}
                                    </div>
                                    <div className="line-repo"></div>
                                    <div className="qty-repo-box">
                                        <p>Min. Qty : {item?.min_quantity}</p>
                                    </div>
                                    <div className="add-product-box">
                                        {(loadingItem === item.sku_id && cartStatus === 'loading') ||
                                            (loadingItem === item.sku_id && DeleteStatus === "loading") ? (
                                            <CartLoader />
                                        ) : (
                                            <div className="add-cart-repo-boxs">
                                                {availability[item.sku_id] === 0 || item?.is_available === 0 ? (
                                                    <div className="out-of-stock">
                                                        <p>Out of Stock</p>
                                                    </div>
                                                ) : (cartItem || (item?.in_cart === 1 && item?.is_available === 1 && item?.quantity !== 0)) ? (
                                                    <div className="increase-cart-repo-boxs">
                                                        <button
                                                            className={`incre-btn-reop ${cartItem && cartItem.quantity <= item.min_quantity ? 'border-red' : ''}`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (cartItem && cartItem.quantity <= item.min_quantity) {
                                                                    handleDelete(item.sku_id);
                                                                } else {
                                                                    handleDecrement(item.sku_id);
                                                                }
                                                            }}
                                                        >
                                                            <p>-</p>
                                                        </button>

                                                        {CartData?.data?.map((count, index) => (
                                                            count.sku_id === item.sku_id ? (
                                                                <span key={index}>
                                                                    {count.quantity}
                                                                </span>
                                                            ) : null
                                                        )) || (
                                                                <span>
                                                                    {item?.quantity}
                                                                </span>
                                                            )}
                                                        <button
                                                            className='incre-btn-reop'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (cartItem && cartItem.quantity < item.max_quantity) {
                                                                    handleIncrement(item.sku_id);
                                                                } else {
                                                                    toast.error("Max Reached");
                                                                }
                                                            }}
                                                        >
                                                            <p>+</p>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCartAdd(item);
                                                        }}
                                                    >
                                                        <p>ADD</p>
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </>
    )
}


export default Medicine_Recent