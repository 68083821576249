import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import SuccessFull from "../Animation/SuccessFull";
import SuccessRadiology from "../Animation/SuccessRadiology";
import { STATE_CITY_LIST_API , REQUESTOPTIONS } from "../Constants";

const RadiologyModal = (props) => {
  const [locationList, setLocationList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState(""); // New state for selected city
  const [apiData, setApiData] = useState(null);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [successmodal, setSuccessModal] = useState(false);
  const navigate = useNavigate();
  const [modalShown, setModalShown] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);


  useEffect(() => {
    if (modalShown) {
      const fetchData = async () => {
        try {
          const response = await fetch(STATE_CITY_LIST_API ,REQUESTOPTIONS);
          const fetchedData = await response.json();

          setLocationList(fetchedData.locations);
          setApiData(fetchedData);
          setStateList(fetchedData?.state_list);
          setCityList(fetchedData?.city_list[fetchedData.state_list[0]]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [modalShown, token]);

  const handleModalShow = () => {
    setModalShown(true);
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    setCityList(apiData.city_list[selectedState]);
    setSelectedCity("");
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setSelectedCity(selectedCity);
  };

  const handleModalHide = () => {
    setModalShown(false);
  };

  const handleSubmit = () => {
    props.onHide();
    setSuccessModal(true);
    setModalShow(true);
    setTimeout(() => {
      setSuccessModal(false);
      setModalShow(false)
      navigate("/radiology-box");
    }, 3000);
  
    const selectedData = {
      selectedState: selectedState,
      selectedCity: selectedCity,
    };
  
    // Save selected data in localStorage
    localStorage.setItem("selectedRadiologyData", JSON.stringify(selectedData));
  
    handleModalHide();
  };
  

  return (
    <>
      <Modal
        {...props}
        centered
        show={props.show}
        onHide={props.onHide}
        onShow={handleModalShow}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modal-heading-text"
          >
            Select State and City
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-box-content">
            <Form.Select
              aria-label="Default select example"
              className="radio-form"
              value={selectedState}
              onChange={handleStateChange}
            >
              <option>Select State</option>
              {stateList.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Form.Select>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="radio-form"
              value={selectedCity}
              onChange={handleCityChange}
              disabled={!selectedState}
            >
              <option>Select City</option>
              {cityList
                ? cityList.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))
                : null}
            </Form.Select>
            <button onClick={handleSubmit}>Submit</button>
          </div>
        </Modal.Body>
      </Modal>
      {successmodal && <SuccessRadiology show={modalShow}/>}
    </>
  );
};

export default RadiologyModal;
