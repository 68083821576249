import { useState } from 'react'
import './prescription_loader.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const PresciptionLoader = ({ showModal }) => {

    return (
        <>
            <Modal
                show={showModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <div className='presc-input-load'>
                        <div class="dot-spinner">
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                        </div>
                        <p>Please Wait...</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}


export default PresciptionLoader