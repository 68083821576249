import React from 'react';
import { Cart_Icon, Header_Background } from '../Images';
import LocationSection from '../../LocationSection/LocationSection';
import '../Medicine_Style/header.css'
import Medicine_Location from './Medicine_Location';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";

const Medicine_Header = ({ Title, state }) => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(`/${state.Link}`)
    }
    return (
        <div className="medicine-header sticky-lg-top sticky-md-top sticky-top">
            <div className="images-box" style={{ backgroundImage: `url(${Header_Background})` }}>
                <div className="header-box-repo-data">
                    {Title && Title !== undefined ? (
                        <div className='data-box-repo-store'>
                            {state?.Title === 'Search Medicines' && (
                                <button onClick={handleBack}><FaChevronLeft /></button>
                            )}
                            <div className="header-title">
                                <h6>{Title}</h6>
                            </div>
                        </div>
                    ) : (
                        <Medicine_Location />
                    )}
                    {state?.Title !== 'Search Medicines' && (
                        <div className="cart-icon-repo">
                            <img src={Cart_Icon} alt="cart-icon" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Medicine_Header;
