import React from 'react'
import { useNavigate } from 'react-router-dom'

const Medicine_Search = () => {
    const navigate = useNavigate();
    const handleSearch = () => {
        const dataState = {
            Title: "Search Medicines",
            Link: "medicines"
        }
        navigate('/search-medicine', { state: dataState })
    }
    return (
        <div className='search-data-input-box'>
            <input type="text" placeholder='Search your medicine...' onClick={handleSearch} />
        </div>
    )
}

export default Medicine_Search