import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowBackButton } from "../utils/AllFunction";
import { ArrowBack, headerLogo } from "../images";
import { Controller, useForm } from "react-hook-form";
import SuccessFull from "../Animation/SuccessFull";
import { MEDICAL_LOAN_API } from "../Constants";
const token = localStorage.getItem("ACCESS_TOKEN");

const MedicalLoan = () => {
  const { handleSubmit, control, formState } = useForm();
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();

  const onSubmitForm = async (data) => {
    const formData = new FormData();
    formData.append("fullname", data.fullname);
    formData.append("procedure", data.procedure);
    formData.append("mobile", data.mobile);
    formData.append("date_of_hospitalization", data.date_of_hospitalization);
    formData.append("email", data.email);
    formData.append("credit_history", data.credit_history);
    formData.append("gender", data.gender);
    formData.append("loan_amt", data.loan_amt);
    formData.append("city", data.city);
    formData.append("loan_amt", data.loan_amt);
    formData.append("aadhar_number", data.aadhar_number);
    formData.append("hospital_name", data.hospital_name);
    formData.append("pancard_no", data.pancard_no);


    try {
      const wellnessData = await fetch(MEDICAL_LOAN_API,
        {
          method: "POST",
          headers: {
            Authorization: `${token}`,
          },
          body: formData,
        }
      );

      const responseData = await wellnessData.json();

      if (!wellnessData.ok) {
        console.error(
          "API error:",
          wellnessData.status,
          wellnessData.statusText
        );
        const errorData = await wellnessData.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      if (responseData.status === true) {
        setSuccessModal(true);
        setSuccessMessage(responseData.message);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setSuccessModal(false);
          navigate("/home");
        }, 3000);
      } else {
        alert("Unsuccessful submission");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div class="home-review-banner">
        <div className="home-app-header nutrition-repo-box">
        
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p>Medical Loan</p>
            </div>
          </div>
        </div>

        <div className="medical-loan-form-section">
          <div className="medical-loan-form-heading">
            <p>Share your basic information</p>
          </div>

          <div className="medical-form-box">
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <Controller
                name="fullname"
                control={control}
                defaultValue=""
                rules={{ required: "Please enter your name" }}
                render={({ field }) => (
                  <>
                    <label>Name</label>
                    <input {...field} placeholder="Full Name" />
                    {formState.errors.fullname && (
                      <p>{formState.errors.fullname.message}</p>
                    )}
                  </>
                )}
              />
              <Controller
                name="procedure"
                control={control}
                defaultValue=""
                rules={{ required: "please select the options" }}
                render={({ field }) => (
                  <>
                    <label>Procedure</label>
                    <select {...field} name="procedure" id="procedure">
                      <option value="" disabled>
                        Select Procedure
                      </option>
                      <option value="respiratory">Respiratory</option>
                      <option value="livers">Livers</option>
                      <option value="other">Other Problems</option>
                      <option value="kidney">Kidney</option>
                      <option value="heart">Heart</option>
                    </select>
                    {formState.errors.procedure && (
                      <p>{formState.errors.procedure.message}</p>
                    )}
                  </>
                )}
              />

              <Controller
                name="mobile"
                control={control}
                defaultValue=""
                rules={{
                  required: "please enter mobile number",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter only numbers",
                  },
                }}
                render={({ field }) => (
                  <>
                    <label>Mobile</label>
                    <input
                      {...field}
                      type="text"
                      maxLength={10}
                      placeholder="Mobile Number"
                    />
                    {formState.errors.mobile && (
                      <p>{formState.errors.mobile.message}</p>
                    )}
                  </>
                )}
              />

              <Controller
                name="date_of_hospitalization"
                control={control}
                defaultValue=""
                rules={{ required: "Please select  date" }}
                render={({ field }) => (
                  <>
                    <label>Date of Hospitalization</label>
                    <input
                      {...field}
                      type="date"
                      placeholder="Enter your Date"
                    />
                    {formState.errors.date_of_hospitalization && (
                      <p>{formState.errors.date_of_hospitalization.message}</p>
                    )}
                  </>
                )}
              />

              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: "please enter Email address" }}
                render={({ field }) => (
                  <>
                    <label>Email</label>
                    <input
                      {...field}
                      type="email"
                      placeholder="Enter Email"
                    />
                    {formState.errors.email && (
                      <p>{formState.errors.email.message}</p>
                    )}
                  </>
                )}
              />

              <Controller
                name="credit_history"
                control={control}
                defaultValue=""
                rules={{ required: "please select the options" }}
                render={({ field }) => (
                  <>
                    <label>Credit History</label>
                    <select {...field} name="credit" id="credit">
                      <option value>Select Credit History</option>
                      <option value="active-creadit">
                        I have an active credit card
                      </option>
                      <option value="loan">I have an active loan</option>
                      <option value="loan1">
                        I have an active credit card and a loan
                      </option>
                      <option value="laon2">
                        I don't have an active credit card and a loan
                      </option>
                    </select>
                    {formState.errors.credit_history && (
                      <p>{formState.errors.credit_history.message}</p>
                    )}
                  </>
                )}
              />

              <Controller
                name="gender"
                control={control}
                defaultValue=""
                rules={{ required: "please select the options" }}
                render={({ field }) => (
                  <>
                    <label>Gender</label>
                    <select {...field} name="gender" id="gender">
                      <option value>Select Gender</option>
                      <option value="male">Male</option>
                      <option value="femal">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {formState.errors.gender && (
                      <p>{formState.errors.gender.message}</p>
                    )}
                  </>
                )}
              />

              <Controller
                name="loan_amt"
                control={control}
                defaultValue=""
                rules={{
                  required: "please enter your loan amount",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter only numbers",
                  },
                }}
                render={({ field }) => (
                  <>
                    <label>Laon Amount</label>
                    <input {...field} type="number" placeholder="Loan Amount" />
                    {formState.errors.loan_amt && (
                      <p>{formState.errors.loan_amt.message}</p>
                    )}
                  </>
                )}
              />
              <Controller
                name="city"
                control={control}
                defaultValue=""
                rules={{ required: "please enter city" }}
                render={({ field }) => (
                  <>
                    <label>City</label>
                    <input {...field} type="text" placeholder="Enter City" />
                    {formState.errors.city && (
                      <p>{formState.errors.city.message}</p>
                    )}
                  </>
                )}
              />

              <Controller
                name="aadhar_number"
                control={control}
                defaultValue=""
                rules={{
                  required: "please enter your aadhar number",
                  pattern: {
                    value: /^[0-9]{12}$/,
                    message: "Please enter a valid Aadhar Number",
                  },
                }}
                render={({ field }) => (
                  <>
                    <label>Aadhar</label>
                    <input
                      {...field}
                      type="text"
                      maxLength={12}
                      placeholder="Enter Aadhar"
                    />
                    {formState.errors.aadhar_number && (
                      <p>{formState.errors.aadhar_number.message}</p>
                    )}
                  </>
                )}
              />

              <Controller
                name="hospital_name"
                control={control}
                defaultValue=""
                rules={{ required: "please enter your hospital name" }}
                render={({ field }) => (
                  <>
                    <label>Hospital Name</label>
                    <input {...field} type="text" placeholder="Hospital Name" />
                    {formState.errors.hospital_name && (
                      <p>{formState.errors.hospital_name.message}</p>
                    )}
                  </>
                )}
              />

              <Controller
                name="pancard_no"
                control={control}
                defaultValue=""
                rules={{
                  required: "please enter your pancard number",
                  pattern: {
                    value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                    message: "Please Enter Valid Pan Number",
                  },
                }}
                render={({ field }) => (
                  <>
                    <label>Pan Card Number</label>
                    <input
                      {...field}
                      type="text"
                      maxLength={10}
                      placeholder="Pan Card Number"
                    />
                    {formState.errors.pancard_no && (
                      <p>{formState.errors.pancard_no.message}</p>
                    )}
                  </>
                )}
              />

              <button type="submit">Submit</button>
            </form>
          </div>

          {successmodal && (
            <SuccessFull show={modalShow} successMessage={successMessage} />
          )}
        </div>
      </div>
    </>
  );
};

export default MedicalLoan;
