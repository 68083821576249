import { React, useEffect, useState, useContext, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  headerLogo,
  physician_icon,
  ArrowBack,
  WorkPointIcon1,
  WorkPointIcon2,
  WorkPointIcon3,
  WorkPointIcon4,
  WorkPointIcon5,
  howitworknum1,
  howitworknum2,
  howitworknum3,
  howitworknum4,
  howitworknum5,
} from "../../../images";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  PACKAGES_API,
  REQUESTOPTIONS,
  TELECONSULTATION_LIST_API
} from "../../../Constants";
import { fetchApi } from "../../../utils/AllFunction";
import { TbChevronRight } from "react-icons/tb";
import SpecialityProduct from "../Components/SpecialityProduct";
import Loader from "../../../Animation/Loader";
import NoDataFoundComp from "../../../components/NoDataFound";
import SymptomsCard from "../Components/SymptomsCard";
import RadiologySection from "../../LocationSection/RadiologySection";
import TeleconsultationSection from "../../LocationSection/TeleconsultationSection";
import WaitLoader from "../../../Animation/WaitLoader";


const HomeTeleConsultation = (props) => {
  const navigate = useNavigate();
  const teleconsultRef = useRef();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dep_id = queryParams.get("dept_id");
  const v2href = queryParams.get("href");
  const [packageList, setPackageList] = useState([]);
  const [isErrorData, setErrorData] = useState([]);
  const [teleconsultationData, setTeleConsultationData] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };

  const rehit = location.state;

  const PackageId = JSON.parse(localStorage.getItem("PackageSelectData"));

  const fetchData = () => {
    const formData = new FormData();
    if (PackageId) {
      formData.append("user_package_id", PackageId.id);
    }
    else {
      formData.append("user_package_id", 0)
    }

    const postRequestOptions = {
      method: "POST",
      headers: {
        Authorization: `${props.token ?? token}`,
      },
      body: formData,
    };

    fetchApi(
      TELECONSULTATION_LIST_API,
      postRequestOptions,
      setTeleConsultationData,
      setDataLoading,
      setErrorData
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(PACKAGES_API, REQUESTOPTIONS);
        const fetchedData = await response.json();
        setPackageList(fetchedData?.user_package)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    if (rehit !== null && rehit !== undefined && rehit.rehit) {
      fetchData();
    }
  }, [rehit]);


  const handleBooking = (data, type, mbConsulttype) => {
    localStorage.setItem("mbConsultType", mbConsulttype)
    localStorage.setItem("BOOKED_TELECONSULTATION_DATA", JSON.stringify(data));
    if (type !== "") {
      setTimeout(() => {
        if (mbConsulttype == "symptoms") {
          navigate(`/book-teleconsultation?type=symptoms`);
        } else {
          navigate(`/book-teleconsultation?type=${type}`);
        }
      }, 500
      )
    }
    else {
      setTimeout(() => {
        navigate("/book-teleconsultation");
      }, 500
      )
    }
  };

  useEffect(() => {

    if (dep_id && teleconsultationData?.tele_departments?.teleconsultaton && teleconsultationData?.tele_departments?.teleconsultaton !== undefined) {
      const matchedObject = teleconsultationData.tele_departments.teleconsultaton.find(
        item => item?.dept_id == dep_id);

      if (matchedObject) {
        const type = matchedObject?.is_free;
        localStorage.setItem('BOOKED_TELECONSULTATION_DATA', JSON.stringify(matchedObject));
        navigate(`/book-teleconsultation?type=${type}`)
      }
    }
  }, [teleconsultationData, dep_id]);

  // useEffect(() => {
  //   if (dep_id != 12) {
  //     const data = {
  //       dept_id: 10,
  //       department_name: "Dietitian",
  //       department_image: "nutrition-and-dietetics_2024-01-30-16-51-26.png",
  //       department_descriptoon:
  //         "Diet and nutrition counseling and coaching for healthy eating, weight management, and chronic illness dietary management.",
  //       doctor_id: 13,
  //       doctor_name: "Dr Reshma",
  //       doctor_mobile: 9819412221,
  //       doctor_email: "info@medibhai.com",
  //       cosult_charge: 249, // Corrected property name
  //       created_on: "2024-01-30 16:51:26",
  //       is_free: 1,
  //       min_date_time: "2024-03-24",
  //       max_date_time: "2024-03-30",
  //     };
  //     localStorage.setItem("BOOKED_TELECONSULTATION_DATA", JSON.stringify(data));

  //     if (props.isV2token && dep_id !== null) {
  //       setTimeout(() => {
  //         navigate(
  //           `/book-teleconsultation?v2Token=${props.isV2token}&href=${v2href}&dept_id=${dep_id}`
  //         );
  //       }, 500);
  //     } else {
  //       console.log("NOt Found");
  //     }
  //   }
  // }, [dep_id]);

  const handleNavigation = () => {
    props.token ? window.history.go(-2) : window.history.back();
  };

  useEffect(() => {
    if (teleconsultRef.current && teleconsultationData) {
      teleconsultRef.current.style.backgroundImage = `url(https://medibhai.com/assets/images/medibhai_images/${teleconsultationData?.tele_departments?.gp_banner})`;
    }
  }, [teleconsultationData]);

  return (
    <>
      <div className="home-review-banner mb-4">
        <div className="home-app-header d-flex justify-content-start align-items-center">

          <div className="tele-data-wrapper">
            <div className="header-logo tele-logo mr-3">
              <img
                src={headerLogo}
                className="header-medibhai-logo"
                width="100%"
                alt="header-logo"
              />
            </div>
            <div className="home-header-teleconsultation d-flex justify-content-between align-items-center">
              <div className="header-navigation-icon head-repo-bor">
                <h6 className="mb-0">Teleconsultation</h6>
                <TeleconsultationSection packageList={packageList} />
              </div>
            </div>
          </div>
        </div>

        {isDataLoading ? (
          <WaitLoader />
        ) : (
          <>
            <div
              className="home-teleconsultation-free-teleconsultation-cover"
              ref={teleconsultRef}
              onClick={() =>
                handleBooking(
                  teleconsultationData?.tele_departments
                    ?.free_teleconsultation[0],
                  teleconsultationData?.tele_departments?.free_teleconsultation[0].is_free == 1 ? "1" : "0",
                  "speciality"
                )
              }
            >
              <div className="free-consultation d-flex align-items-center">
                <div className="consultation-icon-box">
                  <img src={physician_icon} alt="physician icon"></img>
                </div>
                <div className="consultation-content-box">
                  <h5>
                    {
                      teleconsultationData?.tele_departments
                        ?.free_teleconsultation[0]?.department_name
                    }
                  </h5>
                  <p>Consultation</p>
                </div>
              </div>
            </div>
            {/* {teleconsultationData?.tele_departments?.free_teleconsultation[0].is_free == 1 && (
        )} */}

            <div className="home-teleconsultation-main-wrapper mt-4 px-2">
              <div className="specialist-doctor-home-wrapper">
                <div className="specialist-header d-flex justify-content-between align-items-center">
                  <h6 className="mb-0">Specialist Doctors</h6>
                  <NavLink
                    className="view-all-specialist"
                    to="/specialist-teleconsultation"
                  >
                    <button>
                      View All{" "}
                      <span>
                        <TbChevronRight />
                      </span>
                    </button>
                  </NavLink>
                </div>
                <div className="specialist-product-main-wrapper mt-3 px-1">
                  <div className="specialist-product-box d-flex justify-content-between">
                    {teleconsultationData?.tele_departments?.teleconsultaton
                      ?.length > 0 ? (
                      teleconsultationData?.tele_departments?.teleconsultaton
                        .slice(0, 4)
                        .map((specialityData) => {
                          return <SpecialityProduct data={specialityData} imgPath={teleconsultationData?.img_path} handleBooking={() => handleBooking(specialityData, specialityData?.is_free, "speciality")} />;
                        })
                    ) : (
                      <NoDataFoundComp />
                    )}
                  </div>
                </div>
              </div>
              <div className="home-teleconsultation-banner-wrapper">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1.2}
                  loop="true"
                  //   autoplay={{ delay: 1000, disableoninteraction: false }}
                  speed={2000}
                  effect="slide"
                  grabCursor={true}
                  slidesOffsetBefore={0}
                  slidesOffsetAfter={0}
                //   modules={[Autoplay]}
                >
                  <div className="swiper-wrapper">
                    {teleconsultationData?.tele_departments?.teleconsultation_banners.map(
                      (tele_banners) => {
                        // Determine if the teleconsultation is free
                        const isFree = teleconsultationData?.tele_departments?.free_teleconsultation[0]?.is_free === 1 ? "1" : "0";

                        // Generate the redirect URL based on conditions
                        const redirectUrl = tele_banners.id === 1
                          ? "/specialist-teleconsultation"
                          : tele_banners.id === 2
                            ? `/book-teleconsultation?type=${isFree}`
                            : '';

                        return (
                          <SwiperSlide
                            className="applabtest-repo"
                            key={tele_banners.id}
                          >
                            <NavLink to={redirectUrl}>
                              <div className="teleconsultation-banner-box">
                                <img src={tele_banners.image} alt="Teleconsultation Banner" />
                              </div>
                            </NavLink>
                          </SwiperSlide>
                        );
                      }
                    )}

                  </div>
                </Swiper>
              </div>
            </div>
            <div className="symptoms-main-wrapper-cover px-3 mt-3">
              <div className="symptoms-header mb-2">
                <h6>Symptoms</h6>
              </div>
              <div className="symptoms-product-main-wrapper d-flex justify-content-between">
                <div className="specialist-product-box d-flex justify-content-between">
                  {teleconsultationData?.tele_departments?.symptoms?.length >
                    0 ? (
                    teleconsultationData?.tele_departments?.symptoms
                      .slice(0, 3)
                      .map((symptomsData) => {
                        return (
                          <SymptomsCard
                            data={symptomsData}
                            imgPath={teleconsultationData?.img_path}
                            handleBooking={() => handleBooking(symptomsData, 0, "symptoms")}
                          />
                        );
                      })
                  ) : (
                    <NoDataFoundComp />
                  )}
                </div>
              </div>
              <div className="symptoms-footer d-flex justify-content-center mt-3">
                <NavLink
                  className="view-all-specialist"
                  to="/symptoms-teleconsultation"
                >
                  <button className="symptoms-view-button">
                    View All{" "}
                    <span>
                      <TbChevronRight />
                    </span>
                  </button>
                </NavLink>
              </div>
              <div className="how-it-works-main-wrapper mt-4">
                <div className="how-it-works-main-content">
                  <div className="how-it-works-header">
                    <h6>How It Works?</h6>
                    <p>
                      We aim to extend our doctor consultation services to remote
                      location via Teleconsultation.
                    </p>
                  </div>
                  <div className="how-it-works-points-box">
                    <div className="how-it-works-point my-3">
                      <div className="title-detail-box d-flex align-items-center">
                        <img src={WorkPointIcon1} alt="how-it-works-icon"></img>
                        <div className="title-content">
                          <h6>Select Specility</h6>
                          <p>Select the specialty or symptoms</p>
                        </div>
                      </div>
                      <img
                        src={howitworknum1}
                        className="numbering-img"
                        alt="WorkPointIcon"
                      ></img>
                    </div>
                    <div className="how-it-works-point my-3">
                      <div className="title-detail-box d-flex align-items-center">
                        <img src={WorkPointIcon2} alt="how-it-works-icon"></img>
                        <div className="title-content">
                          <h6>Share Details</h6>
                          <p>Share the patient name and make the payment</p>
                        </div>
                      </div>
                      <img
                        src={howitworknum2}
                        className="numbering-img"
                        alt="WorkPointIcon"
                      ></img>
                    </div>
                    <div className="how-it-works-point my-3">
                      <div className="title-detail-box d-flex align-items-center">
                        <img src={WorkPointIcon3} alt="how-it-works-icon"></img>
                        <div className="title-content">
                          <h6>Receive Link</h6>
                          <p>You will receive a link for Video consultation</p>
                        </div>
                      </div>
                      <img
                        src={howitworknum3}
                        className="numbering-img"
                        alt="WorkPointIcon"
                      ></img>
                    </div>
                    <div className="how-it-works-point my-3">
                      <div className="title-detail-box d-flex align-items-center">
                        <img src={WorkPointIcon4} alt="how-it-works-icon"></img>
                        <div className="title-content">
                          <h6>Join Consultation</h6>
                          <p>Join the link and consult with specialist Doctor</p>
                        </div>
                      </div>
                      <img
                        src={howitworknum4}
                        className="numbering-img"
                        alt="WorkPointIcon"
                      ></img>
                    </div>
                    <div className="how-it-works-point my-3">
                      <div className="title-detail-box d-flex align-items-center">
                        <img src={WorkPointIcon5} alt="how-it-works-icon"></img>
                        <div className="title-content">
                          <h6>Get Prescription</h6>
                          <p>
                            You will receive the signed prescription soon after the
                            call
                          </p>
                        </div>
                      </div>
                      <img
                        src={howitworknum5}
                        className="numbering-img"
                        alt="WorkPointIcon"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      </div>
    </>
  );
};

export default HomeTeleConsultation;
