import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackButton } from '../../utils/AllFunction';
import { ArrowBack } from '../../images';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../ContextApi';

const VideoDetailBox = () => {
    const { videodetaildata, setVideoDetailData } = useContext(AppContext);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!videodetaildata || !videodetaildata[id]) {
            navigate('/home');
        }
    }, [id, navigate, videodetaildata]);

    const video = videodetaildata && videodetaildata[id];
    return (
        <>
            <div className="home-review-banner">
                <div className="app-header-wraper video-box-repo">
                    <div className="header-top-section">
                        <div className="header-top-section-category-section header-text-content-p">
                            <h3>{video?.title}</h3>
                        </div>
                        <div className="header-top-section-add-to-cart-section">
                        </div>
                    </div>
                </div>
                <div className="video-deatil-wrapper">
                    <div className="video-detail-box">
                        <div className="video-box-repo-content">
                            {video && (
                                <video width="100%" controls disablepictureinpicture controlslist="nodownload noplaybackrate">
                                    <source src={video.url} type="video/mp4" />
                                </video>
                            )}
                        </div>
                        <div className="video-box-text-content">
                            <p>{video.title}</p>
                        </div>
                        <hr />
                        <div className="video-box-description-content">
                        <p>Description</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoDetailBox