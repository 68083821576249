import { Modal } from 'react-bootstrap';
import React, { useState } from 'react'
import { appAssistance, appConductTest, appCovidSupport, appDignosticsupport, appInsuranceClaimSupport, appKnowMore, appOtherSupport, appPathlogy, appRadiologysupport, appTalkDoctor, hospitalsupport, teleconsultationsupport } from '../../../images';
import SuccessFull from '../../../Animation/SuccessFull';

const HelpModal = ({showModal,setShowModal}) => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const [activeButton, setActiveButton] = useState(null);
    const [selectdata, setSelectData] = useState(null);
    const [successmodal, setSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [profileData, setProfileData] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);

    const handleButtonClick = (buttonIndex, buttonData) => {
        setActiveButton(activeButton === buttonIndex ? null : buttonIndex);
        setSelectData(activeButton === buttonData ? null : buttonData);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const onSubmit = async () => {
        const formData = new FormData();
        formData.append(
            "support_request_no",
            profileData.data?.profile_details?.mobile
        );
        formData.append("support_request_type", selectdata);


        try {
            const wellnessData = await fetch(
                `${process.env.REACT_APP_API_URL}/general/request_support`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `${token}`,
                    },
                    body: formData,
                }
            );

            const responseData = await wellnessData.json();

            if (!wellnessData.ok) {
                console.error(
                    "API error:",
                    wellnessData.status,
                    wellnessData.statusText
                );
                const errorData = await wellnessData.json().catch(() => null);
                console.error("Error details:", errorData);
                throw new Error("Network response was not ok");
            }

            if (responseData.status === 200) {
                setSuccessModal(true);
                setSuccessMessage(responseData.message);
                setModalShow(true);
                setTimeout(() => {
                    setModalShow(false);
                    setSuccessModal(false);
                    setShowModal(false);
                }, 3000);
            } else {
                alert("Unsuccessful submission");
            }
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div>
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="content-support-repo"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="Reach-repo">Reach Us</h5>
                        <p className="option-repo">
                            Please select you options and click on submit
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body classname="modal-covid-repo">
                    <div className="Reach-us-cover-section">
                        <div className="reach-us-image-box">
                            <img src={appCovidSupport} alt="covid-test" />
                            <p>Covid 19 Support</p>
                        </div>
                        <div className="reach-box-section">
                            <button
                                className={`support-btn ${activeButton === 1 ? "active" : ""}`}
                                onClick={() => handleButtonClick(1, "Talk to Doctor")}
                            >
                                <div className="reach-box-1">
                                    <img src={appTalkDoctor} alt="talk-doctor" />
                                    <p>Talk to Doctor</p>
                                </div>
                            </button>
                            <button
                                className={`support-btn ${activeButton === 2 ? "active" : ""}`}
                                onClick={() => handleButtonClick(2, "Conduct Test")}
                            >
                                <div className="reach-box-2">
                                    <img src={appConductTest} alt="conduct-test" />
                                    <p>Conduct Test</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="Reach-us-cover-section">
                        <div className="reach-us-image-box">
                            <img src={appDignosticsupport} alt="diagnostics" />
                            <p>Diagnostic</p>
                        </div>
                        <div className="reach-box-section">
                            <button
                                className={`support-btn ${activeButton === 3 ? "active" : ""}`}
                                onClick={() => handleButtonClick(3, "Pathology")}
                            >
                                <div className="reach-box-1">
                                    <img src={appPathlogy} alt="pathlogy" />
                                    <p>Pathology</p>
                                </div>
                            </button>
                            <button
                                className={`support-btn ${activeButton === 4 ? "active" : ""}`}
                                onClick={() => handleButtonClick(4, "Radiology")}
                            >
                                <div className="reach-box-2">
                                    <img src={appRadiologysupport} alt="Radiology" />
                                    <p>Radiology</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="Reach-us-cover-section">
                        <div className="reach-us-image-box">
                            <img src={appKnowMore} alt="know-more" />
                            <p>Know more about</p>
                        </div>
                        <div className="reach-box-section">
                            <button
                                className={`support-btn ${activeButton === 5 ? "active" : ""}`}
                                onClick={() => handleButtonClick(5, "Hospital")}
                            >
                                <div className="reach-box-1">
                                    <img src={hospitalsupport} alt="hospital-support" />
                                    <p>Hospital</p>
                                </div>
                            </button>
                            <button
                                className={`support-btn ${activeButton === 6 ? "active" : ""}`}
                                onClick={() => handleButtonClick(6, "Teleconsultation")}
                            >
                                <div className="reach-box-2">
                                    <img src={teleconsultationsupport} alt="teleconsultation" />
                                    <p>Teleconsultation</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="Reach-us-cover-section">
                        <div className="reach-us-image-box">
                            <img src={appAssistance} alt="Assistance" />
                            <p>Assistance in</p>
                        </div>
                        <div className="reach-box-section">
                            <button
                                className={`support-btn ${activeButton === 7 ? "active" : ""}`}
                                onClick={() => handleButtonClick(7, "Insurance Claim")}
                            >
                                <div className="reach-box-1">
                                    <img src={appInsuranceClaimSupport} alt="claim-support" />
                                    <p>Insurance Claim</p>
                                </div>
                            </button>
                            <button
                                className={`support-btn ${activeButton === 8 ? "active" : ""}`}
                                onClick={() => handleButtonClick(8, "Others")}
                            >
                                <div className="reach-box-2">
                                    <img src={appOtherSupport} alt="Others" />
                                    <p>Others</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="reach-submit-btn">
                        <button onClick={onSubmit}>
                            <p>Submit</p>
                        </button>
                    </div>
                </Modal.Body>
                {successmodal && (
                    <SuccessFull show={modalShow} successMessage={successMessage} />
                )}
            </Modal>
        </div>
    )
}

export default HelpModal