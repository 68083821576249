export const createApiAction = (actionType, apiCall, options = {}) => {
    return async (dispatch) => {
        dispatch({ type: `${actionType}_REQUEST` });
        try {
            const response = await apiCall(options);
            const data = await response.json();
            dispatch({ type: `${actionType}_SUCCESS`, payload: data });
            setTimeout(() => {
                dispatch({ type: `${actionType}_IDLE` });
            }, 500);
        } catch (error) {
            dispatch({ type: `${actionType}_FAILURE`, payload: error.message });
        }
    };
};