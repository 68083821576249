import React from "react";

const PaymentLoader = () => {
  return (
    <>
      <div className="payment-main-loader-wrapper">
      <div class="loader">
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
      </div>
      <h6 class="text-center mt-3">Confirming Your Order Please Wait...</h6>
      </div>

    </>
  );
};

export default PaymentLoader;
