import { thunk } from 'redux-thunk';
import { deletePrescriptionReducer, medicineCartData, medicineCartDelete, medicineCartDetailsReducer, medicineListReducer, medicineRecentListReducer, medicineReducer, prescriptionListReducer, singleMedicineReducer, uploadPrescriptionReducer } from '../MultipleReducer/multipleReducer';
import { applyMiddleware, combineReducers, createStore } from 'redux';

// Import other reducers

const rootReducer = combineReducers({

    // GET APIS REDUCERS
    medicine: medicineReducer,
    medicine_list: medicineListReducer,
    medicine_recent_list: medicineRecentListReducer,
    prescription_list: prescriptionListReducer,
    medicine_cart_list: medicineCartDetailsReducer,
    single_medicine_list: singleMedicineReducer,



    // POST APIS REDUCERS
    upload_Prescription: uploadPrescriptionReducer,
    delete_Prescription: deletePrescriptionReducer,
    medicine_cart_add: medicineCartData,
    medicine_cart_delete: medicineCartDelete,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;