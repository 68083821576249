import { React, useContext, useEffect, useState } from "react";
import { ArrowBack, appCart, appNotification} from "../../../images";
import { ArrowBackButton } from "../../../utils/AllFunction";
import { NavLink , useLocation } from "react-router-dom";
import "swiper/css";

const TrackOrder = (props) => {
  
  const token = localStorage.getItem("ACCESS_TOKEN");
  const location = useLocation();
  const TrackOrderData = location.state;

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          
          <div className="header-logo">
            <div className="user-name">
              <p>My Order Details</p>
            </div>
          </div>
          <div className="icons-box">
            <div className="notification-icon">
              <NavLink>
                <img src={appNotification} width="100%" alt="notification" />
              </NavLink>
            </div>
            <div className="cart-icon">
              <NavLink to="/emptycart">
                <img src={appCart} width="100%" alt="review" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="track-order-main-cover-wrapper">
            <div className="tracking-details">
                <h4 className="tracking-header-title">Order Details</h4>
                <div className="medicine-card-cover ordered_medicine-card-box">
                    <div className="row d-flex align-items-center">
                        <div className="col-3">
                            <div className="medicine-img-box">
                            <img
                                src={TrackOrderData?.order_Items?.image}
                                alt="medicine-img-box"
                            ></img>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="medicine-details-box">
                            <h6>
                                {TrackOrderData?.order_Items?.medicine_name}
                            </h6>
                            <h6 className="order_id">
                                Order ID :{" "}
                                <span>
                                {TrackOrderData?.order_id}
                                </span>
                            </h6>
                            <ul>
                                <li>
                                Quantity :
                                <span>
                                    {TrackOrderData?.order_Items?.quantity}
                                </span>
                                </li>
                                <li>
                                Payment :{" "}
                                <span>
                                    {TrackOrderData?.payment_type}
                                </span>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>                           
                </div>
                <div className="tracking-timeline">
                    <h4>Tracking Details</h4>
                    {TrackOrderData?.order_Items?.history?.map((histData, index) => (
                        <div key={index} className="tracking-item">
                            <div className="tracking-icon">✔</div>
                            {index < TrackOrderData?.order_Items?.history?.length - 1 && <div className="tracking-line"></div>}
                            <div className="tracking-info">
                                <p>{histData?.status_text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div> 
      </div>
    </>
  );
};

export default TrackOrder;
