import React, { useEffect, useState } from 'react'
import Medicine_Header from '../Components/Medicine_Header'
import { useLocation } from "react-router-dom";
import { headerLogo } from '../../../images';
import '../Medicine_Style/categories.css'
import Medicine_Category from '../Components/Medicine_Category';

const Medicine_Categories = () => {
  const [textData, setTextData] = useState("")
  const location = useLocation();
  const item = location.state || {};
  console.log(item?.data?.[1]?.value)
  const [selectedCategory, setSelectedCategory] = useState(null);


  const SelectCategoryText = encodeURIComponent(item?.data?.[1]?.value)

  const handleSelectCategory = (textData) => {
    setSelectedCategory(textData)
    const encodedTextData = encodeURIComponent(textData);
    setTextData(encodedTextData)
  }

  useEffect(() => {
    if (item?.data?.[1]?.value) {
      const initialCategory = item.data[1].value;
      setSelectedCategory(initialCategory);
      setTextData(encodeURIComponent(initialCategory));
    }
  }, [item]);


  return (
    <div className="home-review-banner">
      <Medicine_Header Title={item?.data ? item?.data?.[1]?.label : "Category"} />

      <div className="category-content-data-box-wrapper">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-3">
            <div className="category-content-data-box">
              {item?.data && item?.data?.[0]?.map((item, index) => (
                <div
                  className={`data-box-wrap-cate ${selectedCategory === item?.value ? 'active' : ''}`}
                  key={index}
                  onClick={() => handleSelectCategory(item?.value)}
                >
                  <div className="image-box-repo" style={{ backgroundColor: `#${item?.color_code}` }}>
                    <img
                      src={item?.logo ? item?.logo : headerLogo}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = headerLogo;
                      }}
                      alt="product-icon"
                    />
                  </div>

                  <div className="product-text-head">
                    <p className='ellipsis-text'>{item?.label}</p>
                  </div>
                </div>
              ))}
            </div>

          </div>
          <div className="col-lg-9 col-md-9 col-9">
            <div className="product-box-wrap-cover-div">
              <Medicine_Category textData={textData} SelectCategoryText={SelectCategoryText} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Medicine_Categories