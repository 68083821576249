import React, { useEffect, useState } from 'react'
import Medicine_Header from '../Components/Medicine_Header'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMedicineCartList } from '../../../Redux/actions/GetApiCall'
import { Need_Help_Image, no_preview, px_Image } from '../Images'
import '../Medicine_Style/cart.css'
import useCartFunction from '../hooks/useCartFunction'
import CartLoader from '../animation/CartLoader'
import toast from 'react-hot-toast'
import LoaderAnime from "../animation/prescription_loader";

const Medicine_Cart = () => {
    const Title = "Cart"
    const dispatch = useDispatch()
    const { cartStatus, cart, CartData, DeleteStatus, loadingItem, handleIncrement, handleDecrement, handleDelete } = useCartFunction();
    const { data: MedicineData, loader: cartLoader } = useSelector(state => state.medicine_cart_list);
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        dispatch(fetchMedicineCartList())
    }, [dispatch])

    useEffect(() => {
        if (DeleteStatus || cartLoader) {
            setShowModal(true)
        } else {
            setShowModal(false)
        }
    }, [DeleteStatus, cartLoader])

    return (
        <div className="home-review-banner">
            <Medicine_Header Title={Title} />
            {cartLoader && <LoaderAnime showModal={showModal} />}
            <div className="cart-wrapper-repo-cover-box">
                {MedicineData?.available_stock?.map((item, index) => {
                    const cartItem = cart.find(cartItem => cartItem.sku_id === item.sku_id);
                    return (
                        <div key={item?.sku_id}>
                            <div className="product-wrapper-box-conver">
                                <div className="image-product-cov">
                                    {item?.prescription_required === 0 && (
                                        <div className="product-px-cover-repo">
                                            <img src={px_Image} alt="px-image" />
                                        </div>
                                    )}
                                    <img src={item?.banner_url ? item?.banner_url : no_preview}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = no_preview;
                                        }}
                                        alt="product-icon" />
                                </div>
                                <div className="product-data-desc-box">
                                    <div className="product-repo-data-cont">
                                        <div className="product-name">
                                            <p className='ellipsis-text-cart'>{item?.name}</p>
                                        </div>
                                        <div className="repo-prod-data-cvoer">
                                            <div className="price-qty-cove">
                                                <div className="product-price-text">
                                                    <p>
                                                        {(item?.discounted_price !== null && item?.discounted_price !== 0)
                                                            ? (String(item.discounted_price).startsWith('₹') ? item.discounted_price : `₹${item.discounted_price}`)
                                                            : (item?.price !== 0 && item?.price !== null)
                                                                ? (String(item.price).startsWith('₹') ? item.price : `₹${item.price}`)
                                                                : "No price available"}
                                                    </p>

                                                    <span>
                                                        {(item?.discounted_price !== null && item?.discounted_price !== 0) &&
                                                            (String(item.price).startsWith('₹') ? item.price : `₹${item.price}`)}
                                                    </span>
                                                </div>
                                                <div className="product-qty">
                                                    <p>Min. Qty : {item?.min_quantity}</p>
                                                </div>
                                            </div>
                                            <div className="product-repo-buttons-repo">
                                                <div className="remove-btn-repo-cover">
                                                </div>
                                                <div className="add-product-box">
                                                    {(loadingItem === item.sku_id && (cartStatus === 'loading' || DeleteStatus === "loading")) ? (
                                                        <CartLoader />
                                                    ) : (
                                                        (cartItem || (item?.is_available === 1 && item?.quantity !== 0)) && (
                                                            <div className="increase-cart-repo-boxs">
                                                                <button
                                                                    className={`incre-btn-reop ${cartItem && cartItem.quantity <= item.min_quantity ? 'border-red' : ''}`}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (cartItem && cartItem.quantity <= item.min_quantity) {
                                                                            handleDelete(item.sku_id);
                                                                        } else {
                                                                            handleDecrement(item.sku_id);
                                                                        }
                                                                    }}
                                                                >
                                                                    <p>-</p>
                                                                </button>

                                                                {CartData?.data?.map((count, index) =>
                                                                    count.sku_id === item.sku_id ? (
                                                                        <span key={index}>
                                                                            {count.quantity}
                                                                        </span>
                                                                    ) : null
                                                                ) || (
                                                                        <span>
                                                                            {item?.quantity}
                                                                        </span>
                                                                    )}

                                                                <button
                                                                    className='incre-btn-reop'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (cartItem && cartItem.quantity < item.max_quantity) {
                                                                            handleIncrement(item.sku_id);
                                                                        } else {
                                                                            toast.error("Max Reached");
                                                                        }
                                                                    }}
                                                                >
                                                                    <p>+</p>
                                                                </button>
                                                            </div>
                                                        )
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {index < MedicineData?.available_stock?.length - 1 && <hr className='product-hr' />}
                        </div>
                    )
                })}
            </div>

            <div className="out-stock-cover-wrapper">

                {MedicineData?.out_of_stock.length > 0 && (
                    <div className="text-repo-out-stock">
                        <p>OUT OF STOCK PRODUCTS</p>
                    </div>
                )}
                {MedicineData?.out_of_stock?.map((item, index) => (
                    <>

                        <div key={item?.sku_id}>
                            <div className="product-wrapper-box-conver">
                                <div className="image-product-cov">
                                    {item?.prescription_required === 0 && (
                                        <div className="product-px-cover-repo">
                                            <img src={px_Image} alt="px-image" />
                                        </div>
                                    )}
                                    <img src={item?.banner_url ? item?.banner_url : no_preview}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = no_preview;
                                        }}
                                        alt="product-icon" />
                                </div>
                                <div className="product-data-desc-box">
                                    <div className="product-repo-data-cont">
                                        <div className="product-name">
                                            <p className='ellipsis-text-cart'>{item?.name}</p>
                                        </div>
                                        <div className="repo-prod-data-cvoer">
                                            <div className="price-qty-cove">
                                                <div className="product-price-text">
                                                    <p>
                                                        {(item?.discounted_price !== null && item?.discounted_price !== 0)
                                                            ? (String(item.discounted_price).startsWith('₹') ? item.discounted_price : `₹${item.discounted_price}`)
                                                            : (item?.price !== 0 && item?.price !== null)
                                                                ? (String(item.price).startsWith('₹') ? item.price : `₹${item.price}`)
                                                                : "No price available"}
                                                    </p>

                                                    <span>
                                                        {(item?.discounted_price !== null && item?.discounted_price !== 0) &&
                                                            (String(item.price).startsWith('₹') ? item.price : `₹${item.price}`)}
                                                    </span>
                                                </div>


                                                <div className="product-qty">
                                                    <p>Min. Qty : {item?.min_quantity}</p>
                                                </div>
                                            </div>
                                            <div className="product-repo-buttons-repo">
                                                <div className="remove-btn-repo-cover">
                                                    <button onClick={() => handleDelete(item?.sku_id)}>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {index < MedicineData?.out_of_stock?.length - 1 && <hr className='product-hr' />}
                        </div>
                    </>
                ))}
            </div>

            <div className="payment-repo-cover-box">
                <div className="payment-text-repo">
                    <p>Payment Summary</p>
                </div>

                <div className="payment-summary-box-repo">
                    {MedicineData?.vas_charges?.details?.map((item, index) => (
                        <div className="price-payment-sum-repo" key={index}>
                            <p>{item?.display_text}</p>
                            <p>₹ {item?.amount}</p>
                        </div>
                    ))}
                </div>
                <div className="fin-text-repo">
                    <div className="price-payment-sum-repo total-pay-text">
                        <p>Total Payable</p>
                        <p>₹ {MedicineData?.payable_amount}</p>
                    </div>
                    <div className="price-payment-sum-repo total-save-text">
                        <p>Total Savings</p>
                        <p>₹ {MedicineData?.total_saving}</p>
                    </div>

                </div>
            </div>

            <div className="need-help-repo-textt">
                <div className="image-data-repo">
                    <img src={Need_Help_Image} alt="help-image" />
                </div>
                <div className="text-help-box">
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. </p>
                    <button>Click Here</button>
                </div>
            </div>
        </div>
    )
}

export default Medicine_Cart