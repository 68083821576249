import React, { useState, useEffect } from 'react';
import CartLoader from '../animation/CartLoader';
import { no_preview, px_Image } from '../Images';
import { FaStar } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { medicineCartAdd, medicineCartDelete } from '../../../Redux/actions/PostApiCall';
import { fetchMedicinelist } from '../../../Redux/actions/GetApiCall';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const Medicine_Card = ({ mediData, corner, inputPara, setLoaderStatus }) => {
    const [cart, setCart] = useState([]);
    const [loadingItem, setLoadingItem] = useState(null);
    const [availability, setAvailability] = useState({});
    const [deletedItems, setDeletedItems] = useState(new Set());
    const dispatch = useDispatch();
    const pincode = localStorage.getItem("pincode");
    const navigate = useNavigate();

    const cartStatus = useSelector(state => state.medicine_cart_add.status);
    const CartData = useSelector(state => state.medicine_cart_add.data);
    const DeleteStatus = useSelector(state => state.medicine_cart_delete.status);

    useEffect(() => {
        if (cartStatus === 'success' && CartData) {
            const updatedCart = CartData.data.reduce((acc, cartItem) => {
                const itemAvailability = cartItem.is_available;
                setAvailability(prev => ({ ...prev, [cartItem.sku_id]: itemAvailability }));

                if (itemAvailability) {
                    const itemIndex = acc.findIndex(item => item.sku_id === cartItem.sku_id);
                    if (itemIndex !== -1) {
                        acc[itemIndex].quantity = cartItem.quantity || acc[itemIndex].quantity;
                    } else {
                        acc.push({ ...cartItem, quantity: cartItem.min_quantity });
                    }
                } else {
                    acc = acc.filter(item => item.sku_id !== cartItem.sku_id);
                }

                return acc;
            }, [...cart]);

            setCart(updatedCart);
            if (cartStatus !== 'loading') {
                setLoadingItem(null);
            }
        }
    }, [cartStatus, CartData]);


    
    const updateCartInServer = async (updatedCart) => {
        const cartAddData = JSON.stringify({
            pincode: pincode,
            cart: updatedCart,
        });
        await dispatch(medicineCartAdd(cartAddData));
    };


    useEffect(() => {
        const newCartItems = [];
        mediData?.data?.forEach(item => {
            if (item?.is_available === 1 && item?.in_cart === 1 && !deletedItems.has(item.sku_id)) {
                const updateCart = {
                    sku_id: item.sku_id,
                    quantity: item.quantity,
                    min_quantity: item.min_quantity,
                    max_quantity: item.max_quantity,
                };

                const isItemInCart = cart.some(cartItem => cartItem.sku_id === item.sku_id);
                if (!isItemInCart) {
                    newCartItems.push(updateCart);
                }
            }
        });

        if (newCartItems.length > 0) {
            setCart(prevCart => [...prevCart, ...newCartItems]);
        }
    }, [mediData, cart, deletedItems]);

    const handleDelete = async (id) => {
        setLoadingItem(id);
        const CartId = { sku_id: id };
        setLoaderStatus(true);
        await dispatch(medicineCartDelete(CartId));
        setDeletedItems(prev => new Set(prev).add(id));
    };

    useEffect(() => {
        if (DeleteStatus === "success" && inputPara !== undefined) {
            setCart(prevCart => prevCart.filter(item => item.sku_id !== loadingItem));
            setDeletedItems(prev => new Set(prev).add(loadingItem));
            dispatch(fetchMedicinelist(inputPara));
        }
    }, [DeleteStatus]);

    const handleCartAdd = async (item) => {
        setLoadingItem(item.sku_id);

        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === item.sku_id
                ? {
                    ...cartItem,
                    quantity: Math.min(cartItem.quantity + item.min_quantity, item.max_quantity),
                }
                : cartItem
        );

        if (!updatedCart.some(cartItem => cartItem.sku_id === item.sku_id)) {
            updatedCart.push({
                sku_id: item.sku_id,
                quantity: item.min_quantity,
                min_quantity: item.min_quantity,
                max_quantity: item.max_quantity,
            });
        }

        await updateCartInServer(updatedCart);
    };

    const handleIncrement = async (sku_id) => {
        setLoadingItem(sku_id);
        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === sku_id && cartItem.quantity < cartItem.max_quantity
                ? { ...cartItem, quantity: cartItem.quantity + 1 }
                : cartItem
        );

        await updateCartInServer(updatedCart);
    };

    const handleDecrement = async (sku_id) => {
        setLoadingItem(sku_id);

        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === sku_id && cartItem.quantity > cartItem.min_quantity
                ? { ...cartItem, quantity: cartItem.quantity - 1 }
                : cartItem
        );

        await updateCartInServer(updatedCart);
    };

    const hanldeDescription = (item) => {
        const data = {
            Title: "Product description",
            data: item
        };
        navigate('/medicine-description', { state: data });
    };

    return (
        <>
            {mediData && mediData?.data?.map((item, index) => {
                // Find the cart item for this product
                const cartItem = cart.find(cartItem => cartItem.sku_id === item.sku_id);

                return (
                    <div
                        className={corner === "data" ? "col-lg-6 col-md-6 col-6 repo-corner-col" : corner === "category" ? "category-repo-corner-col" : "recent-product-data-repo-cover"}
                        key={index}
                        onClick={() => hanldeDescription(item)}
                    >
                        <div key={item?.id} className={corner === "category" ? "category-repo-data-box" : "corner-data-box"}>
                            {item?.prescription_required === 1 && (
                                <div className="px-cover-repo">
                                    <img src={px_Image} alt="px-image" />
                                </div>
                            )}
                            <div className="image-cover-recent">
                                <img
                                    src={item?.banner_url ? item?.banner_url : no_preview}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = no_preview;
                                    }}
                                    alt="product-icon"
                                />
                            </div>
                            <p className='recent-ellipsis-text'>{item?.name}</p>
                            <div className="price-repo-text">
                                <p>{item?.prices?.discounted_price !== null ? item?.prices?.discounted_price : item?.prices?.mrp}</p>
                                <span>{item?.prices?.discounted_price !== null && item?.prices?.mrp}</span>
                            </div>
                            <div className="offer-recent-text-box">
                                <p>{item?.prices?.discount}</p>
                                {item?.average_rating !== '' && (
                                    <span><FaStar />{item?.average_rating}</span>
                                )}
                            </div>
                            <div className="line-repo"></div>
                            <div className="qty-repo-box">
                                <p>Min. Qty : {item?.min_quantity}</p>
                            </div>
                            <div className="add-product-box">
                                {(loadingItem === item.sku_id && cartStatus === 'loading') || (loadingItem === item.sku_id && DeleteStatus === "loading") ? (
                                    <CartLoader />
                                ) : (
                                    <div className="add-product-box">
                                        {(loadingItem === item.sku_id && cartStatus === 'loading') || (loadingItem === item.sku_id && DeleteStatus === "loading") ? (
                                            <CartLoader />
                                        ) : (
                                            <div className="add-cart-repo-boxs">
                                                {availability[item.sku_id] === 0 || item?.is_available === 0 ? (
                                                    <div className="out-of-stock">
                                                        <p>Out of Stock</p>
                                                    </div>
                                                ) : (cartItem || (item?.in_cart === 1 && item?.is_available === 1 && item?.quantity !== 0)) ? (
                                                    <div className="increase-cart-repo-boxs">
                                                        <button
                                                            className={`incre-btn-reop ${cartItem && cartItem.quantity <= item.min_quantity ? 'border-red' : ''}`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (cartItem && cartItem.quantity <= item.min_quantity) {
                                                                    handleDelete(item.sku_id);
                                                                } else {
                                                                    handleDecrement(item.sku_id);
                                                                }
                                                            }}
                                                        >
                                                            <p>-</p>
                                                        </button>

                                                        {CartData?.data?.map((count, index) => (
                                                            count.sku_id === item.sku_id ? (
                                                                <span key={index}>
                                                                    {count.quantity}
                                                                </span>
                                                            ) : null
                                                        )) || (
                                                                <span>
                                                                    {item?.quantity}
                                                                </span>
                                                            )}
                                                        <button
                                                            className='incre-btn-reop'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (cartItem && cartItem.quantity < item.max_quantity) {
                                                                    handleIncrement(item.sku_id);
                                                                } else {
                                                                    toast.error("Max Reached");
                                                                }
                                                            }}
                                                        >
                                                            <p>+</p>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCartAdd(item);
                                                        }}
                                                    >
                                                        <p>ADD</p>
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "",
                    duration: 2700,
                    style: {
                        background: "#fff",
                        color: "#363636",
                    },
                }}
            />
        </>
    );
};

export default Medicine_Card;
