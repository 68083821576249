import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { ArrowBackButton, fetchApi } from "../../utils/AllFunction";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Autoplay } from "swiper/modules";
import Loader from "../../Animation/Loader";
import { REQUESTOPTIONS,PACKAGE_DETAIL_API , HEALTH_PACKAGE_API } from "../../Constants";

import {
  ArrowBack,
  appFastingTime,
  appRecommended,
  appAge,
  appFasting,
  appTestTube,
  appHomeCollectionIcon,
  appIncludeTestIcon,
  appPackageIcon,
  appReports,
  appCart,
} from "../../images";
import NoDataFoundComp from "../../components/NoDataFound";

const Package_Description = () => {
  const [packageDetailsData, setPackageDetailsData] = useState([]);
  const [isPackageDataLoading, setPackageLoading] = useState(true);
  const [packageData, setPackageData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [isErrorData, setErrorData] = useState([]);
  const [isAllpackageLoading, setAllPackageLoading] = useState(true);
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const { pack_id } = useParams();

  useEffect(() => {
    const GetPackageDescription = async () => {
      setPackageLoading(true);
      try {
        const apiUrl = `${PACKAGE_DETAIL_API}?package_id=${pack_id}`;
        const response = await fetch(apiUrl, REQUESTOPTIONS);
        const res = await response.json();
        if (res.status) {
          setPackageDetailsData(res.data.package_data);
          setPackageLoading(false);
          setTestData(res.data.package_parameter);
        } else {
          console.log(res);
        }
      } catch (error) {
        console.error(error);
        setPackageLoading(false);
      }
    };

    GetPackageDescription();
    fetchApi(
      HEALTH_PACKAGE_API,
      REQUESTOPTIONS,
      setPackageData,
      setAllPackageLoading,
      setErrorData
    );
  }, [pack_id]);

  const handlePakageCart = (id) => {
    const isItemInCart = CartData.some((item) => item.pack_id === id);

    if (!isItemInCart) {
      setCartData((prevData) => [
        ...prevData,
        ...packageData.data.filter((data) => data.pack_id === id),
      ]);
    } else {
      const updatedCart = CartData.filter((item) => item.pack_id !== id);
      setCartData(updatedCart);
    }
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="cart-header-banner package-header">
          <div className="back-icon mt-0">
            <NavLink>
              <img
                src={ArrowBack}
                width="100%"
                alt="review"
                onClick={ArrowBackButton}
              />
            </NavLink>
          </div>
          <div className="lab-test-box">
            <div className="cart-heading">
              <h5>Package Description</h5>
            </div>
            <div className="cart-icon lab-icon">
              <NavLink>
                <img src={appCart} width="100%" alt="review" />
              </NavLink>
              <span id="hiddenDiv2">1</span>
            </div>
          </div>
        </div>
        {isPackageDataLoading ? (
          <Loader />
        ) : (
          <div className="package-details-main-cover-wrapper">
            <div className="pack-detail-cover-box">
              <div className="pack-deatil-img-box">
                <img src={appPackageIcon} width="100%" alt="review" />
                <h6>{packageDetailsData?.package_name}</h6>
              </div>
              <hr />
              <div className="pack-detail-content-box">
                <h6>MB Price ₹ {packageDetailsData?.pack_medi_price}</h6>
                <p>₹ {packageDetailsData?.pack_mrp}</p>
                <span>{packageDetailsData?.discount_percent}OFF</span>
              </div>
            </div>
            <div className="description-tabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="">
                  <Col lg={12}>
                    <Nav
                      variant="pills"
                      className="d-flex justify-content-around"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first" className="description-tab">
                          Test Description
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second" className="description-tab">
                          Included Tests
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col lg={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <>
                          {packageDetailsData.pack_description && (
                            <div className="test-description-content-box">
                              <p>{packageDetailsData.pack_description}</p>
                            </div>
                          )}

                          <div className="test-desc-tabs">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-6 desc-detail-box-repo">
                                  <div className="desc-cover-box">
                                    <div className="desc-image-box">
                                      <img
                                        src={appReports}
                                        width="100%"
                                        alt="review"
                                      />
                                    </div>
                                    <div className="desc-detail-box">
                                      <p>E-Reports</p>
                                      <p>
                                        {packageDetailsData.pack_report_time}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 desc-detail-box-repo">
                                  <div className="desc-cover-box">
                                    <div className="desc-image-box">
                                      <img
                                        src={appFastingTime}
                                        width="100%"
                                        alt="review"
                                      />
                                    </div>
                                    <div className="desc-detail-box">
                                      <p>Fasting Time</p>
                                      <p>
                                        {
                                          packageDetailsData.pack_fasting_required
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 desc-detail-box-repo">
                                  <div className="desc-cover-box">
                                    <div className="desc-image-box">
                                      <img
                                        src={appRecommended}
                                        width="100%"
                                        alt="review"
                                      />
                                    </div>
                                    <div className="desc-detail-box">
                                      <p>Recommended</p>
                                      <p>
                                        for - {packageDetailsData.pack_gender}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 desc-detail-box-repo">
                                  <div className="desc-cover-box">
                                    <div className="desc-image-box">
                                      <img
                                        src={appAge}
                                        width="100%"
                                        alt="review"
                                      />
                                    </div>
                                    <div className="desc-detail-box">
                                      <p>Age</p>
                                      <p>{packageDetailsData?.pack_age_type}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="test-accordion-box">
                          <Accordion defaultActiveKey="0">
                            {/* {testData.length > 0 ? testData.map((elem) => console.log(elem)) : "hdjdfh"} */}
                            {testData?.length > 0 ? (
                              testData.map((elemdata, index) => {
                                return (
                                  <Accordion.Item key={index} eventKey={index}>
                                    <Accordion.Header>
                                      <h6>{elemdata?.test_heading}</h6>
                                      <span className="test-count">
                                        {elemdata?.test_parameter?.length}
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="test-main-body">
                                        <ol>
                                          {elemdata?.test_parameter?.length >
                                            0 &&
                                            elemdata?.test_parameter.map(
                                              (data, index) => (
                                                <li key={index}>
                                                  {data?.params_name}
                                                </li>
                                              )
                                            )}
                                        </ol>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              })
                            ) : (
                              <h6>
                                <NoDataFoundComp />
                              </h6>
                            )}
                          </Accordion>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>

              <div className="pack-detail-buttons">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 packe-det-repo">
                      <div className="pack-detail-add-to-cart">
                        {CartData.some(
                          (item) => item.pack_id === packageDetailsData.pack_id
                        ) ? (
                          <div className="pack-detail-buy-now">
                            <button
                              onClick={() =>
                                handlePakageCart(packageDetailsData.pack_id)
                              }
                            >
                              <p>Remove Cart</p>
                            </button>
                          </div>
                        ) : (
                          <div className="health-add-cart">
                            <button
                              id="add-to-cart"
                              className="add-to-cart"
                              onClick={() =>
                                handlePakageCart(packageDetailsData.pack_id)
                              }
                            >
                              <p>ADD TO CART</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 packe-det-repo d-flex align-items-center">
                      <div className="pack-detail-buy-now">
                        <button>
                          <p>BUY NOW</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pack-why-book">
                <div className="pack-why-book-heading">
                  <hr />
                  <h6>Why book with us ?</h6>
                  <hr />
                </div>
                <div className="pack-why-book-cover">
                  <div className="pack-why-book-desc">
                    <div className="pack-why-book-image">
                      <img src={appFasting} width="100%" alt="review" />
                    </div>
                    <div className="pack-why-book-content">
                      <p>Safe sample collection from comfort of your home</p>
                    </div>
                  </div>
                  <div className="pack-why-book-desc">
                    <div className="pack-why-book-image">
                      <img src={appTestTube} width="100%" alt="review" />
                    </div>
                    <div className="pack-why-book-content">
                      <p>Wide range of tests available</p>
                    </div>
                  </div>
                  <div className="pack-why-book-desc">
                    <div className="pack-why-book-image">
                      <img src={appFastingTime} width="100%" alt="review" />
                    </div>
                    <div className="pack-why-book-content">
                      <p>Doctor advise with Health tests</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div className="pack-recommended-wrapper">
                <div className="pack-recommended-heading">
                  <hr />
                  <p>Recommended Packages</p>
                  <hr />
                </div>
                <div className="health-package-box-cover mb-4">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={1.4}
                    loop="true"
                    modules={[Autoplay]}
                    pagination={{ clickable: true }}
                    // autoplay={{ delay: 2500, disableoninteraction: false, }}
                  >
                    <div className="swiper-wrapper">
                      {packageData.data?.length > 0 ? (
                        packageData.data.slice(0, 6).map((elem) => (
                          <SwiperSlide key={elem.pack_id}>
                            <div className="health-pack-main-box">
                              <NavLink
                                to={`/package-description/${elem.pack_id}`}
                              >
                                <div className="health-pack-box">
                                  <div className="health-pack-image-box">
                                    <img
                                      src={appPackageIcon}
                                      width="100%"
                                      alt="package"
                                    />
                                    <p>{elem.package_name}</p>
                                  </div>
                                  <hr />
                                  <div className="health-pack-price">
                                    <h6>MB Price ₹ {elem.pack_medi_price}</h6>
                                    <p>₹ {elem.pack_mrp}</p>
                                    <span>{elem.discount_percent} OFF</span>
                                  </div>
                                  <div className="health-pack-content-box">
                                    <img
                                      src={appIncludeTestIcon}
                                      width="100%"
                                      alt="include-test"
                                    />
                                    <p>{elem.parameter_count} Included Tests</p>
                                  </div>
                                  <div className="health-pack-content-box">
                                    <img
                                      src={appHomeCollectionIcon}
                                      width="100%"
                                      alt="home"
                                    />
                                    <p className="sample-repo">
                                      Home Sample Collection
                                    </p>
                                  </div>
                                </div>
                              </NavLink>

                              {CartData.some(
                                (item) => item.pack_id === elem.pack_id
                              ) ? (
                                <button
                                  className="remove-cart-btn mt-3 mb-2"
                                  onClick={() => handlePakageCart(elem.pack_id)}
                                >
                                  Remove Cart
                                </button>
                              ) : (
                                <div className="health-add-cart">
                                  <button
                                    id="add-to-cart"
                                    className="add-to-cart"
                                    onClick={() =>
                                      handlePakageCart(elem.pack_id)
                                    }
                                  >
                                    <p>ADD TO CART</p>
                                  </button>
                                </div>
                              )}
                            </div>
                          </SwiperSlide>
                        ))
                      ) : (
                        <h6>
                          <NoDataFoundComp />
                        </h6>
                      )}
                    </div>
                  </Swiper>
                </div>
              </div>
              <div className="lab-total-cover" id="hiddenDiv1">
                <div className="lab-total-item">
                  <div className="lab-total-item-content">
                    <p>
                      Total <span>₹ 91.00</span>
                    </p>
                    <p className="add-item">1 Item(s)</p>
                  </div>
                  <div className="lab-total-item-cart">
                    <a href="#">
                      <button>
                        <p>GO TO CART</p>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Package_Description;
