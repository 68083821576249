import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Medicine_Header from '../Components/Medicine_Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../Medicine_Style/description.css';
import { Autoplay, Pagination } from 'swiper/modules';
import { px_Image } from '../Images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleMedicinelist } from '../../../Redux/actions/GetApiCall';
import LoaderAnime from "../animation/prescription_loader";
import useCartFunction from '../hooks/useCartFunction';
import CartLoader from '../animation/CartLoader';
import toast from 'react-hot-toast';

const Medicine_Description = () => {
  const location = useLocation();
  const Data = location?.state;
  const sku_id = Data?.data?.sku_id;
  const type = Data?.data?.type;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const { data: mediData, loader: mediloader } = useSelector(state => state.single_medicine_list);
  const { availability, cartStatus, cart, CartData, DeleteStatus, loadingItem, handleCartAdd, handleIncrement, handleDecrement, handleDelete } = useCartFunction();

  useEffect(() => {
    dispatch(fetchSingleMedicinelist(sku_id, type));
  }, [dispatch, sku_id, type]);

  const handleVaient = (id) => {
    console.log(id);
    dispatch(fetchSingleMedicinelist(id, type)); // Dispatch action to fetch variant data
  };

  useEffect(() => {
    if (mediloader) {
      setShowModal(true)
    }
    else {
      setShowModal(false)
    }
  }, [mediloader])

  const cartItem = cart.find(cartItem => cartItem.sku_id === mediData?.data?.sku_id);

  return (
    <div className="home-review-banner">
      <Medicine_Header Title={Data?.Title} />

      {mediloader ? (
        <>
          {mediloader && <LoaderAnime showModal={showModal} />}
        </>
      ) : (
        <>
          <div className="product-cover-wrapper-boxs">
            <div className="product-swiper">
              <Swiper
                className="mySwiper product-swiper"
                slidesPerView={1}
                pagination={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
              >
                {mediData?.data?.image_urls?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="product-swiper-image">
                      <img src={item?.high} alt="" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {mediData?.data?.prescription_required === 1 && (
              <div className="px-data-rpeo">
                <img src={px_Image} alt="px-image" />
                <span>Prescription Required*</span>
              </div>
            )}

            <div className="product-container-wrapper">
              <div className="product-name-repo-text">
                <p>{mediData?.data?.name}</p>
              </div>

              <div className="product-btn-repo-data-description">
              
                <div className="add-product-box single-btn-repo-box">
                  {(loadingItem === mediData?.data?.sku_id && cartStatus === 'loading') ||
                    (loadingItem === mediData?.data?.sku_id && DeleteStatus === "loading") ? (
                    <CartLoader />
                  ) : (
                    <div className="add-cart-repo-boxs single-repo-desc">
                      {availability[mediData?.data?.sku_id] === 0 || mediData?.data?.is_available === 0 ? (
                        <div className="out-of-stock">
                          <p>Out of Stock</p>
                        </div>
                      ) : (cartItem || (mediData?.data?.in_cart === 1 && mediData?.data?.is_available === 1 && mediData?.data?.quantity !== 0)) ? (
                        <div className="increase-cart-repo-boxs">
                          <button
                            className={`incre-btn-reop ${cartItem && cartItem.quantity <= mediData?.data?.min_quantity ? 'border-red' : ''}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (cartItem && cartItem.quantity <= mediData?.data?.min_quantity) {
                                handleDelete(mediData?.data?.sku_id);
                              } else {
                                handleDecrement(mediData?.data?.sku_id);
                              }
                            }}
                          >
                            <p>-</p>
                          </button>

                          {CartData?.data?.map((count, index) => (
                            count.sku_id === mediData?.data?.sku_id ? (
                              <span key={index}>
                                {count.quantity}
                              </span>
                            ) : null
                          )) || (
                              <span>
                                {mediData?.data?.quantity}
                              </span>
                            )}
                          <button
                            className='incre-btn-reop'
                            onClick={(e) => {
                              e.stopPropagation();
                              if (cartItem && cartItem.quantity < mediData?.data?.max_quantity) {
                                handleIncrement(mediData?.data?.sku_id);
                              } else {
                                toast.error("Max Reached");
                              }
                            }}
                          >
                            <p>+</p>
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCartAdd(mediData?.data);
                          }}
                        >
                          <p>ADD</p>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="product-varient-repo-box">
                <h6>Choose Variant:</h6>
                <div className="data-repo-variant-texts">
                  {mediData?.data?.variants?.map((item, index) => (
                    <p
                      key={index} // Ensure each variant has a unique key
                      className='vaient-data-boxs'
                      onClick={() => handleVaient(item?.id)}
                      style={{
                        backgroundColor: item?.selected === true ? 'red' : 'white',
                        color: item?.selected === true ? 'white' : 'black',
                        border: item?.selected === true ? '1px solid red' : '1px solid grey',
                      }}
                    >
                      {item?.display_text}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Medicine_Description;
