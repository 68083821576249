import React, { useEffect, useState } from "react";
import { headerLogo } from "../../images";
import { HEALTH_FEED_BLOG_API , IMAGE_HEALTH_URL } from "../../Constants";

const BlogHome = (props) => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [blogdata, setBlogData] = useState(null);
  const [isBlogLoading, setBlogLoading] = useState(false);

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };

  useEffect(() => {
    async function getBlogData() {
      setBlogLoading(true);
      try {
        const response = await fetch(
          `${HEALTH_FEED_BLOG_API}`,
          requestOptions
        );
        const res = await response.json();
        if (res) {
          setBlogData(res.health_feed);
        }
      } catch (error) {
        console.error(error);
      }
    }
    getBlogData();
  }, []);

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p className="ml-5">Blog Home</p>
            </div>
          </div>
        </div>

        {blogdata && blogdata?.length > 0 && (
          <div className="blog-home-main-wrapper">
            <div className="blog-wrapper">
              <h3>Health Corner</h3>
              <div className="blog-main-cover">
                {
                  blogdata.map((blogData, index) => (
                    <div className="blog-content-box blog-content-card-box">
                      <div className="blog-image-box">
                        <img
                          src={`${IMAGE_HEALTH_URL}${blogData?.feature_image}`}
                          width="100%"
                          alt="health-care"
                        />
                      </div>
                      <div className="blog-heading-box blog-subject-text">
                        <p>{blogData.subject}</p>
                      </div>
                      <div className="blog-text-box short-desc-repo">
                        <p>{blogData.short_description}</p>
                      </div>
                      <div className="blog-post">
                        <p>
                          Posted on{" "}
                          {
                            new Date(blogData.created_on)
                              .toISOString()
                              .split("T")[0]
                          }
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BlogHome;
