import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicinelist } from '../../../Redux/actions/GetApiCall';
import { FaStar } from 'react-icons/fa6';
import { headerLogo } from '../../../images';
import '../Medicine_Style/categories.css'
import MediLoader from '../animation/MediLoader';
import { no_preview } from '../Images';
import Medicine_Card from './Medicine_Card';

const Medicine_Category = ({ textData }) => {
    const dispatch = useDispatch();
    const [loaderStatus, setLoaderStatus] = useState(false);
    // console.log(SelectCategoryText)
    const { loader: mediloader, data: mediData, error: mediError } = useSelector(state => state.medicine_list);

    useEffect(() => {
        const fetchList = textData !== '' && String(textData);
        if (fetchList) {
            dispatch(fetchMedicinelist(fetchList));
        }
    }, [dispatch, textData]);

    const height = "75%"
    const corner = "category"
    console.log(mediData)


    return (
        <div className="category-con-wrapper-box">
            {mediloader && !loaderStatus ? (
                <MediLoader height={height} />
            ) : (

                <div className="corner-data-container">
                    <Medicine_Card setLoaderStatus={setLoaderStatus} mediData={mediData} corner={corner} inputPara={textData} />
                </div>
            )}
        </div>
    )
}

export default Medicine_Category